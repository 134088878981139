import { CORPORA_URI } from 'src/config';

class TaskClient {
  async getTasks(collectionid, authenticationToken) {
    console.log('Set Tasks', collectionid);
    let responseJson = await fetch(CORPORA_URI + '/get-tasks', {
      method: 'POST',
      body: JSON.stringify({ collectionid: collectionid }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();
    console.log(response);

    return response;
  }
  async addTask(taskName, taskQuery, collectionId, authenticationToken) {
    let data = {
      taskname: taskName,
      taskquery: taskQuery,
      collectionid: collectionId,
    };
    console.log(data);

    let responseJson = await fetch(CORPORA_URI + '/add-task', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();
    console.dir(response);

    return response;
  }
  async editTask(taskid, taskName, taskQuery, collectionId, authenticationToken) {
    let data = {
      taskid: taskid,
      taskname: taskName,
      taskquery: taskQuery,
      collectionid: collectionId,
    };
    // formData.append('taskname', taskName);
    // formData.append('taskquery', taskQuery);
    // formData.append('collectionid', collectionId);

    let responseJson = await fetch(CORPORA_URI + '/edit-task', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
        'Content-Type': 'application/json',
      },
    });

    let response = await responseJson.json();
    console.dir(response);

    return response;
  }
  async deleteTask(taskId, collectionId, authenticationToken) {
    let data = {
      taskid: taskId,
      collectionid: collectionId,
    };

    let responseJson = await fetch(CORPORA_URI + '/delete-task', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
        'Content-Type': 'application/json',
      },
    });

    let response = await responseJson.json();
    console.dir(response);

    return response;
  }
}

const obj = new TaskClient();
export default obj;
