import { logout } from 'src/firebase';
import { CORPORA_URI } from '../config';
import { collection } from 'firebase/firestore';
function swapEverySecondElement(arr) {
  for (let i = 1; i < arr.length; i += 2) {
    const temp = arr[i];
    arr[i] = arr[i - 1];
    arr[i - 1] = temp;
  }
  return arr;
}
class ConversationClient {
  async askGPT3(question, documentId) {
    const data = new FormData();
    data.append('documentId', documentId);
    data.append('question', question);

    const responseJson = await fetch(CORPORA_URI + '/askgpt3', {
      method: 'POST',
      body: data,
    });

    return await responseJson.json();
  }

  async askGPT3FromCollection(question, authenticationToken, questionIndex) {
    const data = new FormData();
    data.append('question', question);
    data.append('index', questionIndex);

    const responseJson = await fetch(CORPORA_URI + '/ask-from-collection', {
      method: 'POST',
      body: data,
      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    if (responseJson.status === 402) {
      return {
        data: {
          isBot: true,
          paymentRequired: true,
        },
      };
    }

    return await responseJson.json();
  }
  async generateGPT3(authenticationToken, fdata) {
    console.log(fdata);
    const responseJson = await fetch(CORPORA_URI + '/generate-question', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fdata),
    });

    if (responseJson.status === 402) {
      return {
        data: {
          isBot: true,
          paymentRequired: true,
        },
      };
    }

    return await responseJson.json();
  }
  async getsessionData(authenticationToken, fdata) {
    console.log(fdata);
    const responseJson = await fetch(CORPORA_URI + '/get-session-by-sessionid', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fdata),
    });

    if (responseJson.status === 402) {
      return {
        data: {
          isBot: true,
          paymentRequired: true,
        },
      };
    }

    return await responseJson.json();
  }

  async getSummary(question, id, authenticationToken) {
    const data = new FormData();
    data.append('question', 'Summarize this document.');
    data.append('document_id', id);

    const responseJson = await fetch(CORPORA_URI + '/get-summary', {
      method: 'POST',
      body: data,
      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    if (responseJson.status === 402) {
      return {
        data: {
          isBot: true,
          paymentRequired: true,
        },
      };
    }

    return await responseJson.json();
  }
  // async getResponseStream(question, authenticationToken, questionIndex) {
  //   const data = new FormData();
  //   data.append("question", "Summarize this document.");
  //   data.append("document_id", id);

  //   const responseJson = await fetch(
  //     CORPORA_URI + "/get-summary",
  //     {
  //       method: "POST",
  //       body: data,
  //       headers: {
  //         Authorization: "Bearer: " + authenticationToken,
  //       },
  //     }
  //   );

  //   if (responseJson.status === 402) {
  //     return {
  //       data: {
  //         isBot: true,
  //         paymentRequired: true,
  //       },
  //     };
  //   }

  //   return await responseJson.json();
  // }
  async getDocumentConversation(id) {
    var data = new FormData();
    data.append('documentId', id);
    let init = {
      method: 'POST',
      body: data,
    };

    let responseJson = await fetch(CORPORA_URI + '/get-document-conversations', init);

    let response = await responseJson.json();

    let messages = response.data.list.map((r) => ({
      message: r.message,
      isBot: r.is_bot,
    }));

    return messages.reverse();
  }

  async getUserConversation(authenticationToken) {
    var data = new FormData();
    //data.append("userId", id);

    let init = {
      method: 'POST',
      body: data,
      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
      },
    };
    //console.log(CORPORA_URI);
    let responseJson = await fetch(CORPORA_URI + '/get-collection-conversations', init);
    // console.log('end');
    let response = await responseJson.json();
    //console.log(response);
    if (response.status === 401) {
      logout();
      return;
    }
    let messages = response.data.list.map((r) => ({
      message: r.message,
      isBot: r.is_bot,
    }));
    // const swappedArr = swapEverySecondElement(messages);
    // console.log(swappedArr);
    return messages.reverse();
  }
}

const conversationRepository = new ConversationClient();
export default conversationRepository;
