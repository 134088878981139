import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus } from '@fortawesome/free-solid-svg-icons';
// import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
// import { faFile } from '@fortawesome/free-solid-svg-icons';
// import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

// import { FileUploader } from 'react-drag-drop-files';
import { useAuthState } from 'react-firebase-hooks/auth';
// import documentClient from '../../clients/DocumentClient';
// import Spinner from '../ui/Spinner';
// import { useContext } from 'react';
// import Context from '../../context';
// import PageHeader from '../ui/PageHeader';
// import PageFooter from '../ui/PageFooter';
import stripeClient from '../clients/StripeClient';
import { auth, db, logout } from '../firebase';
import { useEffect } from 'react';
// import Directory from './Directory';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import SubscriptionPrice from './SubscriptionPrice';
import CenteredSpinner from './CenteredSpinner';
import Iconify from '../components/Iconify';
import {
  REACT_APP_ADDON_PRICE_ID,
  REACT_APP_BASE_PRICE_ID_MN,
  REACT_APP_STANDARD_PRICE_ID_MN,
  REACT_APP_PREMIUM_PRICE_ID_MN,
  REACT_APP_BASE_PRICE_ID_YR,
  REACT_APP_STANDARD_PRICE_ID_YR,
  REACT_APP_PREMIUM_PRICE_ID_YR,
} from '../config';
import PricingPage from './PricingPage';
import {
  Grid,
  Card,
  Container,
  CardHeader,
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Tab,
  CircularProgress,
  Divider,
  FormControlLabel,
  Switch,
} from '@mui/material';
export default function UserSubscription(props) {
  const [user, isUserLoading, error] = useAuthState(auth);
  const [subscription, setSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [Yearly, setYearly] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      if (isUserLoading) return;
      if (!user) return navigate('/login');

      setSubscription(await stripeClient.getSubscription(await user.getIdToken()));
      setIsLoading(false);
    }

    fetchData();
  }, [user, isUserLoading]);

  // let directory = <Directory />

  async function checkout(priceId, isOneTimePayment) {
    console.log(priceId);
    const response = await stripeClient.checkout(await user.getIdToken(), priceId, isOneTimePayment);

    window.location.href = response.url;
  }

  async function openPortalSession() {
    const session = await stripeClient.startPortalSession(await user.getIdToken());

    window.location.href = session.url;
  }

  function getHasSubLayout() {
    return (
      <div>
        <div>Next billing: {DateTime.fromSeconds(subscription.currentPeriodEnd).toISODate()}</div>
        <Button
          className={'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-3'}
          onClick={() => openPortalSession()}
          variant="contained"
        >
          Manage Subscription
        </Button>
        {/* <Button
          className={'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-3'}
          onClick={() => checkout(REACT_APP_ADDON_PRICE_ID, true)}
          variant="contained"
          style={{ marginLeft: 5 }}
        >
          $15 Add on
        </Button> */}
      </div>
    );
  }
  const PriceTable = () => {
    if (Yearly) {
      return (
        <Grid sx={{ display: 'flex', flexDirection: 'row', padding: 2, justifyContent: 'center' }}>
          <SubscriptionPrice
            onClick={() => checkout(REACT_APP_ADDON_PRICE_ID, true)}
            name="Add-on"
            queries={'1,000'}
            price={'15 / one-time'}
            pdfCount={10}
            type={'one-time'}
            extra={''}
          />

          <SubscriptionPrice
            onClick={() => checkout(REACT_APP_BASE_PRICE_ID_YR, false)}
            name="Base"
            queries={'6,000'}
            price={'120 / yearly'}
            pdfCount={15}
            type={'yearly'}
            extra={''}
          />

          <SubscriptionPrice
            onClick={() => checkout(REACT_APP_STANDARD_PRICE_ID_YR, false)}
            name="Standard"
            queries={'24,000'}
            price={'290 / yearly'}
            pdfCount={50}
            type={'yearly'}
            extra={''}
          />

          <SubscriptionPrice
            onClick={() => checkout(REACT_APP_PREMIUM_PRICE_ID_YR, false)}
            name="Premium"
            queries={'96,000'}
            price={'990 / yearly'}
            pdfCount={200}
            type={'yearly'}
            extra={''}
          />
        </Grid>
      );
    } else {
      return (
        <Grid sx={{ display: 'flex', flexDirection: 'row', padding: 2, justifyContent: 'center' }}>
          <SubscriptionPrice
            onClick={() => checkout(REACT_APP_ADDON_PRICE_ID, true)}
            name="Add-on"
            queries={'1,000'}
            price={'15 / one-time'}
            pdfCount={10}
            type={'one-time'}
            extra={''}
          ></SubscriptionPrice>

          <SubscriptionPrice
            onClick={() => checkout(REACT_APP_BASE_PRICE_ID_MN, false)}
            name="Base"
            queries={'500'}
            price={'12 / monthly'}
            pdfCount={15}
            type={'monthly'}
            extra={''}
          ></SubscriptionPrice>

          <SubscriptionPrice
            onClick={() => checkout(REACT_APP_STANDARD_PRICE_ID_MN, false)}
            name="Standard"
            queries={'2,000'}
            price={'29 / monthly'}
            pdfCount={50}
            type={'monthly'}
            extra={''}
          ></SubscriptionPrice>

          <SubscriptionPrice
            onClick={() => checkout(REACT_APP_PREMIUM_PRICE_ID_MN, false)}
            name="Premium"
            queries={'8,000'}
            price={'99 / monthly'}
            pdfCount={200}
            type={'monthly'}
            extra={''}
          ></SubscriptionPrice>
        </Grid>
      );
    }
  };

  return isLoading ? (
    <CircularProgress size="1rem" />
  ) : subscription ? (
    getHasSubLayout()
  ) : (
    <div>
      <div className={'flex justify-between'}>
        <Grid style={{ display: 'flex', alignItems: 'center' }}>
          <Iconify icon={'ion:pricetag'} sx={{ width: 20, height: 20, mr: 1 }} />

          <Typography variant="h5">Pricing </Typography>
          <FormControlLabel
            control={<Switch checked={Yearly} onChange={() => setYearly(!Yearly)} />}
            label={Yearly ? 'Yearly ( 2 months free )' : 'Monthly'}
            sx={{ ml: 2 }}
          />
        </Grid>
        <Divider />
      </div>
      <getHasSubLayout />
      <PriceTable />
      {/* <div className={'mt-3'}>
        <Typography variant="subtitle2">For enterprise pricing email: hello@askcorpora.com</Typography>
      </div> */}
    </div>
  );
}
