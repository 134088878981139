import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import {
  FormGroup,
  Popover,
  Stack,
  FormControlLabel,
  Switch,
  Divider,
  Input,
  FormControl,
  TextField,
  Button,
  Link,
  CircularProgress,
} from '@mui/material';
import Iconify from './Iconify';
import { REACT_ROOT_URL } from 'src/config';
import sharingClient from 'src/clients/SharingClient';
import documentClient from 'src/clients/DocumentClient';
import { useContext } from 'react';
import Context from 'src/context';
import { Grid } from 'swiper';
import { compose } from 'redux';

function Row(props) {
  const { row, generateSharingKey } = props;
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [newCollectionName, setNewCollectionName] = React.useState('');
  const [activeCollectionid, setActiveCollectionid] = React.useState(null);
  const [DeleteCollectionid, setDeleteCollectionid] = React.useState(null);

  const [deletingcollection, setdeletingcollection] = React.useState(false);
  const [deletingdocument, setdeletingdocument] = React.useState(false);
  const [savingnewcollname, setsavingnewcollname] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { authenticationToken, customcollections, setcustomcollections, setuseraccountinfo, useraccountinfo } =
    useContext(Context);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteCollection = async (e) => {
    setdeletingcollection(true);
    const id = DeleteCollectionid;
    const respose = await documentClient.deleteCollection(id, authenticationToken);
    const deletingcoll = customcollections.filter((item) => item.collectionid === id);
    let todeletecoll = deletingcoll[0];
    console.log(todeletecoll);
    useraccountinfo.documents_count = useraccountinfo.documents_count - todeletecoll.documents.length;
    setuseraccountinfo({ ...useraccountinfo });
    if (respose.status === 200) {
      const _data = customcollections.filter((ele) => {
        return ele.collectionid != id;
      });
      setcustomcollections(_data);
      setdeletingcollection(false);
      setDeleteCollectionid(null);
      alert('Collection Deleted');
    }
  };

  const handleDeleteDocument = async (collection, document) => {
    setdeletingdocument(true);
    const response = await documentClient.deleteDocument(document, collection, authenticationToken);
    console.log(response);

    let _collection = customcollections.map((coll) => {
      if (coll.collectionid === collection) {
        coll.documents = coll.documents.filter((doc) => {
          return doc.documentId != document;
        });
      }

      return coll;
    });

    setcustomcollections(_collection);
    if (response.status === 200) {
      useraccountinfo.documents_count = useraccountinfo.documents_count - 1;
      setuseraccountinfo({ ...useraccountinfo });
      setdeletingdocument(false);
      alert('Document Deleted');
    }
  };
  const handleEditCollection = (e, collectionid) => {
    setIsEdit(true);
    //console.log(collectionid);
    setActiveCollectionid(collectionid);
    setAnchorEl(e.currentTarget);
  };
  const handleDeleteCollectionWarning = (e, collectionid) => {
    setIsDelete(true);
    setAnchorEl(e.currentTarget);
    setDeleteCollectionid(collectionid);
  };
  const editCollection = async () => {
    setsavingnewcollname(true);
    console.log(activeCollectionid);
    await documentClient.editCollection(activeCollectionid, newCollectionName, authenticationToken);
    const _data = customcollections.map((ele) => {
      if (ele.collectionid === activeCollectionid) {
        return {
          ...ele,
          CollectionName: newCollectionName,
        };
      }
      return ele;
    });
    setcustomcollections(_data);
    setsavingnewcollname(true);

    setIsEdit(false);
    setAnchorEl(null);
  };

  const shareContent = (row) => (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="subtitle2">Share</Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={row?.Status}
              onChange={() => {
                generateSharingKey(row.collectionid, !row?.Status, row.sharingkey);
              }}
            />
          }
        />
      </FormGroup>
      <Divider sx={{ margin: '0.3rem 0' }} />
      {!Boolean(row?.Status) ? (
        ''
      ) : (
        <div>
          <Typography variant="subtitle1"> Link:</Typography>
          <Input
            sx={{ width: '100%' }}
            value={`${REACT_ROOT_URL}sharing/${row.sharingkey}`}
            endAdornment={
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(`${REACT_ROOT_URL}sharing/${row.sharingkey}`);
                }}
              >
                <Iconify icon={'clarity:copy-to-clipboard-line'} sx={{ width: 20, height: 20, mr: 1 }} />
              </IconButton>
            }
          />
          <Paper elevation={1} sx={{ m: 1, p: 1, backgroundColor: '#F0F0EF' }}>
            <Typography variant="body2">{`Share the chat bot for this collection.`}</Typography>
          </Paper>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            {' '}
            Embed:{' '}
          </Typography>
          <Input
            sx={{ width: '100%' }}
            value={`<script src="https://res.cloudinary.com/durv1xe7p/raw/upload/v1686145300/scrol-chat-bot.js" scrol-share-key=${row.sharingkey} data-button-background-color="#050038" predefined-questions='"What is scrol.ai?", "How do I create a similar chat bot for my website?", "What are the current pricing plans"'></script>`}
            endAdornment={
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(
                    `<script src="https://res.cloudinary.com/durv1xe7p/raw/upload/v1686145300/scrol-chat-bot.js" scrol-share-key=${row.sharingkey} data-button-background-color="#050038" predefined-questions='"What is scrol.ai?", "How do I create a similar chat bot for my website?", "What are the current pricing plans"'></script>`
                  );
                }}
              >
                <Iconify icon={'clarity:copy-to-clipboard-line'} sx={{ width: 20, height: 20, mr: 1 }} />
              </IconButton>
            }
          />
          <Paper elevation={1} sx={{ m: 1, p: 1, backgroundColor: '#F0F0EF' }}>
            <Typography variant="body2">{`Simply copy and paste the embed code before closing </body> tag in your site, and your chat bot/support is ready-to-use in your website.`}</Typography>
          </Paper>
        </div>
      )}
    </Stack>
  );
  const editContent = (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="subtitle1">Name your collecton </Typography>
      <TextField
        label="Collection Name"
        fullWidth
        style={{ margin: 10 }}
        onChange={(e) => setNewCollectionName(e.target.value)}
        value={newCollectionName}
      />

      <Button variant="contained" onClick={editCollection} style={{ margin: 10 }}>
        Save
      </Button>
      {savingnewcollname && <CircularProgress size="1rem" />}
    </Box>
  );
  const deletecollconfirm = (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="subtitle1">Warning:</Typography>
      <Typography variant="subtitle2">Deleting the collection will also delete all documents within it. </Typography>

      <Button variant="contained" onClick={handleDeleteCollection} style={{ margin: 10 }}>
        Delete
      </Button>
      {deletingcollection && <CircularProgress size="1rem" />}
    </Box>
  );
  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;
  function formatSizeUnits(bytes) {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + ' GB';
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes > 1) {
      bytes = bytes + ' bytes';
    } else if (bytes == 1) {
      bytes = bytes + ' byte';
    } else {
      bytes = '0 bytes';
    }
    return bytes;
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, pt: '0.3rem' }}>
        <TableCell sx={{ padding: '0.3rem' }} component="th" scope="row">
          <Button aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <Iconify icon={'ic:outline-expand-more'} sx={{ width: 20, height: 20, mr: 1 }} />
            ) : (
              <Iconify icon={'ic:round-keyboard-arrow-right'} sx={{ width: 20, height: 20, mr: 1 }} />
            )}
            {row?.Status ? (
              <Iconify icon={'material-symbols:folder-shared'} sx={{ width: 20, height: 20, mr: 1 }} />
            ) : (
              <Iconify icon={'material-symbols:folder-rounded'} sx={{ width: 20, height: 20, mr: 1 }} />
            )}

            {row.CollectionName}
          </Button>
        </TableCell>
        <TableCell sx={{ padding: '0.3rem' }} align="right">
          {row.documents.length}
        </TableCell>
        <TableCell sx={{ padding: '0.3rem' }} align="right">
          {Boolean(row?.Status) ? (
            <Chip label="Active" color="primary" size="small" />
          ) : (
            <Chip label="Not Active" size="small" />
          )}
        </TableCell>

        <TableCell sx={{ padding: '0.3rem', alignItems: 'center' }} align="right">
          {deletingcollection && <CircularProgress size="1rem" />}
          <IconButton aria-describedby={id} variant="contained" onClick={handleClick}>
            <Iconify icon={'ic:round-share'} sx={{ width: 20, height: 20, mr: 1 }} />
          </IconButton>
          <Popover
            id={id}
            open={openPop}
            anchorEl={anchorEl}
            onClose={handleClose}
            PaperProps={{
              style: { borderRadius: 5 },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box sx={{ width: isEdit ? '300px' : '600px', height: isEdit ? null : '300px', padding: '1rem' }}>
              {isEdit ? editContent : isDelete ? deletecollconfirm : shareContent(row)}
            </Box>
          </Popover>

          <IconButton>
            <Iconify
              icon={'mdi:folder-edit'}
              onClick={(e) => handleEditCollection(e, row.collectionid)}
              sx={{ width: 20, height: 20, mr: 1 }}
            />
          </IconButton>

          <IconButton onClick={(e) => handleDeleteCollectionWarning(e, row.collectionid)} value={row.collectionid}>
            <Iconify icon={'mdi:delete-forever'} sx={{ width: 20, height: 20, mr: 1 }} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Documents
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell>Metadata</TableCell>
                    <TableCell>Size</TableCell>

                    <TableCell align="right">{deletingdocument && <CircularProgress size="1rem" />} Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.documents
                    .slice(0)
                    .reverse()
                    .map((document) => (
                      <TableRow key={document.documentId}>
                        <TableCell component="th" scope="row">
                          <Link href={document.fileURL} target="_blank">
                            {document.fileName}
                          </Link>
                        </TableCell>
                        <TableCell>{document.metadata ? document.metadata : 'none'}</TableCell>
                        <TableCell>{document.filesize ? formatSizeUnits(document.filesize) : 'none'}</TableCell>

                        <TableCell align="right">
                          <IconButton
                            onClick={() => handleDeleteDocument(row.collectionid, document.documentId)}
                            value={document.documentId}
                          >
                            <Iconify icon={'mdi:delete-forever'} sx={{ width: 20, height: 20, mr: 1 }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ collections, generateSharingKey }) {
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);
  return collections.length > 0 ? (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Collection Name</TableCell>
            <TableCell align="right">Total Docs</TableCell>
            <TableCell align="right">Shared Status</TableCell>

            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collections.map((row) => (
            <Row key={row.collectionId} row={row} generateSharingKey={generateSharingKey} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <>
      <Box
        alignItems="center"
        justifyContent="center"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}
      >
        {isVisible ? (
          <CircularProgress />
        ) : (
          <Typography variant="h5">You have no collection, create one to get started.</Typography>
        )}
      </Box>
    </>
  );
}
