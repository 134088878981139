import { CORPORA_URI } from '../config';

class DocumentClient {
  async getDocuments(authenticationToken) {
    //let formData = new FormData();
    //formData.append('userId', userId)

    let responseJson = await fetch(CORPORA_URI + '/get-documents', {
      method: 'POST',
      //body: formData,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();
    console.dir(response);

    return response.data.list.map((d) => ({
      fileName: d.file_name,
      id: d.id,
      fileUrl: d.file_url,
    }));
  }

  async deleteDocument(id, collection, authenticationToken) {
    let formData = new FormData();
    formData.append('documentId', id);
    formData.append('collectionid', collection);

    let responseJson = await fetch(CORPORA_URI + '/delete-document', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();
    console.dir(response);

    return response;
  }
  async deleteCollection(id, authenticationToken) {
    let formData = new FormData();
    formData.append('collectionid', id);

    let responseJson = await fetch(CORPORA_URI + '/delete-collections', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();
    console.dir(response);

    return response;
  }

  async uploadDocument(file, authenticationToken, collectionid, metadata) {
    let formData = new FormData();
    console.log(file);

    formData.append('file', file);
    formData.append('collectionid', collectionid);
    formData.append('metadata', metadata);

    console.log(collectionid);
    let responseJson = await fetch(CORPORA_URI + '/upload-file', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();
    console.log(response);

    if (responseJson.status === 402) {
      throw { type: 'documentLimitExceeded', message: response };
    }

    if (response.status === 500) {
      throw new Error();
    }
    return {
      fileName: response.fileName,
      documentId: response.documentId,
      fileUrl: response.file_url,
      filesize: response.filesize,
      metadata: response.metadata,
      pagecount: response.pagesize,
      parentcollection: response.parentcollection,
      status: response.status,
      sessionid: response.sessionid,
      sessionname: response.sessionname,
    };
  }

  async getDocumentUrl(id) {
    let formData = new FormData();
    formData.append('documentId', id);

    let responseJson = await fetch(CORPORA_URI + '/view-document', {
      method: 'POST',
      body: formData,
    });

    let response = await responseJson.json();
    return response.data;
  }
  async addCollection(collectionname, authenticationToken) {
    let formData = new FormData();
    // formData.append('documentId', id);
    formData.append('collectionname', collectionname);

    let responseJson = await fetch(CORPORA_URI + '/add-collections', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();

    return response;
  }
  async editCollection(collectionid, collectionname, authenticationToken) {
    let data = {
      collectionid: collectionid,
      newcollectionname: collectionname,
    };

    let responseJson = await fetch(CORPORA_URI + '/rename-collection', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();

    return response;
  }

  async getDocumentStatus(id) {
    let formData = new FormData();
    formData.append('documentId', id);

    let responseJson = await fetch(CORPORA_URI + '/get-document-status', {
      method: 'POST',
      body: formData,
    });

    let response = await responseJson.json();
    return response;
  }

  async fetchWebLink(link, authenticationToken) {
    let data = {
      link: link,
    };

    try {
      let responseJson = await fetch(CORPORA_URI + '/get-web-links', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer: ' + authenticationToken,
        },
      });
      let response = await responseJson.json();

      return response;
    } catch (e) {
      console.log(e);
    }
  }
  async uploadLinks(link, collectionId, authenticationToken) {
    let data = {
      url: link,
      collectionid: collectionId,
    };

    try {
      let responseJson = await fetch(CORPORA_URI + '/upload-web', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer: ' + authenticationToken,
        },
      });
      let response = await responseJson.json();

      return response;
    } catch (e) {
      console.log(e);
    }
  }

  async getDocumentsByCollectionId(collectionId, authenticationToken) {
    let responseJson = await fetch(CORPORA_URI + '/get-documents-by-collection', {
      method: 'POST',
      //body: formData,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authenticationToken,
      },
      body: JSON.stringify({ collectionid: collectionId }),
    });

    let response = await responseJson.json();
    console.dir(response);

    return response;
  }
}

const documentClient = new DocumentClient();
export default documentClient;
