import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  Card,
  Container,
  CardHeader,
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Tab,
  CircularProgress,
  CardContent,
  CardActions,
  List,
  ListItem,
  Divider,
} from '@mui/material';

export default function SubscriptionPrice(props) {
  return (
    <Card sx={{ textAlign: 'center', m: 1 }}>
      <CardContent>
        <Typography variant="h6" sx={{ color: 'text.primary' }}>
          {props.name}{' '}
        </Typography>
        <Divider>{props.extra}</Divider>
        <List>
          <ListItem>
            <Typography variant="body1">{props.queries} Credits </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">{props.pdfCount} Documents</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">${props.price}</Typography>
          </ListItem>
        </List>
      </CardContent>
      <CardActions sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Button variant="contained" onClick={() => props.onClick()}>
          {props.name == 'Add-on' ? 'Buy' : 'Subscribe'}
        </Button>
      </CardActions>
    </Card>
  );
}
