import { CORPORA_URI } from 'src/config';

class BotClient {
  async getUsersBots(authenticationToken) {
    let responseJson = await fetch(CORPORA_URI + '/get-users-bots', {
      method: 'GET',

      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();

    return response;
  }
  async getBotInfo(bottoken) {
    let responseJson = await fetch(CORPORA_URI + '/get-bot-info', {
      method: 'POST',
      body: JSON.stringify({ botid: bottoken }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    let response = await responseJson.json();

    return response;
  }
  async getPublicBots() {
    let responseJson = await fetch(CORPORA_URI + '/get-public-bots', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    let response = await responseJson.json();

    return response;
  }
  async askBot(payload, authenticationToken) {
    let responseJson = await fetch(CORPORA_URI + '/askbot', {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();

    return response;
  }
  async getBotById(botid, authenticationToken) {
    let responseJson = await fetch(CORPORA_URI + '/get-bot-by-id', {
      method: 'POST',
      body: JSON.stringify({ botid: botid }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();

    return response;
  }
  async addLLMKey(payload, authenticationToken) {
    let responseJson = await fetch(CORPORA_URI + '/add-llm-key', {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();

    return response;
  }
  async addBot(formData, authenticationToken) {
    let responseJson = await fetch(CORPORA_URI + '/add-bot', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();
    return response;
  }
  async updateBot(formData, authenticationToken) {
    let responseJson = await fetch(CORPORA_URI + '/update-bot', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();
    return response;
  }
  async getBotSessionData(botid, authenticationToken) {
    let responseJson = await fetch(CORPORA_URI + '/get-botsession-by-botid', {
      method: 'POST',
      body: JSON.stringify({ botid: botid }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();
    return response;
  }
  async deleteBot(botid, authenticationToken) {
    const data = {
      botid: botid,
    };

    let responseJson = await fetch(CORPORA_URI + '/delete-bot', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();
    return response;
  }
  async deleteLLMKey(vendor, authenticationToken) {
    const data = {
      vendor: vendor,
    };

    let responseJson = await fetch(CORPORA_URI + '/delete-llm-key', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();
    return response;
  }
  async checkLLMKeyStatus(authenticationToken) {
    let responseJson = await fetch(CORPORA_URI + '/check-llm-key-status', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();
    return response;
  }
}

const obj = new BotClient();
export default obj;
