import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { CORPORA_URI } from '../config';

import ReactMarkdown from 'react-markdown';
import Context from '../context';
import { useContext } from 'react';
import BotClient from '../clients/BotClient';
import { styled } from '@mui/material/styles';

import remarkGfm from 'remark-gfm';
import { SSE } from 'sse';

import rehypeRaw from 'rehype-raw';
import markdownStyles from './markdown.module.css';
import logo from '../assets/logo.png';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Box,
  InputAdornment,
  TextField,
  CircularProgress,
  ToggleButton,
  Stack,
  ToggleButtonGroup,
  Dialog,
  DialogContent,
  Tooltip,
  Switch,
  tooltipClasses,
  IconButton,
  Button,
  Avatar,
  Card,
  Link,
  Chip,
} from '@mui/material';
// components
import PageComp from '../components/Page';
import Iconify from '../components/Iconify';
import BouncingDotsLoader from 'src/components/BouncingDotsLoader';
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
import { auth, db, logout } from '../firebase';

// sections
import { ApplicationPanelBS } from '../sections/@dashboard/app';
import useResponsive from 'src/hooks/useResponsive';

import PDFViewer from './PDFViewer';
import is from 'date-fns/locale/is';

// ----------------------------------------------------------------------
const APPBAR_DESKTOP = 92;
const DRAWER_WIDTH = 280;
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(102, 115, 128, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      maxWidth: 180,
      padding: '15px 15px',
    },
  })
);
export default function Agents() {
  let { bottoken } = useParams();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['session_auth_token']);
  const isMobile = useResponsive('down', 'sm');
  const isTablet = useResponsive('down', 'lg');
  const theme = useTheme();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [question, setQuestion] = useState('');
  const [agent, setagent] = useState({});
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [conversation, setconversation] = useState([
    { bot: 'Hello 👋, how can I help you?', metadata: { document: [] } },
  ]);
  const chatContainerRef = useRef(null);
  const [sessionid, setsessionid] = useState(null);
  useEffect(() => {
    async function fetchData() {
      let botinfo = await BotClient.getBotInfo(bottoken);
      if (botinfo.visibility == 'public') {
        console.log('public agent');
        if (cookies.session_auth_data) {
          if (cookies.session_auth_data.idToken) {
            setagent(botinfo);
          } else {
            navigate('/login', { state: { from: window.location.pathname } });
          }
        } else {
          navigate('/login', { state: { from: window.location.pathname } });
        }
      } else {
        setagent(botinfo);
      }
    }
    fetchData();
  }, []);
  console.log(conversation);
  async function sendQuestion(question) {
    return new Promise(async (resolve) => {
      setQuestion('');
      let chatcontexts = conversation.slice(-1).map((item) => item.bot);
      const chatcontext = chatcontexts.join('\n');
      let payload = {
        question: question,
        botid: bottoken,
        chatcontext: chatcontext,
        sessionid: sessionid,
      };
      setconversation((prevconv) => [...prevconv, { user: question }]);
      setconversation((prevconv) => [...prevconv, { bot: '' }]);
      // let response = await BotClient.askBot(
      //   payload,
      //   agent.visibility == 'public' ? cookies.session_auth_data.idToken : null
      // );
      let AuthToken = agent.visibility == 'public' ? cookies.session_auth_data.idToken : null;
      if (payload.question != '') {
        let url = CORPORA_URI + '/askbot';
        let source = new SSE(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer: ' + AuthToken,
          },
          method: 'POST',
          payload: JSON.stringify(payload),
        });
        source.addEventListener('message', (e) => {
          if (e.data != '[DONE]') {
            console.log(e);
            let payload = JSON.parse(e.data);
            let updatedConversation = [...conversation, { user: question }, { bot: '' }]; // Create a copy of the conversation array
            updatedConversation[updatedConversation.length - 1].bot = payload.data.bot; // Update the bot value of the last element
            updatedConversation[updatedConversation.length - 1].metadata = payload.data.metadata; // Update the bot value of the last element
            setconversation(updatedConversation);
            setsessionid(payload.data.sessionid);

            if (payload.data.status == 401) {
              navigate('/login');
            }
          } else {
            source.close();
          }
        });

        source.addEventListener('readystatechange', (e) => {
          if (e.readyState >= 2) {
            console.log('stream finished');
          }
        });

        source.stream();
      }
    });
  }
  let onKeyPress = (e) => {
    if (e.keyCode === 13) {
      setIsUserScrolling(false);
      scrollToBottom();
      sendQuestion(e.target.value);
    }
  };
  let handlesend = (e) => {
    setIsUserScrolling(false);
    scrollToBottom();
    sendQuestion(question);
  };
  const handleQuestion = (event) => {
    setQuestion(event.target.value);
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    console.log(isUserScrolling);
    if (!isUserScrolling) {
      scrollToBottom();
    }
  }, [conversation, isUserScrolling]);
  const handleScroll = () => {
    const chatContainer = chatContainerRef.current;
    const buffer = 10;
    if (chatContainer.scrollHeight - chatContainer.scrollTop <= chatContainer.clientHeight + buffer) {
      // User has scrolled to the bottom
      setIsUserScrolling(false);
    } else {
      console.log('Scrolling');
      // User is actively scrolling
      setIsUserScrolling(true);
    }
  };
  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }
  console.log(isUserScrolling);
  return (
    <Grid
      title="Dashboard"
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        height: '90vh',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Grid
        sx={{
          position: 'fixed',
          top: 0,
          left: isTablet ? 0 : Boolean(pdfUrl) ? 0 : DRAWER_WIDTH,

          width: Boolean(pdfUrl)
            ? '58.33%'
            : isMobile
            ? '100%'
            : isTablet
            ? '100%'
            : `calc(100% - ${2 * DRAWER_WIDTH + 1}px)`,
          minHeight: '10vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          flexDirection: 'column',
          padding: isMobile ? '0rem' : '2rem',
          // backgroundImage: 'linear-gradient(to top,white,80%, transparent)',
          backgroundColor: isMobile ? 'transparent' : 'transparent',
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <Avatar src={agent.avatar} alt="photoURL" sx={{ mr: isMobile ? null : 2, width: 35, height: 35 }} width={5} />

          <Typography variant="subtitle1">{agent.name}</Typography>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            p: 1,
          }}
        >
          <Typography variant="body2">{agent.description}</Typography>
        </Grid>
      </Grid>
      <Grid
        lg={8}
        ref={chatContainerRef}
        sx={{
          flex: 6,
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'column',
          overflowY: 'scroll',
          width: isMobile ? '100%' : isTablet ? '90%' : '70%',
          mb: 2,
          mt: 10,
        }}
        onScroll={handleScroll}
      >
        <div style={{ maxHeight: '100%' }}>
          {conversation.map((chat, index) => {
            return chat.user ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  margin: 25,
                }}
              >
                <Card key={index} sx={{ p: 2, maxWidth: '90vh', backgroundColor: '#f2f2f2' }}>
                  <Typography variant="body" sx={{ fontSize: isMobile ? '12px' : '90%' }}>
                    {chat.user}
                  </Typography>{' '}
                </Card>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  margin: 25,
                }}
              >
                <Avatar
                  src={agent.avatar}
                  alt="photoURL"
                  sx={{ mr: 3, width: 25, height: 25, display: isMobile ? 'none' : 'block' }}
                  width={5}
                />
                {/* agent modigy */}
                <Card key={index} sx={{ p: 2, maxWidth: '90vh', mb: 2, pl: 4, fontSize: isMobile ? '12px' : '90%' }}>
                  {chat.bot ? (
                    <>
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        className={markdownStyles}
                        rehypePlugins={[rehypeRaw]}
                        style={{ fontSize: isMobile ? '12px' : '90%', p: 2 }}
                      >
                        {chat.bot}
                      </ReactMarkdown>
                      {chat.metadata.document.length > 0 &&
                        chat.metadata.document.map((doc, index) => {
                          return isValidHttpUrl(doc.file) ? (
                            <Link href={doc.file} target="_blank" key={index}>
                              <Chip
                                sx={{ mt: 2, ml: 1 }}
                                icon={
                                  <>
                                    <Iconify
                                      icon={`material-symbols:counter-${index + 1}-rounded`}
                                      sx={{ ml: '0.3rem' }}
                                      color="#808080"
                                    />
                                    <Iconify icon={'material-symbols:link'} sx={{ ml: '0.3rem' }} />
                                  </>
                                }
                                label={`${index + 1}. ${doc.file.slice(0, 40)} `}
                                onClick={() => {}}
                                variant="outlined"
                                style={{ fontSize: '13px', color: '#386ea2' }}
                              />
                            </Link>
                          ) : (
                            <Link href={`${doc.file_url}#page=${doc.page}`} target="_blank" key={index}>
                              <Chip
                                sx={{ mt: 2, ml: 1 }}
                                icon={
                                  <>
                                    <Iconify
                                      icon={`material-symbols:counter-${index + 1}-rounded`}
                                      sx={{ ml: '0.3rem' }}
                                      color="#808080"
                                    />
                                    <Iconify icon={'material-symbols:link'} sx={{ ml: '0.3rem' }} />
                                  </>
                                }
                                label={`${index + 1}. ${doc.file.slice(0, 40)} `}
                                onClick={() => {}}
                                variant="outlined"
                                style={{ fontSize: '13px', color: '#386ea2' }}
                              />
                            </Link>
                          );
                        })}
                    </>
                  ) : (
                    <BouncingDotsLoader />
                  )}
                </Card>
              </div>
            );
          })}
        </div>
      </Grid>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: isTablet ? 0 : Boolean(pdfUrl) ? 0 : DRAWER_WIDTH,

          width: Boolean(pdfUrl)
            ? '58.33%'
            : isMobile
            ? '100%'
            : isTablet
            ? '100%'
            : `calc(100% - ${2 * DRAWER_WIDTH + 1}px)`,
          minHeight: '10vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',

          padding: isMobile ? '0rem' : '2rem',
          // backgroundImage: 'linear-gradient(to top,white,80%, transparent)',
          backgroundColor: isMobile ? 'transparent' : 'transparent',
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ width: isMobile ? '100%' : '100%', mt: isTablet ? (isMobile ? 7 : 4) : 0 }}
        >
          <Grid
            item
            xs={11}
            lg={9}
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
          >
            <TextField
              id="outlined-basic"
              label="Ask"
              variant="outlined"
              style={{ width: '100%', backgroundColor: 'white' }}
              onKeyUp={onKeyPress}
              value={question}
              onChange={handleQuestion}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handlesend}>
                      <Iconify icon="carbon:send-alt" sx={{ color: 'text.disabled', width: 25, height: 25 }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>

      {Boolean(pdfUrl) ? (
        <Grid item xs={6} md={6} lg={6}>
          <Button
            onClick={() => {
              setPdfUrl(null);
              setpdfKeyword('');
              setpdfPage(0);
              setShowSidebar(true);
            }}
            variant="text"
            size="small"
            sx={{ color: 'red', transform: { translate: { x: '-50%' } } }}
          >
            <Iconify icon={'ic:round-close'} sx={{ width: 20, height: 20, mr: 1 }} />
          </Button>

          <Box>{Boolean(pdfUrl) ? <PDFViewer pdfUrl={pdfUrl} keyword={pdfKeyword} page={pdfPage} /> : ''}</Box>
        </Grid>
      ) : null}
    </Grid>
  );
}
