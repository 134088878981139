import { useState, useEffect, useRef, useContext } from 'react';

import { Link, matchPath, useLocation, useParams, useNavigate } from 'react-router-dom';

import { useAuthState } from 'react-firebase-hooks/auth';
import userClient from '../clients/UserClient';
import UserSubscription from './UserSubscription';
import ApiKeyCreator from './ApiKeyCreator';
// @mui
import Sharing from './Sharing';
import { Grid, Card, Button, Typography, Divider } from '@mui/material';
// components
import Page from '../components/Page';

import Iconify from '../components/Iconify';
// sections

import { auth, db, logout } from '../firebase';
import Context from '../context';
import PricingPage from './PricingPage';
// ----------------------------------------------------------------------

export default function Profile() {
  const [firebaseUser, isFirebaseUserLoading, error] = useAuthState(auth);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  let { useraccountinfo } = useContext(Context);
  let navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      if (isFirebaseUserLoading) return;
      if (!firebaseUser) return navigate('/login');
      console.dir(firebaseUser);

      let response = await userClient.getUser(await firebaseUser.getIdToken());

      setUser(response);
      setIsLoading(false);
    }

    fetchData();
  }, [firebaseUser, isFirebaseUserLoading]);

  const [sharingKey, setSharingKey] = useState(null);
  const [isHidingLogo, setIsHidingLogo] = useState(false);
  const [isHidingLink, setIsHidingLink] = useState(false);
  const [sharingTitle, setSharingTitle] = useState(null);
  const [isSharingLinkCopied, setIsSharingLinkCopied] = useState(false);
  const [isEmbedCodeCopied, setIsEmbedCodeCopied] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (isFirebaseUserLoading) return;
      if (!firebaseUser) return navigate('/login');

      let response = await userClient.getUser(await firebaseUser.getIdToken());
      setUser(response);
      setIsHidingLink(response.isHidingLink);
      setIsHidingLogo(response.isHidingLogo);
      setSharingKey(response.sharingKey);
      setSharingTitle(response.sharingTitle);
      setIsLoading(false);
    }

    fetchData();
  }, [firebaseUser, isFirebaseUserLoading]);

  async function createSharingLink() {
    const response = await sharingClient.createSharingLink(await firebaseUser.getIdToken());
    setSharingKey(response.sharing_key);
    setIsSharingLinkCopied(false);
  }

  async function toggleHidingLogo(event) {
    const value = event.target.checked;
    setIsHidingLogo(value);
    const response = await sharingClient.updateIsHidingLogo(await firebaseUser.getIdToken(), value);
  }

  async function toggleHidingLink(event) {
    const value = event.target.checked;
    setIsHidingLogo(value);
    const response = await sharingClient.updateIsHidingLink(await firebaseUser.getIdToken(), value);
  }

  async function updateSharingTitle(event) {
    const title = event.target.value;
    setSharingTitle(title);
    const response = await sharingClient.updateSharingTitle(await firebaseUser.getIdToken(), title);
  }

  async function deleteSharingLink() {
    setSharingKey(null);
    setIsSharingLinkCopied(false);
    await sharingClient.deleteSharingLink(await firebaseUser.getIdToken());
  }

  function copySharingLink() {
    navigator.clipboard.writeText(`https://askcorpora.com/dashboard/sharing/${sharingKey}`);
    setIsSharingLinkCopied(true);
    setIsEmbedCodeCopied(false);
  }

  function copyEmbedCode() {
    navigator.clipboard.writeText(
      `<iframe src="https://askcorpora.com/dashboard/sharing/${sharingKey}" title="${
        sharingTitle || ''
      }" height="500" width="900"></iframe>`
    );
    setIsEmbedCodeCopied(true);
    setIsSharingLinkCopied(false);
  }

  const linkStyle = {
    'max-width': '90%',
  };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (
    <>
      <Button variant="contained" component={Link} style={{ margin: 10 }} to="/dashboard/ask">
        Go to Chat
      </Button>
      <Card style={{ margin: 10 }}>
        {/* <CardHeader title={title} /> */}

        <Grid item xs={4}>
          {/* <Loading /> */}
        </Grid>
        <Grid item xs={8} md={8} lg={8} style={{ padding: 10 }}>
          {' '}
          <Typography variant="h5" style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
            <Iconify icon={'gg:profile'} sx={{ width: 20, height: 20, mr: 1 }} />
            Profile{' '}
          </Typography>
          <Divider />
          <Grid item xs={4} style={{ padding: 10 }}>
            <Typography variant="h6">Basic Info </Typography>
            <Typography variant="subtitle2">
              Name:{' '}
              <Typography variant="body2" sx={{ display: 'inline' }}>
                {useraccountinfo.name}
              </Typography>
            </Typography>
            <Typography variant="subtitle2">
              Email:{' '}
              <Typography variant="body2" sx={{ display: 'inline' }}>
                {firebaseUser.email}
              </Typography>{' '}
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ padding: 10 }}>
            <Typography variant="h6">Subscription </Typography>
            <Typography variant="subtitle2">
              Remaining Credits:{' '}
              <Typography variant="body2" sx={{ display: 'inline' }}>
                {' '}
                {useraccountinfo.searches_limit - useraccountinfo.searches_count}{' '}
              </Typography>
            </Typography>
            <Typography variant="subtitle2">
              Plan:{' '}
              <Typography variant="body2" sx={{ display: 'inline' }}>
                {' '}
                {useraccountinfo.subscription_status ? `${useraccountinfo.subscription_status}` : 'Free'}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Card>
      {/* <Sharing /> */}
      <Card style={{ margin: 10 }}>
        <Grid item xs={8} md={8} lg={8} style={{ padding: 10 }}>
          <UserSubscription></UserSubscription>
        </Grid>
      </Card>
      {/* <Card style={{ margin: 10 }}>
        <Grid item xs={8} md={8} lg={8} style={{ padding: 10 }}>
          <ApiKeyCreator></ApiKeyCreator>
        </Grid>
      </Card> */}
    </>
  );
}
