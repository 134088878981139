import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import BotClient from '../../clients/BotClient';
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  Chip,
  Divider,
  Popover,
  TextField,
  Alert,
  CircularProgress,
  Tooltip,
  tooltipClasses,
} from '@mui/material';

// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';
import logo from '../../assets/logo.png';
import scrolai from '../../assets/scrolai.png';
// ----------------------------------------------------------------------
import Uploader from 'src/pages/Uploader';
import Iconify from 'src/components/Iconify';

import Context from '../../context';
import { useContext } from 'react';
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
import { data } from 'src/constant';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab';
import { add } from 'lodash';
const DRAWER_WIDTH = 280;
const APPBAR_DESKTOP = 92;

const RootStyle = styled('div')(({ theme }) => ({
  pt: APPBAR_DESKTOP,
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(102, 115, 128, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      maxWidth: 180,
      padding: '15px 15px',
    },
  })
);

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  let { user, useraccountinfo, navsessions, setnavsessions, authenticationToken, llmKeys, setLLMKeys } =
    useContext(Context);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEle, setAnchorEle] = useState(null);
  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;
  const [help, setHelp] = useState(false);
  const [navcon, setnavcon] = useState([]);
  const [isllmkey, setisllmkey] = useState(false);
  const [llmkey, setllmkey] = useState(null);
  const [llmvendor, setllmvendor] = useState(null);
  const [isaddingkey, setisaddingkey] = useState(false);
  const [logmsg, setlogmsg] = useState('');
  const [severity, setseverity] = useState('info');

  useEffect(() => {
    async function fetchData() {
      let response = await BotClient.checkLLMKeyStatus(authenticationToken);
      if (response.llmkeys.length > 0) {
        response.llmkeys.forEach((key) => {
          setLLMKeys((prevState) =>
            prevState.map((item) => {
              if (item.vendor == key.vendor) {
                return { ...item, key: key.key };
              }
              return item;
            })
          );
        });
      }
    }
    fetchData();
  }, []);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleLLMKeyClick = (e, vendor) => {
    setisllmkey(true);
    setllmvendor(vendor);
  };
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);
  useEffect(() => {
    let helpStatus = localStorage.getItem('help');
    if (helpStatus == undefined) {
      setHelp(true);
    } else if (helpStatus === false) {
      setHelp(true);
    }
  }, [pathname]);
  useEffect(() => {
    console.log(useraccountinfo);
    if (useraccountinfo.sessions) {
      const adminSessions = useraccountinfo.sessions.filter((session) => session.session_type === 'admin');
      adminSessions.sort((a, b) => a.timestamp - b.timestamp);
      let adminsessions = [];
      adminSessions.forEach((session) => {
        adminsessions.push({
          title: session.name,
          path: `/dashboard/s/${session.session_id}`,
          icon: getIcon('iconoir:message'),
        });
      });
      setnavsessions((prev) => [...adminsessions.reverse()]);
      console.log(navConfig);
    } else {
      console.log('user still loading');
    }
  }, [useraccountinfo.sessions]);

  const addllmkey = async () => {
    setisaddingkey(true);
    setlogmsg('');
    let payload = {
      vendor: llmvendor,
      key: llmkey,
    };
    console.log(payload);
    let response = await BotClient.addLLMKey(payload, authenticationToken);
    if (response.status == 200) {
      setseverity('success');
      setlogmsg('Key added successfully.');
      setisaddingkey(false);
      setLLMKeys((prevState) =>
        prevState.map((item) => {
          if (item.vendor === llmvendor) {
            return { ...item, key: true };
          }
          return item;
        })
      );
      setisllmkey(false);
    }
    setisllmkey(false);
    setllmvendor(false);
    console.log(response);
  };
  const deletellmkey = async (e, vendor) => {
    setisaddingkey(true);
    setlogmsg('');
    let response = await BotClient.deleteLLMKey(vendor, authenticationToken);
    if (response.status == 200) {
      setseverity('success');
      setlogmsg('Key deleted successfully.');
      setisaddingkey(false);

      setLLMKeys((prevState) =>
        prevState.map((item) => {
          if (item.vendor === vendor) {
            return { ...item, key: null };
          }
          return item;
        })
      );
    } else {
      setseverity('warning');
      setlogmsg('Something went wrong.');
      setisaddingkey(false);
    }
    console.log(response);
  };
  const handleClose = () => {
    setAnchorEle(null);
    setisllmkey(false);
  };
  const handleKeyChange = (event) => {
    setllmkey(event.target.value);
  };
  function AddKey() {
    if (llmvendor == 'openai') {
      return (
        <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <TextField label={`${llmvendor} key`} style={{ margin: 10 }} value={llmkey} onChange={handleKeyChange} />
          <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button variant="contained" onClick={addllmkey} sx={{ m: 1 }}>
              Add
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                setisllmkey(false);
                setllmvendor(null);
              }}
              sx={{ m: 1 }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      );
    } else if (llmvendor == 'anthropic') {
      return (
        <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <TextField label={`${llmvendor} key`} style={{ margin: 10 }} value={llmkey} onChange={handleKeyChange} />
          <Button variant="contained" onClick={addllmkey} sx={{ width: '50%' }}>
            Add
          </Button>
        </Grid>
      );
    } else {
      return null;
    }
  }
  console.log(llmKeys);
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack sx={{ px: 2.5, pb: 3, mt: 3 }} direction="row" alignItems="center">
        <img src={logo} style={{ width: '30px', height: '30px' }} />
        {/* <img src={scrolai} style={{ width: '100px' }} /> */}
      </Stack>

      {/* <Box sx={{ mt: 10, mb: 2, mx: 1 }}>
        <Link underline="none" component={RouterLink} to="/dashboard/profile">
          <AccountStyle>
            <Avatar src={user.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2, overflow: 'hidden' }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user.displayName}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {user.email}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box> */}

      <Button
        variant="contained"
        aria-describedby={'upload'}
        style={{ margin: '1rem 1rem 3rem 1rem', fontSize: '12px' }}
        onClick={handleClick}
      >
        Import Documents or Videos
      </Button>
      {logmsg && (
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Alert severity={severity}>{logmsg}</Alert>
        </Grid>
      )}
      {isaddingkey && (
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size="1rem" />
        </Grid>
      )}
      {llmKeys.map((key) => {
        if (key.key) {
          return (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mb: 2, alignItems: 'center' }}>
              <LightTooltip
                title="we can't provide any api use analaysis yet but your key might be used in: Document upload, Answer generation(big models), langchain agents and other tasks(small models). but all the uses will be for your tasks/queries only."
                placement="right"
              >
                <Typography variant="subtitle1">
                  <span style={{ fontSize: '17px', color: '#65a765' }}>●</span> {key.vendor} key enabled
                </Typography>
              </LightTooltip>
              <IconButton onClick={(e) => deletellmkey(e, key.vendor)}>
                <Iconify icon="ic:baseline-delete" sx={{ color: '#C70000', width: 16, height: 16 }} />
              </IconButton>
            </Grid>
          );
        } else {
          if (!isllmkey) {
            return (
              <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  aria-describedby={'upload'}
                  style={{ margin: '1rem 1rem 3rem 1rem', width: '50%', fontSize: '12px' }}
                  onClick={(e) => handleLLMKeyClick(e, key.vendor)}
                >
                  Add {key.vendor} Key
                </Button>
              </Grid>
            );
          }
        }
      })}
      {
        llmvendor == 'openai' ? (
          <Grid
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 2 }}
          >
            <TextField label={`${llmvendor} key`} style={{ margin: 10 }} value={llmkey} onChange={handleKeyChange} />
            <Link href={'https://platform.openai.com/account/api-keys'} target="_blank">
              platform.openai.com
            </Link>
            <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
              <Button variant="contained" onClick={addllmkey} sx={{ m: 1 }}>
                Add
              </Button>
              <Button
                variant="contained"
                onClick={(e) => {
                  setisllmkey(false);
                  setllmvendor(null);
                }}
                sx={{ m: 1 }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        ) : llmvendor == 'cohere' ? null : null //</Grid> //  </Grid> //    </Button> //      Close //    > //      sx={{ m: 1 }} //      }} //        setllmvendor(null); //        setisllmkey(false); //      onClick={(e) => { //      variant="contained" //    <Button //    </Button> //      Add //    <Button variant="contained" onClick={addllmkey} sx={{ m: 1 }}> //  <Grid sx={{ display: 'flex', flexDirection: 'row' }}> //  <TextField label={`${llmvendor} key`} style={{ margin: 10 }} value={llmkey} onChange={handleKeyChange} /> //<Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
      }
      <Uploader id={'upload'} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <NavSection navConfig={navConfig} />

      <Divider />
      <Scrollbar>
        <NavSection navConfig={navsessions} />
      </Scrollbar>
      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 3 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6">Join us!</Typography>
          </Box>
          <Grid>
            <IconButton href="https://twitter.com/scrol_ai">
              <Iconify icon="ant-design:twitter-outlined" sx={{ color: 'text.primary', width: 30, height: 30 }} />
            </IconButton>
            <IconButton href="https://discord.com/invite/EjrvfCKuej">
              <Iconify icon="ic:twotone-discord" sx={{ color: 'text.primary', width: 30, height: 30 }} />
            </IconButton>
            <IconButton href="https://www.linkedin.com/company/scrol-ai/">
              <Iconify icon="ri:linkedin-box-fill" sx={{ color: 'text.primary', width: 30, height: 30 }} />
            </IconButton>
            <IconButton onClick={() => setHelp(true)}>
              <Iconify icon="ic:baseline-help" sx={{ color: 'text.primary', width: 30, height: 30 }} />
            </IconButton>
          </Grid>
        </Stack>

        <Dialog
          open={help}
          onClose={() => {
            setHelp(false);
            localStorage.setItem('help', true);
          }}
          maxWidth={'lg'}
          fullWidth={true}
          PaperProps={{
            style: { borderRadius: 5 },
          }}
        >
          <DialogTitle>
            <Typography variant="h3"> HELP</Typography>
          </DialogTitle>
          <DialogContent sx={{ mt: '1rem', textAlign: 'justify', fontSize: '0.8rem' }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">School/University:</Typography>
                    Enhance your study experience by uploading your textbooks and easily chat with the content to find
                    answers and explanations. Create a Collection for each subject and upload all your material and
                    notes there. You can always log back in and chat with them.
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Legal and compliance:</Typography>
                    Make compliance easier by uploading regulatory documents and quickly search through them using our
                    chat interface. Anonymize and upload legal agreements to analyze, compare, or find specific clauses
                    with ease using our chat tool.
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Financial analysis:</Typography>
                    Upload annual reports and financial data to perform financial analysis by chatting with the content,
                    quickly extracting key metrics, ratios, and performance indicators for informed decision-making.
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Research: </Typography>
                    Upload multiple research papers on a topic to easily chat and find connections, trends, or
                    contrasting viewpoints across the studies.
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Content creation: </Typography>
                    Use GPT-4 to gather information and insights from the uploaded documents and create articles, blog
                    posts, ad copy.
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Personalized book interaction: </Typography>
                    Upload and chat with your favorite books to explore characters, themes, and plot-lines in a unique
                    and engaging way, deepening your connection with the stories you love.
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  {data.helpTimeline.map((step, idx) => (
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot color="info" variant="outlined" sx={{ padding: '4px 8px' }}>
                          <Typography variant="caption">{idx + 1}</Typography>
                        </TimelineDot>
                        <TimelineConnector color="info" />
                      </TimelineSeparator>
                      <TimelineContent>
                        <span style={{ fontWeight: 'bold' }}>{step.title}</span>: {step.content}
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
