export const data = {
  helpTimeline: [
    {
      title: 'Create you first Collection',
      content: `A Collection is a folder to better manage your files. Select the Collections tab on the left panel and click “Create Collection”, give it a name and “Add”`,
    },
    {
      title: 'Upload multiple Documents',
      content: `To upload a document, click the "Upload Documents” button, select the Collection where you want your file saved, select the type [file, or html link] and select the files from your device. You can now add metadata (optional) to every file. This improves search accuracy with multiple files in a Collection. We support PDFs ups to 5,000 pages, please note that the upload time is around 1 min per 100 pages. Our platform supports a variety of formats, including PDF, Word, Excel.`,
    },
    {
      title: 'Chat',
      content: `Once your document is uploaded, go to the Chat tab and start a conversation with your documents. The AI will provide relevant information and insights extracted directly from your document. Please make sure that you selected a Collection first. You can also Chat with a specific documenting inside a Collection by selecting the document and turning on the “Search by Document”`,
    },
    {
      title: 'Side-by-Side View',
      content: `As you chat, our tool will automatically provide the sources for its answers. Click on any source and we’ll display a side-by-side view of your document and the extracted text. This feature allows you to see exactly where the information is coming from, ensuring accuracy and transparency.`,
    },
    {
      title: 'Generate insight',
      content: `Select a document, then click the ‘Generate insights” and our app will Click to generate 5 interesting topics to read bout from from the selected document.`,
    },
    {
      title: 'Search Functionality',
      content: `Easily search your document for specific keywords or phrases using the built-in search bar. The search results will be highlighted in the side-by-side view, helping you quickly locate relevant information.`,
    },
    {
      title: 'Save and run Prompts',
      content: `Utilize the 'Tasks' dropdown menu to name, save, and run Prompts as Tasks. Activate Tasks using the 'Run' button. Run Tasks in step-by-step mode to treat each new row as a separate query.`,
    },
    {
      title: 'Styles',
      content: `Use the 'Style' dropdown menu to create and save custom Chat Styles that modify the chat output according to your preferences. The selected Chat Style will be applied in all modes, including single chat queries.`,
    },
  ],
};
