import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
// material

import { styled } from '@mui/material/styles';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  Drawer,
  Typography,
  Avatar,
  Stack,
  Slider,
  Divider,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  TextField,
  Grid,
  FormControlLabel,
  Switch,
  Tooltip,
  tooltipClasses,
  CircularProgress,
  Alert,
  Collapse,
} from '@mui/material';

// hooks
import useResponsive from '../../hooks/useResponsive';
// components

import { REACT_ROOT_URL } from 'src/config';
import Scrollbar from '../../components/Scrollbar';
import { useCookies } from 'react-cookie';
import conversationClient from '../../clients/ConversationClient';
//
import logo from '../../assets/logo.png';
// ----------------------------------------------------------------------
import Context from '../../context';
import { useContext } from 'react';
import Iconify from 'src/components/Iconify';
import TaskClient from '../../clients/TaskClient';
import AgentClient from '../../clients/AgentClient';
import BotClient from '../../clients/BotClient';
import AccountPopover from './AccountPopover';
import documentClient from 'src/clients/DocumentClient';
import { CORPORA_URI } from 'src/config';
import { SSE } from 'sse';
const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 1,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(102, 115, 128, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      maxWidth: 180,
      padding: '15px 15px',
    },
  })
);
// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, sendQuestion, isGPT, setIsGPT }) {
  let {
    user,
    customcollections,
    activeCollection,
    setActiveCollection,
    activeDocument,
    setActiveDocument,
    authenticationToken,
    activeTask,
    setActiveTask,
    activeAgent,
    setActiveAgent,
    activeModel,
    setActiveModel,
    temperature,
    setTemperature,
    msgloading,
    setMsgloading,
    msg,
    setMsg,
    useraccountinfo,
  } = useContext(Context);

  const handleCollectionChange = (event) => {
    setActiveCollection(event.target.value);
    if (event.target.value !== null) setErr(null);
  };
  const [cookies, setCookie] = useCookies(['session_auth_data']);

  const { pathname } = useLocation();
  console.log(pathname);
  const pathString = pathname;
  const pathArray = pathString.split('/'); // Split the path string by '/'
  const currentnav = pathArray[pathArray.length - 1];

  const isDesktop = useResponsive('up', 'lg');
  const [isEdit, setIsEdit] = useState(false);
  const [isAgnentEdit, setisAgnentEdit] = useState(false);
  const [isSavingTask, setisSavingTask] = useState(false);
  const [isSavingAgent, setisSavingAgent] = useState(false);
  const [generatedquestions, setgeneratedquestions] = useState([]);

  const [tasks, setTasks] = useState([]);

  const [newTask, setNewTask] = useState(null);
  const [agents, setAgents] = useState([]);
  const [err, setErr] = useState(null);
  const [newAgent, setNewAgent] = useState(null);
  const [currentAnswerIndex, setCurrentAnswerIndex] = useState(null);
  const [docs, setDocs] = useState([]);
  const [activeDoc, setActiveDoc] = useState(null);
  const [advance, setAdvance] = useState(false);

  const [models, setModels] = useState([
    { id: 'gpt-3.5-turbo', name: 'gpt-3.5-turbo' },
    { id: 'gpt-4', name: 'gpt-4' },
    { id: 'gpt-4-0314', name: 'gpt-4-0314' },
    // { id: 'gpt-4-32k', name: 'gpt-4-32k' },
    // { id: 'gpt-4-32k-0314', name: 'gpt-4-32k-0314' },
  ]);
  console.log(cookies.useraccountinfo);
  const [open, setOpen] = useState(false);
  const [openAgent, setOpenAgent] = useState(false);
  const [step, setStep] = useState(false);
  const [sourceOnly, setsourceOnly] = useState(false);
  const [logmsg, setlogmsg] = useState('');
  const [severity, setseverity] = useState('info');
  const [publicbots, setpublicbots] = useState([]);
  useEffect(() => {
    if (customcollections.length > 0) setActiveCollection(customcollections[0].collectionid);
  }, [customcollections]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    async function fetchData() {
      let response = await BotClient.getPublicBots();
      console.log(response.bots);
      setpublicbots(response.bots);
    }
    fetchData();
  }, []);
  const handleClose = () => {
    setOpen(false);
    setNewTask(null);
    setNewAgent(null);
    setisAgnentEdit(false);
    setIsEdit(false);
  };

  const handleAddTask = async () => {
    console.log(activeCollection);
    if (activeCollection === null) {
      setErr('Please Select  a Collection');
      handleClose();
      return;
    }
    setisSavingTask(true);
    if (isEdit) {
      console.log(newTask);
      const response = await TaskClient.editTask(
        activeTask,
        newTask.taskName,
        newTask.taskquery,
        activeCollection,
        authenticationToken
      );
      const _tasks = tasks.map((task) => {
        if (task.taskid == activeTask) {
          return response;
        } else {
          return task;
        }
      });
      setNewTask({});
      setTasks(_tasks);
    } else {
      const response = await TaskClient.addTask(
        newTask.taskName,
        newTask.taskquery,
        activeCollection,
        authenticationToken
      );
      console.log(response);
      setTasks([{ taskid: response.taskid, taskName: response.taskName, taskquery: response.taskquery }, ...tasks]);
      setActiveTask(response.taskid);
      setNewTask({});
    }
    setisSavingTask(false);
    setIsEdit(false);
    handleClose();
  };

  const handleDeleteTask = async () => {
    await TaskClient.deleteTask(activeTask, activeCollection, authenticationToken);
    setTasks(tasks.filter((task) => task.taskid !== activeTask));
  };

  const handleEditTask = async () => {
    setIsEdit(true);

    setNewTask(tasks.find((x) => x.taskid === activeTask));
    handleClickOpen();
  };

  const handleClickOpenAgent = () => {
    setOpenAgent(true);
  };

  const handleAgentClose = () => {
    setOpenAgent(false);
  };

  const handleAddAgent = async () => {
    console.log(activeCollection);
    console.log();
    console.log(isAgnentEdit);
    setisSavingAgent(true);
    if (isAgnentEdit) {
      console.log(newAgent);
      const response = await AgentClient.editAgent(
        activeAgent,
        newAgent.agentName,
        newAgent.agentInstruct,
        authenticationToken
      );
      const _agents = agents.map((agent) => {
        if (agent.agentid == activeAgent) {
          return response;
        } else {
          return agent;
        }
      });

      setAgents(_agents);

      setOpenAgent(false);
      setNewAgent({});
      return;
    } else {
      console.log('Agent', newAgent);
      const response = await AgentClient.addAgent(newAgent.agentName, newAgent.agentInstruct, authenticationToken);
      console.log(response);
      setAgents([
        ...agents,
        { agentid: response.agentid, agentName: response.agentName, agentInstruct: response.agentInstruct },
      ]);
      setActiveAgent(response.agentid);
    }
    setNewAgent({});
    setisAgnentEdit(false);
    setisSavingAgent(false);

    handleAgentClose();
  };

  const handleDeleteAgent = async () => {
    await AgentClient.deleteAgent(activeAgent, authenticationToken);
    setAgents(agents.filter((agent) => agent.agentid !== activeAgent));
  };

  const handleEditAgent = async () => {
    setisAgnentEdit(true);

    setNewAgent(agents.find((x) => x.agentid === activeAgent));
    handleClickOpenAgent();
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await AgentClient.getAgents(authenticationToken);
      setAgents(response);
    };
    if (authenticationToken) {
      fetchData();
    }
  }, [authenticationToken]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await TaskClient.getTasks(activeCollection, authenticationToken);
      setTasks(response);
      const _docs = await documentClient.getDocumentsByCollectionId(activeCollection, authenticationToken);
      setDocs(_docs);
    };
    if (activeCollection !== null) {
      fetchData();
    }
  }, [activeCollection]);

  const handlePannelRun = async () => {
    if (step) {
      let task = tasks.filter((task) => task.taskid === activeTask)[0];
      let questions = task.taskquery.trim().split('\n');
      questions = questions.filter((element) => element !== '');
      console.log(msgloading);
      for (let question in questions) {
        console.log('quest', questions[question]);
        await sendQuestion(questions[question], 3, false, false, setMsg);
      }
    } else {
      let task = tasks.filter((task) => task.taskid === activeTask)[0];
      console.log(task);
      await sendQuestion(task.taskquery, 3, false, false, setMsg);
    }
  };
  function lastword(words) {
    var n = words.split(' ');
    return n[n.length - 1];
  }
  const summarize = (setMsgCallback) => {
    let id = undefined;
    return new Promise(async (resolve) => {
      let questionToUse = 'Summarize this document.';
      console.info(questionToUse);
      setMsgloading(true);

      if (msgloading) {
        return;
      }
      let currentAnswerIndex = -1;

      setMsgCallback((prevState) => {
        currentAnswerIndex = prevState[id].length;
        return {
          ...prevState,
          [id]: [
            ...prevState[id],
            {
              message: questionToUse,
              isBot: false,
            },
          ],
        };
      });

      // if (!isSharing) {
      //   setQuestion('', true);
      // }

      let authtoken = authenticationToken ? authenticationToken : cookies.session_auth_data.idToken;

      try {
        const fdata = {
          question: questionToUse,
          document_id: activeDocument,
          collectionid: activeCollection,
          temperature: temperature,
          modeltouse: activeModel,
          sharing: false,
        };

        if (questionToUse !== '') {
          let url = CORPORA_URI + '/get-summary-stream';

          let source = new SSE(url, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer: ' + authenticationToken,
            },
            method: 'POST',
            payload: JSON.stringify(fdata),
          });
          setMsgCallback((prevState) => {
            return {
              ...prevState,
              [id]: [
                ...prevState[id],
                {
                  message: {
                    answer: '',
                    documents: [],
                  },
                  paymentRequired: false,
                  isBot: true,
                },
              ],
            };
          });
          source.addEventListener('message', (e) => {
            console.log('streaming started');

            if (e.data != '[DONE]') {
              // console.log(e);
              let payload = JSON.parse(e.data);
              let text = payload.data.answer;
              // console.log(text);
              if (text != '\n') {
                if (lastword(payload.data.answer) == 'DONESUMALPHA') {
                  if (payload.chunkno == payload.chunklen - 1) {
                    setMsgloading(false);
                  } else {
                    setMsgloading(true);
                  }
                } else {
                  setMsgloading(true);

                  setMsgCallback((prevState) => {
                    const newState = { ...prevState };
                    console.log(currentAnswerIndex);
                    console.log(newState);
                    newState[id][currentAnswerIndex + 1].message.answer = payload.data.answer;
                    newState[id][currentAnswerIndex + 1].message.documents = payload.data.documents;
                    newState[id][currentAnswerIndex + 1].paymentRequired = payload.data.paymentRequired;

                    return newState;
                  });
                }
              }
            } else {
              setMsgloading(false);
              console.log('ONRESOLVE', msg);
              console.log('ONRESOLVE', msg);
              source.close();
              resolve();
            }
          });

          source.addEventListener('readystatechange', (e) => {
            if (e.readyState >= 2) {
              resolve();
              console.log('ONRESOLVE', msg);
              setMsgloading(false);
            }
          });

          source.stream();
        } else {
          alert('Please insert a prompt!');
        }
      } catch (e) {
        if (e.type === 'payment') {
          if (window.confirm('You have run out of free credits, please create a subscription to continue')) {
            window.location.href = '/profile';
          }
          resolve();
          setMsgloading(false);
          return;
        }
        console.log(e);
      }

      setMsgloading(false);

      // if (window.LO.events && window.LO.events.track) {
      //   window.LO.events.track("search");
      // }

      if (window.mixpanel && window.mixpanel.track) {
        window.mixpanel.track('Search', {
          source: 'Web App',
          question: q.substring(0, 200),
        });
      }
    });
  };
  const generateQues = (setMsgCallback) => {
    let id = undefined;
    return new Promise(async (resolve) => {
      let questionToUse = 'Generate 5 question from given context.';
      console.info(questionToUse);
      setMsgloading(true);

      if (msgloading) {
        return;
      }
      let currentAnswerIndex = -1;
      // if (!isSharing) {
      //   setQuestion('', true);
      // }

      let authtoken = authenticationToken ? authenticationToken : cookies.session_auth_data.idToken;
      console.log(activeDocument);
      try {
        const fdata = {
          question: questionToUse,
          documentid: activeDocument,
          collectionid: activeCollection,
          temperature: temperature,
          modeltouse: activeModel,
          sharing: false,
          index: 2,
          generatequestion: true,
        };
        setMsgCallback((prevState) => {
          currentAnswerIndex = prevState[id].length;
          return {
            ...prevState,
            [id]: [
              ...prevState[id],
              {
                message: {
                  answer: '',
                  documents: [],
                  generatedquestion: true,
                },
                paymentRequired: false,
                isBot: true,
              },
            ],
          };
        });
        if (questionToUse !== '') {
          let response = await conversationClient.generateGPT3(authenticationToken, fdata);

          setMsgCallback((prevState) => {
            const newState = { ...prevState };
            console.log(currentAnswerIndex);
            console.log(newState);
            newState[id][currentAnswerIndex].message.answer = response.data.answer;
            newState[id][currentAnswerIndex].message.documents = response.data.documents;
            newState[id][currentAnswerIndex].paymentRequired = response.data.paymentRequired;

            return newState;
          });

          console.log(response);
        } else {
          alert('Please insert a prompt!');
        }
      } catch (e) {
        if (e.type === 'payment') {
          if (window.confirm('You have run out of free credits, please create a subscription to continue')) {
            window.location.href = '/profile';
          }
          resolve();
          setMsgloading(false);
          return;
        }
        console.log(e);
      }

      setMsgloading(false);

      // if (window.LO.events && window.LO.events.track) {
      //   window.LO.events.track("search");
      // }

      if (window.mixpanel && window.mixpanel.track) {
        window.mixpanel.track('Search', {
          source: 'Web App',
          question: q.substring(0, 200),
        });
      }
    });
  };
  const handleSummarize = async () => {
    await summarize(setMsg);
  };
  const handleGenerate = async () => {
    await generateQues(setMsg);
  };
  const handlesourceOnly = async () => {
    if (!sourceOnly) {
      setActiveDocument(activeDoc);
    } else {
      setActiveDocument(null);
    }
    setsourceOnly(!sourceOnly);
  };
  const handleactivedoc = async (e) => {
    console.log(sourceOnly);
    // setMsg((prevState) => {
    //   return {
    //     ...prevState,
    //     [undefined]: [
    //       ...prevState[undefined],
    //       {
    //         message: 'click here to generate questions from selected document',
    //         isBot: false,
    //         generatedquestion: true,
    //         generatetopic: true,
    //       },
    //     ],
    //   };
    // });
    setActiveDocument(e.target.value);
    setActiveDoc(e.target.value);
  };

  console.log(customcollections);
  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center" sx={{ padding: '1rem' }}>
          <Grid item>
            <AccountPopover photo={user ? user.photoURL : null} />
          </Grid>
          <Grid item sx={{ p: 1 }}>
            <Typography variant="subtitle2">
              Remaining credits: {useraccountinfo.searches_limit - useraccountinfo.searches_count}
            </Typography>
            <Typography variant="subtitle2">
              Documents: {useraccountinfo.documents_count} / {useraccountinfo.documents_limit}
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              textAlign: 'left',
            }}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary', pr: 1 }}>
              {useraccountinfo.subscription_status ? `${useraccountinfo.subscription_status} Plan` : 'Free Plan'}
            </Typography>
            <Button variant="outlined" size="small" component={Link} to="/dashboard/profile">
              Manage
            </Button>
          </Grid>
        </Grid>

        <Stack direction="column" spacing={2} sx={{ padding: '1rem 1rem 1rem 1rem' }} justify>
          <LightTooltip
            title="Use Collections to organize your data into folders. Selecting a Collection ensures queries only consider data within it, offering a focused search experience and better organization."
            placement="left"
          >
            <FormControl>
              <InputLabel size="small" id="demo-simple-select-helper-label">
                Collections
              </InputLabel>
              <Select
                value={activeCollection || 'Select'}
                label="Collections"
                onChange={handleCollectionChange}
                required
                size="small"
              >
                <MenuItem value={'Select'} disabled>
                  {'Select'}
                </MenuItem>
                {customcollections.map((collection) => {
                  return <MenuItem value={collection.collectionid}>{collection.CollectionName}</MenuItem>;
                })}
              </Select>
              <FormHelperText>Select which collection to search</FormHelperText>
            </FormControl>
          </LightTooltip>
          {currentnav != 'createbots' && (
            <Button onClick={() => setAdvance(!advance)}>
              {advance ? (
                <Iconify icon={'ic:outline-expand-more'} sx={{ width: 20, height: 20, mr: 1 }} />
              ) : (
                <Iconify icon={'ic:round-keyboard-arrow-right'} sx={{ width: 20, height: 20, mr: 1 }} />
              )}
              Advanced options
            </Button>
          )}
          <Collapse in={advance}>
            <Stack direction="column" spacing={2} justify>
              <LightTooltip
                title="Use this selector to choose a specific Document from the Collection to chat with. If you'd like to search and chat within the entire Collection, simply select 'None' at the top of the list.
            "
                placement="left"
              >
                <FormControl sx={{ marginTop: 1 }}>
                  <InputLabel size="small" id="dedemo">
                    Documents
                  </InputLabel>
                  <Select
                    value={activeDocument ? activeDocument : ''}
                    label="Documents"
                    onChange={handleactivedoc}
                    required
                    size="small"
                  >
                    <MenuItem value={null}>None</MenuItem>
                    {docs.map((doc) => {
                      return <MenuItem value={doc.documentId}>{doc.fileName}</MenuItem>;
                    })}
                  </Select>
                  <FormHelperText>Select Document</FormHelperText>
                </FormControl>
              </LightTooltip>
              {/* <LightTooltip
                title="Click to enable/disable, searching and chatting within a specific document only."
                placement="left"
              >
                <FormControlLabel
                  control={<Switch checked={isGPT} onChange={() => setIsGPT(!isGPT)} />}
                  label="Search by Document"
                />
              </LightTooltip> */}

              {activeCollection && (
                <LightTooltip title="Click to generate 5 insights from the selected document." placement="left">
                  <Button variant="contained" size="small" onClick={handleGenerate}>
                    Get insights
                  </Button>
                </LightTooltip>
              )}

              <FormControl sx={{ padding: 0 }}>
                <InputLabel size="small" id="demo-simple-select-helper-label">
                  Models
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={activeModel ? activeModel : ''}
                  label={activeModel ? 'Models' : 'Model'}
                  onChange={(e) => {
                    setActiveModel(e.target.value);
                    setlogmsg('');
                    setseverity('info');
                    if (e.target.value === 'gpt-4-0314' || e.target.value === 'gpt-4') {
                      setlogmsg(`${e.target.value} costs 20 credits per search.`);
                      setseverity('warning');
                    }
                  }}
                  sx={{ padding: 0 }}
                  size="small"
                >
                  {models.map((model) => (
                    <MenuItem aria-label="None" value={model.name}>
                      {model.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Select a model to use</FormHelperText>
              </FormControl>

              {logmsg && (
                <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <Alert severity={severity}>{logmsg}</Alert>
                </Grid>
              )}
              <LightTooltip title="low temperature = less random, high temp. = more random" placement="left">
                <Box>
                  <Typography sx={{ paddingLeft: 1 }} size="small" id="demo-simple-select-helper-label">
                    Temperature
                  </Typography>
                  <FormControl sx={{ width: '100%', padding: 1 }}>
                    <Slider
                      aria-label="Small steps"
                      defaultValue={0.2}
                      getAriaValueText={0}
                      step={0.1}
                      marks
                      min={0}
                      max={1}
                      value={temperature}
                      valueLabelDisplay="auto"
                      onChange={(e) => setTemperature(e.target.value)}
                    />
                  </FormControl>
                </Box>
              </LightTooltip>
              <LightTooltip
                title="Enable Direct GPT Chat to engage in conversations solely with GPT-4 or GPT-3.5, without any connections to your personal data,  as if you were on the OpenAI platform. You can attach any conversations from your Chat history to give Context. We highly recommend this mode when you want to generate new content."
                placement="left"
              >
                <FormControlLabel
                  control={<Switch checked={isGPT} onChange={() => setIsGPT(!isGPT)} />}
                  label="Direct GPT Chat"
                />
              </LightTooltip>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '100%' }}
                spacing={2}
              >
                <Grid xs={8}>
                  <LightTooltip
                    title="Use this selector to name and save a Chat Style, which will modify the chat output based on the custom description. You can use this feature to set the tone of a message, change output formatting, or give the chat a 'persona'. The selected Style will be applied in all modes: single chat query, Direct Chat GPT, and when running Tasks."
                    placement="left"
                  >
                    <FormControl sx={{ width: '100%', padding: 1 }}>
                      <InputLabel sx={{ pl: 1 }}>Chat Style</InputLabel>

                      <Select
                        value={activeAgent ? activeAgent : ''}
                        onChange={(e) => {
                          setActiveAgent(e.target.value);
                        }}
                        sx={{ width: '100%' }}
                        size="small"
                      >
                        <MenuItem aria-label="None" value="">
                          <Button variant="outlined" size="small" fullWidth={true} onClick={handleClickOpenAgent}>
                            + New Style
                          </Button>
                        </MenuItem>
                        <MenuItem value={null}>None</MenuItem>
                        {agents?.map((agent) => {
                          return (
                            <MenuItem value={agent.agentid}>
                              <Typography>{agent.agentName}</Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </LightTooltip>
                </Grid>

                <Tooltip title="edit agent">
                  <Grid xs={2}>
                    <Button onClick={handleEditAgent}>
                      <Iconify icon={'ic:baseline-edit'} sx={{ width: 20, height: 20, mr: 1 }} />
                    </Button>
                  </Grid>
                </Tooltip>
                <Tooltip title="edit agent">
                  <Grid xs={2}>
                    <Button onClick={handleDeleteAgent}>
                      <Iconify icon={'mdi:delete-forever'} sx={{ width: 20, height: 20, mr: 1 }} />
                    </Button>
                  </Grid>
                </Tooltip>
              </Grid>
              <Divider />
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '100%' }}
                spacing={2}
              >
                <Grid xs={8}>
                  <LightTooltip
                    title="Use this selector to name and save a Prompt as a Task. You can also run the Task in step-by-step mode, which treats each new row as a separate query. To activate the Task, click the 'Run' button below."
                    placement="left"
                  >
                    <FormControl sx={{ width: '100%', padding: 1 }}>
                      <InputLabel sx={{ pl: 1 }}>Tasks</InputLabel>
                      <Select
                        size="small"
                        value={activeTask ? activeTask : ''}
                        onChange={(e) => {
                          setActiveTask(e.target.value);
                        }}
                        sx={{ width: '100%', padding: 0 }}
                      >
                        <MenuItem aria-label="None" value="">
                          <Button variant="outlined" size="small" fullWidth={true} onClick={handleClickOpen}>
                            + New Task
                          </Button>
                        </MenuItem>
                        <MenuItem value={null}>None</MenuItem>
                        {tasks?.map((task) => {
                          return (
                            <MenuItem value={task.taskid}>
                              <Typography>{task.taskName}</Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </LightTooltip>
                </Grid>

                <Tooltip title="edit task">
                  <Grid xs={2}>
                    <Button onClick={handleEditTask}>
                      <Iconify icon={'ic:baseline-edit'} sx={{ width: 20, height: 20, mr: 1 }} />
                    </Button>
                  </Grid>
                </Tooltip>
                <Tooltip title="delete task">
                  <Grid xs={2}>
                    <Button onClick={handleDeleteTask}>
                      <Iconify icon={'mdi:delete-forever'} sx={{ width: 20, height: 20, mr: 1 }} />
                    </Button>
                  </Grid>
                </Tooltip>

                <Typography variant="subtitle2" sx={{ color: 'red' }}>
                  {err ? err : ''}
                </Typography>
              </Grid>
              <LightTooltip
                title="Enable the Step-by-Step mode to run each row from the Task as a separate prompt. This is useful if you want to query multiple documents from your Collection at once"
                placement="left"
              >
                <FormControlLabel
                  control={<Switch checked={step} onChange={() => setStep(!step)} />}
                  label="Step By Step"
                />
              </LightTooltip>

              <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={'md'}
                fullWidth={true}
                PaperProps={{
                  style: { borderRadius: 5 },
                }}
              >
                <DialogTitle>{isEdit ? 'Edit Task' : 'New Task'}</DialogTitle>
                <DialogContent sx={{ mt: '1rem' }}>
                  <FormControl sx={{ width: '100%' }}>
                    <Stack spacing={2}>
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Title"
                        name="taskName"
                        variant="outlined"
                        value={newTask?.taskName}
                        onChange={(e) => setNewTask({ ...newTask, [e.target.name]: e.target.value })}
                        sx={{ width: '100%', mt: 1 }}
                      />
                      <TextField
                        name="taskquery"
                        value={newTask?.taskquery}
                        onChange={(e) => setNewTask({ ...newTask, [e.target.name]: e.target.value })}
                        id="outlined-multiline-static"
                        label="Queries"
                        minRows={5}
                        multiline
                      />
                    </Stack>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>

                  <Button onClick={handleAddTask}>{isEdit ? 'Save' : 'Save'}</Button>
                  {isSavingTask && <CircularProgress size="1rem" />}
                </DialogActions>
              </Dialog>
              <Dialog
                open={openAgent}
                onClose={handleAgentClose}
                maxWidth={'md'}
                fullWidth={true}
                PaperProps={{
                  style: { borderRadius: 5 },
                }}
              >
                <DialogTitle>{isAgnentEdit ? 'Edit Style' : 'New Style'}</DialogTitle>
                <DialogContent sx={{ mt: '1rem' }}>
                  <FormControl sx={{ width: '100%' }}>
                    <Stack spacing={2}>
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Title"
                        name="agentName"
                        variant="outlined"
                        value={newAgent?.agentName}
                        onChange={(e) => setNewAgent({ ...newAgent, [e.target.name]: e.target.value })}
                        sx={{ width: '100%', mt: 1 }}
                      />
                      <TextField
                        name="agentInstruct"
                        value={newAgent?.agentInstruct}
                        onChange={(e) => setNewAgent({ ...newAgent, [e.target.name]: e.target.value })}
                        id="outlined-multiline-static"
                        label="Queries"
                        minRows={5}
                        multiline
                      />
                    </Stack>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAgentClose}>Cancel</Button>
                  {isAgnentEdit ? (
                    <Button onClick={handleAddAgent}>Save</Button>
                  ) : (
                    <Button onClick={handleAddAgent}>Save</Button>
                  )}
                  {isSavingAgent && <CircularProgress size="1rem" />}
                </DialogActions>
              </Dialog>

              <LightTooltip title="Run your tasks" placement="left">
                <Button variant="contained" fullWidth={true} onClick={handlePannelRun}>
                  Run Task
                </Button>
              </LightTooltip>
            </Stack>
          </Collapse>
          {!advance && (
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                border: '0.5px solid gray',
                borderRadius: '15px',
              }}
            >
              <Typography variant="subtitle2">Suggested Bots</Typography>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  width: '95%',
                  flexWrap: 'wrap',
                  mt: 2,
                  mb: 2,
                }}
              >
                {publicbots.length > 0 ? (
                  publicbots.slice(0, 5).map((agent, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          maxWidth: '80px',
                          marginTop: 10,
                        }}
                        key={index}
                      >
                        <Link
                          to={`${REACT_ROOT_URL}agents/${agent.botid}`}
                          target="_blank"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            textDecoration: 'none',
                          }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            src={agent.avatar}
                            sx={{ width: 36, height: 36, cursor: 'pointer' }}
                            onClick={(e) => handleAgentInfo(e, agent)}
                          >
                            {agent.name}
                          </Avatar>
                          <Typography variant="body" sx={{ ml: 2, mr: 2, mt: 2, fontSize: 13 }}>
                            {agent.name.slice(0, 5)}...
                          </Typography>
                        </Link>
                      </div>
                    );
                  })
                ) : (
                  <Typography variant="subtitle2">Public Bot suggestion</Typography>
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    maxWidth: '80px',
                    marginTop: 10,
                  }}
                >
                  <Link
                    to={`${REACT_ROOT_URL}bots/discover`}
                    target="_blank"
                    text
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Iconify icon={'mingcute:more-3-fill'} sx={{ width: 36, height: 36 }} color="gray" />
                  </Link>
                  <Typography variant="body" sx={{ ml: 2, mr: 2, mt: 2, fontSize: 13 }}>
                    More
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}
        </Stack>
      </Scrollbar>
    </>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          anchor="right"
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          anchor="right"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
