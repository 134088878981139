import { useEffect } from 'react';
import ReactGa from 'react-ga';
import Router from './routes';
import { auth, onAuthStateChanged } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

// theme
import ThemeProvider from './theme';
// components

// ----------------------------------------------------------------------
export default function App() {
  const [user, isLoading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('app');
    ReactGa.initialize('UA-214532842-2');
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  );
}
