import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import * as firebaseAuth from 'firebase/auth';
import { useCookies } from 'react-cookie';

import { getFirestore, query, getDocs, collection, where, addDoc } from 'firebase/firestore';

import { initializeApp } from 'firebase/app';
import userClient from './clients/UserClient';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCm94C0060udvBZUPdyNUYmSnLeAaQBD-o',
  //authDomain: "pdf-analysis-saas.firebaseapp.com",
  authDomain: 'auth.scrol.ai',
  projectId: 'pdf-analysis-saas',
  storageBucket: 'pdf-analysis-saas.appspot.com',
  messagingSenderId: '557180662061',
  appId: '1:557180662061:web:d006db8ad4e60bd862e3a4',
  measurementId: 'G-Z90P11CGWJ',
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = firebaseAuth.getAuth(firebaseApp);
// const auth = getAuth(app);
const db = getFirestore(firebaseApp);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    const idToken = await user.getIdToken();
    document.cookie = 'session_auth_token=' + idToken;

    await userClient.login(idToken);
    return {
      idToken: idToken,
      user: user,
    };
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    let res = await signInWithEmailAndPassword(auth, email, password);
    const userinfo = res.user;
    const idToken = await userinfo.getIdToken();
    await userClient.emailpasslogin(idToken);
    return {
      idToken: idToken,
      user: userinfo,
    };
  } catch (err) {
    console.error(err);
    return {
      err: err.message,
    };
  }
};
const deleteUser = async (user) => {
  try {
    await auth.deleteUser(user.uid);
    console.log('User account deleted successfully');
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    // console.log(auth.currentUser.emailVerified);
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const userinfo = res.user;
    const idToken = await userinfo.getIdToken();
    await userClient.emailpasslogin(idToken, name);
    return {
      idToken: idToken,
      user: userinfo,
    };
  } catch (err) {
    console.error(err);
    if (err.code === 'auth/email-already-in-use') {
      const result = await logInWithEmailAndPassword(email, password);
      if (result.user.emailVerified) {
        return result;
      } else {
        await userClient.emailpasslogin(result.idToken, name);
        return result;
      }
      // await deleteUser(existingUser);
    }
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return 'password reset link sent to your email';
  } catch (err) {
    console.error(err);
    // alert(err.message);
    return err.message;
  }
};

const logout = () => {
  signOut(auth);
  document.cookie = '';
  window.location.href = '/';
};

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  firebaseConfig,
  onAuthStateChanged,
};
