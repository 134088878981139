// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: '+ New Chat',
    path: '/dashboard/ask',
    icon: getIcon('ic:round-message'),
  },

  { title: 'Create Bots', path: '/dashboard/createbots', icon: getIcon('fluent:bot-add-20-filled') },
  { title: 'Collections', path: '/dashboard/collections', icon: getIcon('bi:collection-fill') },
  //{ title: 'Better Google', path: '/dashboard/bettergoogle', icon: getIcon('material-symbols:search') },
];

export default navConfig;
