import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Button, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { auth, registerWithEmailAndPassword, signInWithGoogle } from '../../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import userClient from '../../../clients/UserClient';
// ----------------------------------------------------------------------

export default function RegisterForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [otp, setotp] = useState();
  const [verifyotp, setverifyotp] = useState(false);
  const [isLogging, setisLogging] = useState(false);
  const [userdata, setuserdata] = useState({});
  const [logmsg, setlogmsg] = useState('');

  const [user, loading, error] = useAuthState(auth);
  const [cookies, setCookie] = useCookies(['session_auth_token']);
  const navigate = useNavigate();
  const register = async () => {
    if (!name) {
      setlogmsg('Please enter your email');
      return;
    }
    if (!email) {
      setlogmsg('Please enter your email');
      return;
    }
    if (!password) {
      setlogmsg('Please enter your password');
      return;
    }
    setisLogging(true);
    const userconfdata = await registerWithEmailAndPassword(name, email, password);
    console.log(userconfdata);
    setuserdata(userconfdata);
    if (userconfdata.user.emailVerified) {
      setlogmsg('User already exists, Please login with your email and password.');
    } else {
      setverifyotp(true);
    }
    setlogmsg('Please check your email for the verification code.');
    setisLogging(false);
    // setCookie('session_auth_data', userconfdata, { path: '/' });
  };
  const verifyemail = async () => {
    if (!otp) {
      setlogmsg('Please enter your verification code.');
      return;
    }
    setisLogging(true);

    const user = await userClient.verifyemailpass(otp);
    console.log(user);
    if (user.status == 200) {
      setlogmsg('');

      setCookie('session_auth_data', userdata, { path: '/' });
      navigate('/dashboard/ask');
    } else {
      setlogmsg('Verification code did not match. Please try again.');
      setCookie('session_auth_data', '', { path: '/' });
    }

    console.log(cookies.session_auth_data);

    setisLogging(false);
  };

  return (
    <Stack spacing={3}>
      {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name" />
          <RHFTextField name="lastName" label="Last name" />
        </Stack> */}
      <TextField
        name="name"
        label="Full Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Full Name"
      />

      <TextField name="email" label="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />

      <TextField
        name="password"
        label="Password"
        // type={showPassword ? 'text' : 'password'}
        // InputProps={{
        //   endAdornment: (
        //     <InputAdornment position="end">
        //       <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
        //         <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
        //       </IconButton>
        //     </InputAdornment>
        //   ),
        // }}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {verifyotp ? (
        <>
          <TextField
            name="OTP"
            label="Enter Verification Code"
            onChange={(e) => setotp(e.target.value)}
            placeholder="Enter Verification Code"
          />
          <Button fullWidth size="large" type="submit" variant="contained" onClick={verifyemail}>
            Verify Email
          </Button>
        </>
      ) : (
        <Button fullWidth size="large" type="submit" variant="contained" onClick={register}>
          Register
        </Button>
      )}
      <div style={{ color: '#FFA500' }}>{logmsg}</div>

      {isLogging && (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingTop: 5 }}>
          <CircularProgress size="2rem" />
        </div>
      )}
    </Stack>
  );
}
