import { CORPORA_URI } from '../config';

class UserClient {
  async login(idToken, name) {
    const data = {
      idToken: idToken,
      name: name,
    };

    const responseJson = await fetch(CORPORA_URI + '/login', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return await responseJson.json();
  }
  async emailpasslogin(idToken, name) {
    const data = {
      idToken: idToken,
      name: name,
    };

    const responseJson = await fetch(CORPORA_URI + '/emailpasslogin', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return await responseJson.json();
  }
  async verifyemailpass(otp) {
    try {
      const data = {
        otp: otp,
      };

      const responseJson = await fetch(CORPORA_URI + '/verifyemailpass', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      return await responseJson.json();
    } catch (err) {
      alert(err.message);
      console.log(err.message);
    }
  }

  async getUser(authorizationToken) {
    const responseJson = await fetch(CORPORA_URI + '/user', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authorizationToken,
      },
    });

    return await responseJson.json();
  }
  async getCollections(authorizationToken) {
    const responseJson = await fetch(CORPORA_URI + '/get-collections', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authorizationToken,
      },
    });

    return await responseJson.json();
  }
}

const userClient = new UserClient();
export default userClient;
