import { CORPORA_URI } from 'src/config';

class AgentClient {
  async getAgents(authenticationToken) {
    // formData.append('taskname', taskName);
    // formData.append('taskquery', taskQuery);
    // formData.append('collectionid', collectionId);

    let responseJson = await fetch(CORPORA_URI + '/get-agents', {
      method: 'GET',

      headers: {
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();

    return response;
  }

  async addAgent(agentName, agentInstruct, authenticationToken) {
    let data = {
      agentname: agentName,
      agentInstruct: agentInstruct,
    };
    // formData.append('taskname', taskName);
    // formData.append('taskquery', taskQuery);
    // formData.append('collectionid', collectionId);
    console.log(data);
    let responseJson = await fetch(CORPORA_URI + '/add-agent', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });
    let response = await responseJson.json();

    return response;
  }
  async editAgent(agentId, agentName, agentInstruct, authenticationToken) {
    let data = {
      agentname: agentName,
      agentInstruct: agentInstruct,
      agentid: agentId,
    };

    let responseJson = await fetch(CORPORA_URI + '/edit-agent', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();
    console.dir(response);

    return response;
  }
  async deleteAgent(agentId, authenticationToken) {
    const data = {
      agentid: agentId,
    };

    let responseJson = await fetch(CORPORA_URI + '/delete-agent', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authenticationToken,
      },
    });

    let response = await responseJson.json();
    return response;
  }
}

const obj = new AgentClient();
export default obj;
