import { CORPORA_URI } from '../config';

class ApiKeyClient {
  async createApiKey(authorizationToken) {
    const responseJson = await fetch(CORPORA_URI + '/create-api-key', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authorizationToken,
      },
    });

    let json = responseJson.json();
    return json;
  }
}

const apiKeyClient = new ApiKeyClient();
export default apiKeyClient;
