export const UNIFY_URI =
  process.env.NODE_ENV === 'production' ? 'https://api-oneapi.onrender.com' : 'http://localhost:8002';
// 'http://localhost:8002'
// 'https://apponeapi.herokuapp.com';
// export const RZR_KEYID = 'rzp_test_2x7BgqQ0pkYHTZ';

export const CORPORA_URI =
  process.env.NODE_ENV === 'production' ? 'https://api-scrol.onrender.com' : 'http://localhost:8080';

export const REACT_ROOT_URL =
  process.env.NODE_ENV === 'production' ? 'https://app.scrol.ai/' : 'http://localhost:3000/';
export const REACT_APP_ADDON_PRICE_ID =
  process.env.NODE_ENV === 'production' ? 'price_1N6sGJBcinonnZFEcZJz63w7' : 'price_1N6tYtBcinonnZFExfffuPzP';
export const REACT_APP_BASE_PRICE_ID_MN =
  process.env.NODE_ENV === 'production' ? 'price_1N7FFXBcinonnZFEQVZGas98' : 'price_1N79cbBcinonnZFEgm2wlsud';
export const REACT_APP_BASE_PRICE_ID_YR =
  process.env.NODE_ENV === 'production' ? 'price_1N7FFXBcinonnZFEziv6SuWe' : 'price_1N7G94BcinonnZFECv7rGS0s';
export const REACT_APP_STANDARD_PRICE_ID_MN =
  process.env.NODE_ENV === 'production' ? 'price_1N7FVUBcinonnZFE44ZrIeke' : 'price_1N6tZIBcinonnZFEPFOc1Sz1';
export const REACT_APP_STANDARD_PRICE_ID_YR =
  process.env.NODE_ENV === 'production' ? 'price_1N7FVUBcinonnZFEzEioDnTc' : 'price_1N7GA5BcinonnZFEw7GbNw2L';
export const REACT_APP_PREMIUM_PRICE_ID_MN =
  process.env.NODE_ENV === 'production' ? 'price_1N6tFYBcinonnZFEJ3t8EPdV' : 'price_1N6tZmBcinonnZFEx6zKvCj2';
export const REACT_APP_PREMIUM_PRICE_ID_YR =
  process.env.NODE_ENV === 'production' ? 'price_1N6wcFBcinonnZFEtaJuXzEY' : 'price_1N7G9YBcinonnZFEUR3p2YKw';
