import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, CircularProgress, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { useCookies } from 'react-cookie';
import { auth, logInWithEmailAndPassword, sendPasswordReset, signInWithGoogle } from '../../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [user, loading, error] = useAuthState(auth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [logmsg, setlogmsg] = useState('');

  const [cookies, setCookie] = useCookies(['session_auth_token']);
  const [isLogging, setisLogging] = useState(false);

  const signinwithemailpass = async (email, password) => {
    if (!email) {
      setlogmsg('Please enter your email');
      return;
    }
    if (!password) {
      setlogmsg('Please enter your password');
      return;
    }
    setisLogging(true);

    let userconfdata = await logInWithEmailAndPassword(email, password);

    setCookie('session_auth_data', userconfdata, { path: '/' });

    console.log(cookies.session_auth_data);
    console.log(userconfdata);
    if (userconfdata.err) {
      setlogmsg(userconfdata.err);
    } else {
      navigate('/dashboard/ask');
    }
    setisLogging(false);
  };
  const resetpassword = async (email) => {
    if (!email) {
      setlogmsg('Please enter email to reset password');
      return;
    }
    let response = sendPasswordReset(email);
    setlogmsg('password reset link sent to your email');
  };
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
  }, [user, loading]);

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Enter Your Email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <TextField
          name="password"
          label="Enter Password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={() => signinwithemailpass(email, password)}
        >
          Login
        </Button>

        <Button fullWidth size="large" type="submit" variant="contained" onClick={() => resetpassword(email)}>
          Forgot Password
        </Button>

        <div style={{ color: '#FFA500' }}>{logmsg}</div>
        <div>
          Don't have an account? <Link href="/register">Register</Link> now.
        </div>

        {isLogging && (
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingTop: 5 }}>
            <CircularProgress size="2rem" />
          </div>
        )}
      </Stack>
    </>
  );
}
