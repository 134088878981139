import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { CORPORA_URI } from '../config';
import ChatDummy from './ChatDemo';
import { Helmet } from 'react-helmet';
import Context from '../context';
import { useContext } from 'react';
import conversationClient from '../clients/ConversationClient';
import { styled } from '@mui/material/styles';
import BotClient from '../clients/BotClient';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import Dropzone from 'react-dropzone';
import { SSE } from 'sse';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Card,
  Typography,
  Tooltip,
  Button,
  Popover,
  TextField,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  InputLabel,
  OutlinedInput,
  ListItemText,
  FormLabel,
  Avatar,
  Radio,
  RadioGroup,
  FormControlLabel,
  Alert,
  IconButton,
  CircularProgress,
  Input,
  Box,
  Chip,
} from '@mui/material';

// components
import PageComp from '../components/Page';
import Iconify from '../components/Iconify';
import AvatarWithOverlay from 'src/components/AvatarWithOverlay';
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
import { auth, db, logout } from '../firebase';

import { REACT_ROOT_URL } from 'src/config';
// sections
import { ApplicationPanel } from '../sections/@dashboard/app';
import useResponsive from 'src/hooks/useResponsive';
import { func } from 'prop-types';
import { forEach } from 'lodash';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// ----------------------------------------------------------------------
const APPBAR_DESKTOP = 92;
const DRAWER_WIDTH = 280;
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(102, 115, 128, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      maxWidth: 180,
      padding: '15px 15px',
    },
  })
);
export default function CreateBots() {
  let { authenticationToken, customcollections, llmKeys } = useContext(Context);
  const isMobile = useResponsive('down', 'sm');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isconfigure, setisconfigure] = React.useState(false);
  const [isstylize, setisstylize] = React.useState(false);
  const [isdeploy, setisdeploy] = React.useState(false);
  const [agentname, setagentname] = React.useState('Lexi');
  const [avatarurl, setavatarurl] = React.useState(null);
  const [variantName, setVariantName] = React.useState([
    {
      id: 1,
      name: 'document-search',
      description: 'this tool let AI search through your uploaded documents',
      source: 'scrol',
    },
  ]);
  const [visibility, setvisibilty] = React.useState('public');
  const [logmsg, setlogmsg] = useState('');
  const [severity, setseverity] = useState('info');
  const [loading, setloading] = useState(false);
  const [isagentopen, setisagentopen] = useState(false);
  const [currentagent, setcurrentagent] = useState(null);
  const [botdescription, setbotdescription] = useState(null);
  const [downloading, setdownloading] = useState(false);
  const [botinstruction, setbotinstruction] =
    useState(`Compose a well-structured response to the user's question, using context and conversation information. 
      Format your answer with distinct <h3>titles</h3> and <h3>subtitles</h3>, <b>emphasis</b>, <b>bold</b>, <i>italic<i>, <li>lists</li>, and tables *use html code*.
      Use agent style if given.
      output programming code in appropriate markdown format, so it's highlighted
      Reference context source number only in this format <sup><b>[Number]</b></sup> after every paragraph or sentence.
      If context lacks relevant information, disclose that and discuss related topics. 
      Response:`);
  const [deleteconfirm, setdeleteconfirm] = useState(false);
  const [deletebotid, setdeletebotid] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [collectionid, setcollectionid] = useState(null);
  const [activeCollection, setactivecollection] = useState(null);
  const [err, setErr] = useState(null);
  const spanRef = React.useRef();
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      let userbots = await BotClient.getUsersBots(authenticationToken);
      console.log('userbots');
      console.log(userbots);
      if (userbots.status == 401) {
        navigate('/login');
      }
      setdeployedagents(userbots);
    }
    fetchData();
  }, []);
  console.log(customcollections);
  const [selectedmodel, setselectedmodel] = React.useState();
  const configureRef = React.useRef();
  const stylizeRef = React.useRef();
  const deployRef = React.useRef();
  const [availablemodels, setavailablemodels] = React.useState([
    { id: 1, vendor: 'Open AI', model: 'gpt-3.5-turbo', description: 'AI model by Open AI', vendor: 'openai' },
    { id: 2, vendor: 'Open AI', model: 'gpt-4', description: 'AI model by Open AI', vendor: 'openai' },
    { id: 3, vendor: 'Cohere', model: 'command', description: 'AI model by Cohere', vendor: 'cohere' },
  ]);
  const variants = [
    {
      id: 1,
      name: 'document-search',
      description: 'this tool let AI search through your uploaded documents',
      source: 'scrol',
    },
    {
      id: 2,
      name: 'wikipedia',
      description: 'this tool let AI search through wikipedia database.',
      source: 'langchain',
    },
    {
      id: 3,
      name: 'stackoverflow',
      description:
        'this tool enables AI in getting coding related answer from stackoverflow and give response on its analysis.',
      source: 'scrol',
    },
    {
      id: 4,
      name: 'googlesearch',
      description: 'this tool enable AI to use scrol search for your own documents',
      source: 'langchain',
    },
    {
      id: 5,
      name: 'arxiv',
      description: 'this tool let AI search through arxiv database.',
      source: 'langchain',
    },
    {
      id: 6,
      name: 'math',
      description: 'this tool helps AI in mathematical tasks',
      source: 'langchain',
    },
  ];
  // this is data that is will fetched from database from first load.
  // also this is same form which is used to shw public agents.
  const [deployedagents, setdeployedagents] = React.useState([]);
  const [publicagents, setpublicagents] = React.useState([
    {
      visibility: 'public',
      name: 'SOS',
      authorname: 'navdeep dhakar',
      description:
        'Stack on Steroid helps you with coding task, it searches coding problem on stackoverflow and presents self summarized solution..',
      totalsearches: 0,
      avatar:
        'https://storage.googleapis.com/pdf-analysis-saas.appspot.com/documents/b98b201c-7af9-4c0c-b90a-d77c7536fdb7.jpg?GoogleAccessId=firebase-adminsdk-gxi41%40pdf-analysis-saas.iam.gserviceaccount.com&Expires=4108213800&Signature=PMNb0CgJU3XS5wUJUM8ToWHIKD78W5L1mQFciPqe3gXCKlRwcKV0r0Vz8IyTFcXz7cHTrx8mCJ1ds1kt3s1gnAqKWMvc7pZSzHXMz4t07Bk950EzYnKQqpM85FhHBlS2GNn%2BYVKQjgISDTeUleLki2tmvbFrDtK%2B%2Fx1ZgqVshR9eS5YbOMcrEKJtQIrsefMscfWrJPFhON4%2BqMnNPoe17OO31YGRaQ8O5kZ8gVAu96I7TYGL4z%2FkgwXBsuKHWi7bVctIBQxOkAbsLKPiYBveGRXhry%2B2vWaFyoeBaLBslXxkjYMg0LgPpbRbNdSDhPpaECZwoE11NeFaC%2FZ5W17%2BLw%3D%3D',
      aimodel: {
        vendor: 'openai',
        description: 'AI model by Open AI',
        model: 'gpt-3.5-turbo',
        id: 1,
      },
      tools: [
        {
          name: 'googlesearch',
          description: 'this tool enable AI to use scrol search for your own documents',
          id: 4,
          source: 'langchain',
        },
        {
          name: 'stackoverflow',
          description:
            'this tool enables AI in getting coding related answer from stackoverflow and give response on its analysis.',
          id: 3,
          source: 'scrol',
        },
      ],
      collectionid: null,
      scrolsearch: false,
      userid: 'rUgkyuiEUWYTz8ileHSArxtxekC3',
      botid: 'L0umQ0C7ZrSkWaynJZfg',
      bot_token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJib3RfaWQiOiJMMHVtUTBDN1pyU2tXYXluSlpmZyIsInVzZXJpZCI6InJVZ2t5dWlFVVdZVHo4aWxlSFNBcnh0eGVrQzMiLCJpYXQiOjE2OTA0NDg5MTd9.QgHrqskTOWjtybNpXl7Yk8vgiSmHp2hLeafVFLrZM74',
    },
    {
      visibility: 'public',
      name: 'Wiki Help',
      authorname: 'navdeep dhakar',
      description: 'Get answers from wikipedia quickly with the help of AI.',
      totalsearches: 0,
      avatar:
        'https://storage.googleapis.com/pdf-analysis-saas.appspot.com/documents/7b16d698-f975-4c8d-bd8f-d203deba701c.png?GoogleAccessId=firebase-adminsdk-gxi41%40pdf-analysis-saas.iam.gserviceaccount.com&Expires=4108213800&Signature=Awriz2CKBjvi6pY5Kly9KI6kVyZ9PGECVSfa0l2M6VC83exIE45zZKp0%2BNOJLPbKyoJ5FHnA6MjXIm25snG8bssI9Xayp%2FB6SmzKMVD%2BC25vW%2F%2BJmOXuHVi1e7XMRje7nqSx7IJ%2FKi2la%2FIPcQ1zDAB1YnmbJ9UnU1LYS%2BGxRHekHFWyVeRg9j8aJfati%2FDKUj3qZmTiJtjOXIiSR9lfpFu4%2FFhXIxh49cOgkMWsyMiWR9O0DefZo%2FhN5O%2BeIUuh5gtsKDnH43hck7uXkdlFPqVZPoCCsIp%2FooD%2FpMG1vU8omuOOpob4LACm2nAHSe1%2FpQ%2Fk4fFugJpbkm0wLl9EsQ%3D%3D',
      aimodel: {
        vendor: 'openai',
        description: 'AI model by Open AI',
        model: 'gpt-3.5-turbo',
        id: 1,
      },
      tools: [
        {
          name: 'wikipedia',
          description: 'this tool let AI search through wikipedia database.',
          id: 1,
          source: 'langchain',
        },
        {
          name: 'googlesearch',
          description: 'this tool enable AI to use scrol search for your own documents',
          id: 4,
          source: 'langchain',
        },
      ],
      collectionid: null,
      scrolsearch: false,
      userid: 'rUgkyuiEUWYTz8ileHSArxtxekC3',
      botid: 'XkEUhZvSEG9oPijryNdP',
      bot_token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJib3RfaWQiOiJYa0VVaFp2U0VHOW9QaWpyeU5kUCIsInVzZXJpZCI6InJVZ2t5dWlFVVdZVHo4aWxlSFNBcnh0eGVrQzMiLCJpYXQiOjE2OTA0MjkxMTl9.Az_FRJhOab0KX81JrYrO6F5hIlmDFOPRZ2tGpW_C148',
    },
  ]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setseverity('info');
    setlogmsg(null);
    setisconfigure(false);
    setisstylize(false);
    setisdeploy(false);
    setdeleteconfirm(false);
    setPreviewImage(null);
    setisagentopen(false);
  };
  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;
  const handleBotConfigure = (e) => {
    setisconfigure(true);
    setAnchorEl(spanRef.current);
  };
  const handleBotStylize = (e) => {
    setisstylize(true);
    setAnchorEl(spanRef.current);
  };
  const handleBotPublish = (e) => {
    setisdeploy(true);
    setAnchorEl(spanRef.current);
  };
  const handleAgentInfo = (e, agent) => {
    setisagentopen(true);
    setcurrentagent(agent);
    setAnchorEl(e.currentTarget);
  };
  const handleBotDelete = (e, botid) => {
    console.log(deployedagents);
    setisagentopen(false);
    setdeleteconfirm(true);
    setdeletebotid(botid);
    setAnchorEl(e.currentTarget);
  };

  const handleBotDeploy = async (e) => {
    if (!selectedFile) {
      setseverity('error');
      setlogmsg('please select a avatar for bot.');
      return;
    }
    if (!!variantName.find((item) => item.name === 'document-search')) {
      if (!collectionid) {
        setseverity('warning');
        setlogmsg('please select a collection to enable document search.');
        return;
      }
    }
    console.log(selectedmodel);
    const llmapirequired = llmKeys.filter((item) => item.vendor === selectedmodel.vendor);
    if (!llmapirequired[0].key) {
      setseverity('error');
      setlogmsg(`Please add api key for ${selectedmodel.vendor} and refresh the page.`);
      return;
    }
    setseverity('info');
    setlogmsg('');
    setisdeploy(true);
    setloading(true);
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Append the JSON data to the formData
      let payload = {
        name: agentname,
        visibility: visibility,
        tools: variantName,
        aimodel: selectedmodel,
        avatar: avatarurl,
        description: botdescription,
        collectionid: collectionid,
        botinstruction: botinstruction,
      };

      formData.append('agentInfo', JSON.stringify(payload));

      try {
        const response = await BotClient.addBot(formData, authenticationToken);
        if (response.status === 200) {
          setseverity('success');
          setlogmsg('Agent deployed successfully.');
          const deployedAgent = {
            ...payload,
            bot_token: response.bot_token,
            avatar: response.avatar,
            botid: response.botid,
          };
          setdeployedagents((prevagents) => [...prevagents, deployedAgent]);
          setloading(false);
          setagentname('');
          setVariantName([]);
          setSelectedFile(null);
          setbotdescription('');
          setisdeploy(false);
        } else {
          setloading(false);
          setisdeploy(false);
          setseverity('warning');
          if (response.msg) {
            setlogmsg(response.msg);
          } else {
            setlogmsg('Something went wrong.');
          }
        }
      } catch (error) {
        setloading(false);
        setisdeploy(false);
        console.error('Error uploading file:', error);
        setseverity('warning');
        setlogmsg('Something went wrong during the upload.');
      }
    }
    setAnchorEl(null);
  };

  const deleteBot = async (e, botid) => {
    setloading(true);
    let response = await BotClient.deleteBot(botid, authenticationToken);
    setloading(false);
    setlogmsg('');
    if (response.status == 200) {
      setseverity('success');
      setlogmsg('Agent deleted successfully');
      var currentdeployedagents = deployedagents.filter(function (element) {
        return element.botid !== botid;
      });
      setdeployedagents(currentdeployedagents);
      setdeletebotid(null);
      setAnchorEl(null);
    } else {
      setseverity('warning');
      setlogmsg('Something went wrong.');
    }
  };
  const updateBot = async (e) => {
    setloading(true);
    const formData = new FormData();
    if (currentagent.selectedFile) {
      formData.append('file', currentagent.selectedFile);
    }

    // Append the JSON data to the formData
    let payload = {
      name: currentagent.name,
      visibility: currentagent.visibility,
      description: currentagent.description,
      botinstruction: currentagent.botinstruction,
      avatar: currentagent.avatar,
      botid: currentagent.botid,
      collectionid: currentagent.collectionid,
    };

    formData.append('agentInfo', JSON.stringify(payload));
    try {
      const response = await BotClient.updateBot(formData, authenticationToken);
      if (response.status === 200) {
        setseverity('success');
        setlogmsg('Agent updated successfully.');

        setdeployedagents((prevAgents) =>
          prevAgents.map((agent) => {
            if (agent.botid === currentagent.botid) {
              // Replace this with the desired botid
              // Modify the properties of the matched agent
              return {
                ...agent,
                // Update the properties you want to change
                visibility: response.visibility,
                name: response.name,
                description: response.description,
                botinstruction: response.botinstruction,
                avatar: response.avatar,
                // Update other properties as needed
              };
            }
            return agent; // Return the unchanged agent for non-matching botids
          })
        );
        setloading(false);
        setcurrentagent({
          ...currentagent,
          visibility: response.visibility,
          name: response.name,
          description: response.description,
          botinstruction: response.botinstruction,
          avatar: response.avatar,
        });
        setisdeploy(false);
      } else {
        setloading(false);
        setisdeploy(false);
        setseverity('warning');
        if (response.msg) {
          setlogmsg(response.msg);
        } else {
          setlogmsg('Something went wrong.');
        }
      }
    } catch (error) {
      setloading(false);
      setisdeploy(false);
      console.error('Error uploading file:', error);
      setseverity('warning');
      setlogmsg('Something went wrong during the upload.');
    }
  };
  console.log(deployedagents);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'string') {
      setVariantName([]);
    } else {
      const clickedItem = value[value.length - 1]; // Get the clicked item
      const alreadySelected = variantName.some((item) => item.id === clickedItem.id); // Check if the item is already selected

      if (alreadySelected) {
        // Remove the item from the state
        setVariantName((prevState) => prevState.filter((item) => item.id !== clickedItem.id));
      } else {
        // Add the item to the state
        setVariantName(value);
      }
    }
  };
  const handleVisibilityChange = (event) => {
    setvisibilty(event.target.value);
  };
  const handleNameChange = (event) => {
    setagentname(event.target.value);
  };
  const handleBotDescription = (event) => {
    setbotdescription(event.target.value);
  };
  const handleBotInstruction = (event) => {
    setbotinstruction(event.target.value);
  };
  const handleUpdateVisibilityChange = (event) => {
    setcurrentagent({ ...currentagent, visibility: event.target.value });
  };
  const handleUpdateNameChange = (event) => {
    setcurrentagent({ ...currentagent, name: event.target.value });
  };
  const handleUpdateBotDescription = (event) => {
    setcurrentagent({ ...currentagent, description: event.target.value });
  };
  const handleUpdateBotInstruction = (event) => {
    setcurrentagent({ ...currentagent, botinstruction: event.target.value });
  };
  const handleUpdateBotCollection = (event) => {
    setcurrentagent({ ...currentagent, collectionid: event.target.value });
  };
  const resetInstruction = () => {
    setbotinstruction(`Compose a well-structured response to the user's question, using context and conversation information. 
      Format your answer with distinct <h3>titles</h3> and <h3>subtitles</h3>, <b>emphasis</b>, <b>bold</b>, <i>italic<i>, <li>lists</li>, and tables *use html code*.
      Use agent style if given.
      output programming code in appropriate markdown format, so it's highlighted
      Reference context source number only in this format <sup><b>[Number]</b></sup> after every paragraph or sentence.
      If context lacks relevant information, disclose that and discuss related topics. 
      Response:`);
  };
  const handleModelChange = (event) => {
    const {
      target: { value },
    } = event;
    setselectedmodel(value);
  };
  const handleCollectionChange = (event) => {
    setcollectionid(event.target.value);
    console.log(event.target.value);
    if (event.target.value !== null) setErr(null);
  };

  async function getsharedata(botid) {
    setdownloading(true);
    let sessionconvo = await BotClient.getBotSessionData(botid, authenticationToken);
    const jsonString = JSON.stringify(sessionconvo, null, 2);
    const jsonData = JSON.parse(jsonString);
    const csv = Papa.unparse(jsonData);
    // Create a new Blob object with the JSON string
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

    // Generate a file name for the exported file
    const fileName = 'bot_sessions_data.csv';

    // Save the file using file-saver
    setdownloading(false);
    saveAs(blob, fileName);
  }
  function DeleteBotConfirm() {
    return (
      <Grid sx={{ display: 'flex', justifyContent: 'center', p: 2, flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body1" sx={{ m: 5 }}>
          Are you sure you want to delete this agent?
        </Typography>
        <Button
          variant="contained"
          onClick={(e) => deleteBot(e, deletebotid)}
          sx={{ width: '50%', backgroundColor: '#C70000' }}
        >
          Delete
        </Button>
      </Grid>
    );
  }

  return (
    <PageComp title="Create Bots">
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vh',
            height: '100vh',
          }}
        >
          <Grid sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            {logmsg && (
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Alert severity={severity}>{logmsg}</Alert>
              </Grid>
            )}
            {loading && <CircularProgress size="1rem" />}
            <Typography variant="h5" sx={{}}>
              Deploy Your AI bot
            </Typography>
            <Typography variant="caption" sx={{ mb: 2 }}>
              Make custom AI chat pipelines easily.
            </Typography>
          </Grid>
          <Container
            maxWidth="xl"
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <div style={{ position: 'fixed', left: '50%' }} component="span" ref={spanRef}></div>
            <Card
              sx={{
                padding: { xs: '0.3rem', md: '1rem' },
                margin: '0.5rem',
                marginLeft: isMobile ? 0 : '0.5rem',
                marginRight: isMobile ? 0 : '0.5rem',
                borderRadius: { xs: '0.4rem', md: '1rem' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h5">Step 1:</Typography>
              <Typography variant="subtitle1" sx={{ p: 2 }}>
                🛠️ cofigure your bot.
              </Typography>
              <Button variant="contained" sx={{ p: 1 }} onClick={(e) => handleBotConfigure(e)} ref={configureRef}>
                Configure
              </Button>
            </Card>
            <Iconify icon={'bi:arrow-right'} sx={{ width: 25, height: 25, m: 1 }} color="#808080" width={50} />
            <Card
              sx={{
                padding: { xs: '0.3rem', md: '1rem' },
                margin: '0.5rem',
                marginLeft: isMobile ? 0 : '0.5rem',
                marginRight: isMobile ? 0 : '0.5rem',
                borderRadius: { xs: '0.4rem', md: '1rem' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h5">Step 2:</Typography>
              <Typography variant="subtitle1" sx={{ p: 2 }}>
                👨‍💻 Give Instruction
                <Typography variant="caption" sx={{ color: 'gray' }}>
                  (optional)
                </Typography>
                .
              </Typography>
              <Button variant="contained" sx={{ p: 1 }} onClick={(e) => handleBotStylize(e)} ref={stylizeRef}>
                Stylize
              </Button>
            </Card>

            <Iconify icon={'bi:arrow-right'} sx={{ width: 25, height: 25, m: 1 }} color="#808080" width={50} />
            <Card
              sx={{
                padding: { xs: '0.3rem', md: '1rem' },
                margin: '0.5rem',
                marginLeft: isMobile ? 0 : '0.5rem',
                marginRight: isMobile ? 0 : '0.5rem',
                borderRadius: { xs: '0.4rem', md: '1rem' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h5">Step 3:</Typography>
              <Typography variant="subtitle1" sx={{ p: 2 }}>
                ✅️ Publish your Bot.
              </Typography>
              <Button variant="contained" sx={{ p: 1 }} onClick={(e) => handleBotPublish(e)} ref={deployRef}>
                Publish
              </Button>
            </Card>
            <Popover
              id={id}
              open={openPop}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  borderRadius: 5,
                  maxWidth: '80%', // You can adjust the maximum width if needed
                },
              }}
            >
              {isconfigure ? (
                //configure bot card
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 25,
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="caption">step:1 choose model and basic configuration of your bot.</Typography>
                  <TextField label="Name" style={{ margin: 10 }} value={agentname} onChange={handleNameChange} />
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-simple-select-helper-label">AI model</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={selectedmodel}
                      label="Models"
                      onChange={handleModelChange}
                      input={<OutlinedInput label="AI model" />}
                      required
                      MenuProps={MenuProps}
                    >
                      {availablemodels.map((model) => {
                        return (
                          <MenuItem value={model} key={model.id}>
                            <ListItemText
                              primary={model.model}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="caption"
                                    color="text.secondary"
                                  >
                                    {model.vendor}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </MenuItem>
                        );
                      })}
                      <MenuItem value={{ model: 'custom' }} disabled>
                        <ListItemText
                          primary={'claude'}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="caption"
                                color="text.secondary"
                              >
                                Anthropic
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </MenuItem>
                      <MenuItem value={{ model: 'custom' }} disabled>
                        <ListItemText
                          primary={'custom LLM'}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="caption"
                                color="text.secondary"
                              >
                                coming soon
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-checkbox-label">Tools</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={variantName}
                      displayEmpty
                      onChange={handleChange}
                      input={<OutlinedInput label="Tools" />}
                      renderValue={(selected) => selected.map((x) => x.name).join(', ')}
                      MenuProps={MenuProps}
                    >
                      {variants.map((variant) => (
                        <MenuItem key={variant.id} value={variant}>
                          <Checkbox checked={variantName.findIndex((item) => item.id === variant.id) >= 0} />
                          <ListItemText
                            primary={variant.name}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="caption"
                                  color="text.secondary"
                                >
                                  {variant.source}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {!!variantName.find((item) => item.name === 'document-search') && (
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel size="small" id="demo-simple-select-helper-label">
                        Document Collections
                      </InputLabel>
                      <Select
                        value={collectionid || 'Select'}
                        label=" Document Collection"
                        onChange={handleCollectionChange}
                        required
                        size="small"
                      >
                        <MenuItem value={'Select'} disabled>
                          {'Select'}
                        </MenuItem>
                        {customcollections.map((collection) => {
                          return <MenuItem value={collection.collectionid}>{collection.CollectionName}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  )}
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <input
                      type="file"
                      id="file-input"
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        setSelectedFile(event.target.files[0]);
                        setPreviewImage(URL.createObjectURL(event.target.files[0]));
                      }}
                    />
                    {selectedFile ? (
                      <Avatar
                        alt="Bot Preview"
                        src={previewImage}
                        sx={{ width: 36, height: 36, cursor: 'pointer', m: 1 }}
                        onClick={(e) => handleAgentInfo(e, agent)}
                      />
                    ) : null}
                    <label htmlFor="file-input" style={{ width: '100%', padding: 7 }}>
                      <Button variant="outlined" component="span" sx={{ width: '100% ' }}>
                        {selectedFile ? selectedFile.name.slice(0, 35) : 'Upload your bot avatar'}
                      </Button>
                    </label>
                  </Grid>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Button
                      variant="contained"
                      sx={{ p: 1, width: '50%' }}
                      onClick={(e) => {
                        handleClose();
                        setisstylize(true);
                        setAnchorEl(spanRef.current);
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              ) : //configure bot card end
              isstylize ? (
                //stylize bot card
                <div style={{ display: 'flex', justifyContent: 'center', margin: 25, flexDirection: 'column' }}>
                  <Typography variant="caption">step:2 personalize your bot with custom instructions</Typography>
                  <TextField
                    label="Bot Instruction"
                    style={{ margin: 10, fontSize: '12px' }}
                    inputProps={{ style: { fontSize: '14px' } }}
                    value={botinstruction}
                    multiline
                    onChange={handleBotInstruction}
                  />
                  <Typography variant="caption" sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                    <Iconify icon="ic:baseline-info" sx={{ color: '#C70000', width: 15, height: 15, mr: 1 }} />
                    avoid changing too much default instruction as it may cause formatting issues.
                    <Chip
                      sx={{ ml: 1 }}
                      size="small"
                      label={`Default`}
                      onClick={() => {
                        resetInstruction();
                      }}
                      variant="outlined"
                      style={{ fontSize: '13px', color: '#386ea2' }}
                    />
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Button
                      variant="contained"
                      sx={{ p: 1, flex: 1 }}
                      onClick={(e) => {
                        handleClose();
                        setisconfigure(true);
                        setAnchorEl(spanRef.current);
                      }}
                    >
                      Back
                    </Button>
                    <Grid sx={{ flex: 2 }}></Grid>
                    <Button
                      variant="contained"
                      sx={{ p: 1, flex: 1 }}
                      onClick={(e) => {
                        handleClose();
                        setisdeploy(true);
                        setAnchorEl(spanRef.current);
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              ) : //stylize bot card end
              isdeploy ? (
                //publish bot card start
                <div style={{ display: 'flex', justifyContent: 'center', margin: 25, flexDirection: 'column' }}>
                  <Typography variant="caption">step:3</Typography>
                  <TextField
                    label="Bot Description"
                    style={{ margin: 10 }}
                    value={botdescription}
                    inputProps={{ style: { fontSize: '14px' } }}
                    onChange={handleBotDescription}
                  />
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <FormLabel id="demo-radio-buttons-group-label">Agent Accessibilty</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={visibility}
                      onChange={handleVisibilityChange}
                      sx={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <FormControlLabel value="public" control={<Radio />} label="Public" />
                      <FormControlLabel value="private" control={<Radio />} label="Private" />
                    </RadioGroup>
                  </FormControl>
                  <Grid sx={{ display: 'flex', mt: 2 }}></Grid>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Button
                      variant="contained"
                      sx={{ p: 1, flex: 1 }}
                      onClick={(e) => {
                        handleClose();
                        setisstylize(true);
                        setAnchorEl(spanRef.current);
                      }}
                    >
                      Back
                    </Button>
                    <Grid sx={{ flex: 2 }}></Grid>
                    <Button variant="contained" sx={{ p: 1, flex: 1 }} onClick={(e) => handleBotDeploy(e)}>
                      Deploy
                    </Button>
                  </div>
                </div>
              ) : isagentopen ? (
                <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', p: 5 }}>
                  <Grid sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    {logmsg && (
                      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Alert severity={severity}>{logmsg}</Alert>
                      </Grid>
                    )}
                    {loading && <CircularProgress size="1rem" />}
                  </Grid>
                  {downloading ? (
                    <Grid item alignItems="center" justifyContent="center">
                      <CircularProgress size="1rem" />
                    </Grid>
                  ) : (
                    <Grid item>
                      <Button variant="contained" onClick={() => getsharedata(currentagent.botid)} style={{}}>
                        All Sessions Data
                        <Iconify icon={'material-symbols:download'} width={22} height={22} />
                      </Button>
                    </Grid>
                  )}
                  <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    {' '}
                    Share Link:
                  </Typography>
                  {currentagent.visibility == 'private' ? (
                    <Typography variant="caption" paragraph sx={{ mb: 2, maxWidth: '600px', color: 'red' }}>
                      Don't share private bots, as anybody using them will use your ai credits for their chats, It is
                      advisable to only share them with individuals whom you specifically intend the bot to be used by.{' '}
                    </Typography>
                  ) : (
                    <Typography variant="caption" paragraph sx={{ mb: 2, maxWidth: '600px', color: 'green' }}>
                      Public bots can be shared openly. When users interact with your public bot, they will utilize
                      their own AI credits or API key for the chat, not your credits/key.
                    </Typography>
                  )}

                  <Input
                    sx={{ width: '100%' }}
                    value={`${REACT_ROOT_URL}agents/${currentagent.botid}`}
                    endAdornment={
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(`${REACT_ROOT_URL}agents/${currentagent.botid}`);
                        }}
                      >
                        <Iconify icon={'clarity:copy-to-clipboard-line'} sx={{ width: 20, height: 20, mr: 1 }} />
                      </IconButton>
                    }
                  />
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mt: 2,
                    }}
                  >
                    <input
                      type="file"
                      id="file-input"
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        setcurrentagent({ ...currentagent, selectedFile: event.target.files[0] });
                        setPreviewImage(URL.createObjectURL(event.target.files[0]));
                      }}
                    />

                    <label htmlFor="file-input" style={{ width: '100%' }}>
                      <AvatarWithOverlay
                        avatarSrc={`${previewImage ? previewImage : currentagent.avatar}`} // Provide the path to your avatar image
                      />
                    </label>
                  </Grid>
                  <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    Name -
                  </Typography>
                  <TextField
                    sx={{ width: '100%' }}
                    id="dfasd"
                    value={currentagent.name}
                    variant="standard"
                    inputProps={{ style: { fontSize: '14px' } }}
                    onChange={handleUpdateNameChange}
                    multiline
                  />
                  <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    Description -
                  </Typography>
                  <TextField
                    sx={{ width: '100%' }}
                    value={currentagent.description}
                    variant="standard"
                    onChange={handleUpdateBotDescription}
                    inputProps={{ style: { fontSize: '14px' } }}
                    multiline
                  />
                  {currentagent.botinstruction && (
                    <>
                      <Typography variant="subtitle1" sx={{ mt: 2 }}>
                        Instruction -
                      </Typography>
                      <TextField
                        sx={{ width: '100%' }}
                        value={currentagent.botinstruction}
                        variant="standard"
                        onChange={handleUpdateBotInstruction}
                        maxRows={6}
                        inputProps={{ style: { fontSize: '14px' } }}
                        multiline
                      />
                    </>
                  )}
                  <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    Collection -
                  </Typography>
                  <FormControl sx={{ mt: 1, width: 300 }}>
                    <InputLabel size="small" id="demo-simple-select-helper-label">
                      Document Collections
                    </InputLabel>
                    <Select
                      value={currentagent.collectionid || 'Select'}
                      label=" Document Collection"
                      onChange={handleUpdateBotCollection}
                      required
                      size="small"
                    >
                      <MenuItem value={'Select'} disabled>
                        {'Select'}
                      </MenuItem>
                      {customcollections.map((collection) => {
                        return <MenuItem value={collection.collectionid}>{collection.CollectionName}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <Grid sx={{ display: 'flex', flexDirection: 'row', mt: 2, width: '100%' }}>
                    <Grid sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <Typography variant="subtitle1">Visibility </Typography>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={currentagent.visibility}
                          onChange={handleUpdateVisibilityChange}
                          sx={{ display: 'flex', flexDirection: 'row', fontSize: '12px' }}
                        >
                          <FormControlLabel
                            value="public"
                            control={<Radio size="small" />}
                            label={
                              <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
                                Public
                              </Typography>
                            }
                          />

                          <FormControlLabel
                            value="private"
                            control={<Radio size="small" />}
                            label={
                              <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
                                Private
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <Typography variant="subtitle1">Tools -</Typography>
                      {currentagent.tools.map((tool) => {
                        return <Typography variant="body2">{tool.name}</Typography>;
                      })}
                    </Grid>
                    <Grid sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <Typography variant="subtitle1">AI model -</Typography>
                      <Typography variant="body2">
                        {currentagent.aimodel.model} ({currentagent.aimodel.vendor})
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ display: 'flex' }}>
                    <Link
                      to={`${REACT_ROOT_URL}agents/${currentagent.botid}`}
                      target="_blank"
                      style={{ display: 'flex', justifyContent: 'center', flex: 1 }}
                    >
                      <Button variant="text" sx={{ flex: 1 }}>
                        Open
                        <Iconify
                          icon={'ion:open-outline'}
                          sx={{
                            width: 15,
                            height: 15,
                            ml: 1,
                            color: 'black',
                            opacity: 0.8,
                          }}
                        />
                      </Button>
                    </Link>
                    <Grid sx={{ flex: 2 }}></Grid>
                    <Button variant="contained" sx={{ flex: 1 }} onClick={(e) => updateBot(e)}>
                      {' '}
                      Save
                    </Button>
                  </Grid>
                </Grid>
              ) : deleteconfirm ? (
                <DeleteBotConfirm />
              ) : null}
            </Popover>
          </Container>
          <Grid
            sx={{
              display: 'flex',
              mt: 5,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="subtitle2" sx={{ mb: 3 }}>
                Your AI Agents
              </Typography>
            </Grid>
            <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              {deployedagents.length > 0 ? (
                deployedagents.map((agent, index) => {
                  return (
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} key={index}>
                      <Avatar
                        alt="Remy Sharp"
                        src={agent.avatar}
                        sx={{ width: 46, height: 46, cursor: 'pointer' }}
                        onClick={(e) => handleAgentInfo(e, agent)}
                      >
                        {agent.name}
                      </Avatar>
                      <Typography variant="body" sx={{ ml: 2, mr: 2, mt: 2, fontSize: 12 }}>
                        {agent.name}
                      </Typography>
                      <IconButton onClick={(e) => handleBotDelete(e, agent.botid)}>
                        <Iconify icon="ic:baseline-delete" sx={{ color: '#C70000', width: 15, height: 15 }} />
                      </IconButton>
                    </div>
                  );
                })
              ) : (
                <Typography variant="subtitle2">0 deployed agents, Deploy Now.</Typography>
              )}
            </Grid>
          </Grid>
          {/*
          <Grid sx={{ mt: 5, display: isMobile ? 'none' : 'flex', flexDirection: 'column', flex: 1 }}>
            <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="subtitle2" sx={{ mb: 3 }}>
                Try Public Agents
              </Typography>
            </Grid>
            <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              {publicagents.map((agent, index) => {
                return (
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Link
                      to={`${REACT_ROOT_URL}agents/${agent.botid}`}
                      target="_blank"
                      text
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Avatar alt="agent" src={agent.avatar} sx={{ width: 36, height: 36, cursor: 'pointer' }}>
                        {agent.name}
                      </Avatar>
                    </Link>
                    <Typography variant="body" sx={{ m: 3, fontSize: 13 }}>
                      {agent.name}
                    </Typography>
                  </div>
                );
              })}
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Link
                  to={`${REACT_ROOT_URL}bots/discover`}
                  target="_blank"
                  text
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Iconify icon={'mingcute:more-3-fill'} sx={{ width: 36, height: 36, mr: 1 }} color="gray" />
                </Link>
                <Typography variant="body" sx={{ m: 3, fontSize: 13 }}>
                  More
                </Typography>
              </div>
            </Grid>
            </Grid>*/}
        </Grid>
      </Grid>
    </PageComp>
  );
}
