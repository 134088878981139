import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  IconButton,
  Typography,
  TextField,
  Button,
  Grid,
  Stack,
  Alert,
  Divider,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import documentClient from 'src/clients/DocumentClient';
import Iconify from 'src/components/Iconify';
import Context from 'src/context';
import useResponsive from 'src/hooks/useResponsive';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const Uploader = ({ id, anchorEl, setAnchorEl }) => {
  const [userIsDragging, setUserIsDragging] = useState(false);
  const navigate = useNavigate();
  const isMobile = useResponsive('down', 'sm');

  let {
    user,
    customcollections,
    authenticationToken,
    setcustomcollections,
    setuseraccountinfo,
    useraccountinfo,
    activeCollection,
    setActiveCollection,
    navsessions,
    setnavsessions,
  } = useContext(Context);
  const [fromCollection, setFromCollection] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [showselectedfile, setshowselectedfile] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [uploadCount, setUploadCount] = useState(0);
  const [totalBulkUploadCount, setTotalBulkUploadCount] = useState(0);
  const [currentBulkUploadCount, setCurrentBulkUploadCount] = useState(0);
  const [currentFilename, setCurrentFilename] = useState('');
  const [currentFilesize, setcurrentFilesize] = useState('');

  const [uploadProgress, setUploadProgress] = useState(0);

  const [uploadType, setUploadType] = useState('file');
  const [logmsg, setlogmsg] = useState('');
  const [severity, setseverity] = useState('info');
  const [metadataValues, setMetadataValues] = useState([]);

  const [webLink, setWebLink] = useState('');
  const [fetchedLinks, setFetchedLinks] = useState([]);

  useEffect(() => {
    setFromCollection(activeCollection);
  }, [activeCollection]);
  const handleFetchLink = async () => {
    if (webLink !== '') {
      setseverity('info');
      setlogmsg('extracting all links, it can take upto 1 min.');
      setIsFetching(true);

      let links = await documentClient.fetchWebLink(webLink, authenticationToken);
      if (links.length > 0) {
        setFetchedLinks(links);
      }
      setseverity('info');
      setlogmsg('');
      setIsFetching(false);
    }
  };

  const handleDropedFiles = async (Files) => {
    if (fromCollection === null) {
      setseverity('warning');
      setlogmsg('please select collection');
      return;
    }
    setshowselectedfile(true);
    Files.forEach((file) => {
      file.metadata = '';
    });
    setSelectedFiles([...Files]);
  };
  console.log(selectedFiles);
  function formatSizeUnits(bytes) {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + ' GB';
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes > 1) {
      bytes = bytes + ' bytes';
    } else if (bytes == 1) {
      bytes = bytes + ' byte';
    } else {
      bytes = '0 bytes';
    }
    return bytes;
  }
  const uploadFileWithMeta = async () => {
    let Files = selectedFiles;
    setTotalBulkUploadCount(Files.length);
    let uploadResponse;
    setseverity('info');
    console.log(selectedFiles);
    if (selectedFiles[0].type.includes('video')) {
      setlogmsg('Processing large videos may take some time. Feel free to do other tasks.');
    } else {
      setlogmsg('it takes ~1 min. per 100 pages of pdf to upload');
    }
    if (Files.length > 1) {
      try {
        console.log(Files);
        let current = 1;

        for (let file of selectedFiles) {
          setshowselectedfile(false);
          setIsUploading(true);
          setCurrentFilename(file.name);
          setcurrentFilesize(formatSizeUnits(file.size));
          const uploadedFile = await uploadFile(file, file.metadata);
          setCurrentBulkUploadCount(current);
          console.log(current);
          console.log(uploadedFile);
          uploadResponse = uploadedFile;
          setUploadedFiles((prevdocuments) => [uploadedFile.fileName, ...prevdocuments]);
          useraccountinfo.documents_count = useraccountinfo.documents_count + 1;
          setuseraccountinfo({ ...useraccountinfo });
          let _collection = customcollections.map((coll) => {
            if (coll.collectionid === fromCollection) {
              coll.documents.push(uploadedFile);
              // console.log(uploadedFile);
            }
            return coll;
          });
          setcustomcollections(_collection);
          setIsUploading(false);
        }
        current++;
      } catch (err) {
        setlogmsg(err.message);
        console.log('error');
      }
    } else {
      try {
        let current = 1;

        setIsUploading(true);
        setCurrentFilename(Files[0].name);
        setcurrentFilesize(formatSizeUnits(Files[0].size));

        setCurrentBulkUploadCount(current);
        const uploadedFile = await documentClient.uploadDocument(
          Files[0],
          authenticationToken,
          fromCollection,
          Files[0].metadata
        );
        setUploadedFiles((prevdocuments) => [uploadedFile.fileName, ...prevdocuments]);
        useraccountinfo.documents_count = useraccountinfo.documents_count + 1;
        setuseraccountinfo({ ...useraccountinfo });
        console.log(uploadedFile);
        uploadResponse = uploadedFile;
        let _collection = customcollections.map((coll) => {
          if (coll.collectionid === fromCollection) {
            coll.documents.push(uploadedFile);
            // console.log(uploadedFile);
          }
          return coll;
        });
        setcustomcollections(_collection);
        setIsUploading(false);
      } catch (err) {
        setlogmsg(err.message.data);
        setIsUploading(false);
        console.log(err);
        console.log('error');
        return;
      }
    }
    setSelectedFiles([]);
    setlogmsg('');
    setnavsessions((prevsessions) => [
      {
        title: uploadResponse.sessionname ? uploadResponse.sessionname : 'New Chat',
        path: `/dashboard/s/${uploadResponse.sessionid}`,
        icon: getIcon('iconoir:message'),
      },
      ...navsessions,
    ]);
    if (uploadResponse.sessionid) {
      navigate(`/dashboard/s/${uploadResponse.sessionid}`);
    }
  };
  const handleDeleteLink = (idx) => {
    console.log(idx);
    let _links = [...fetchedLinks];
    _links.splice(idx, 1);
    console.log(_links);
    setFetchedLinks(_links);
  };

  const handleUploadLinks = async () => {
    if (fromCollection === null) {
      setseverity('warning');
      setlogmsg('please select collection to upload data');
      return;
    }
    setseverity('info');
    setlogmsg('extracting and uploading websites');

    setIsUploading(true);
    let linksPromises = fetchedLinks.map((link) => {
      return new Promise(async (resolve) => {
        let response = await documentClient.uploadLinks(link, fromCollection, authenticationToken);
        console.log(response);
        let _collection = customcollections.map((coll) => {
          if (coll.collectionid === fromCollection) {
            coll.documents.push(response);
            // console.log(uploadedFile);
          }
          return coll;
        });
        setcustomcollections(_collection);
        useraccountinfo.documents_count = useraccountinfo.documents_count + 1;
        setuseraccountinfo({ ...useraccountinfo });
        resolve();
      });
    });
    await Promise.all(linksPromises);

    setseverity('info');
    setlogmsg('upload complete');
    setFetchedLinks([]);
    setWebLink('');

    setIsUploading(false);
  };
  async function handlemetadata(event, index) {
    const newMetadataValues = [...metadataValues];
    newMetadataValues[index] = event.target.value;
    setMetadataValues(newMetadataValues);

    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles[index].metadata = event.target.value;
    setSelectedFiles(updatedSelectedFiles);
  }
  async function uploadFile(file, metadata) {
    const document = await documentClient.uploadDocument(file, authenticationToken, fromCollection, metadata);
    // setDocuments([document, ...documents])
    // setDocuments((prevDocuments) => [document, ...prevDocuments])
    setUploadCount((prevcount) => prevcount + 1);
    return document;
  }

  return (
    <Popover
      id={Boolean(anchorEl) ? id : undefined}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => {
        setAnchorEl(null);
        setUploadedFiles([]);
        setSelectedFiles([]);
        setshowselectedfile(false);
        setMetadataValues([]);
        setUploadCount(0);
        setlogmsg('');
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: { borderRadius: 5 },
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Box sx={{ padding: '1rem' }}>
          <FormControl>
            <InputLabel size="small" id="demo-simple-select-helper-label">
              Upload Type
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={uploadType}
              label="Upload Type"
              onChange={(e) => {
                setUploadType(e.target.value);
              }}
              menuPlacement="top"
            >
              <MenuItem value={'file'}>{'File/Video'}</MenuItem>
              <MenuItem value={'weblinks'}>{'Web Links'}</MenuItem>
            </Select>
            <FormHelperText size="small">Select what to upload</FormHelperText>
          </FormControl>
          <FormControl sx={{ marginLeft: isMobile ? 1 : 2 }}>
            <InputLabel id="demo-simple-select-helper-label" size="small">
              Collections
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={fromCollection || 'Select'}
              label="Collections"
              size="small"
              onChange={(e) => {
                setFromCollection(e.target.value);
                setActiveCollection(e.target.value);
              }}
              menuPlacement="top"
            >
              <MenuItem value={'Select'} disabled>
                {'Select'}
              </MenuItem>
              {customcollections.map((collection) => {
                return <MenuItem value={collection.collectionid}>{collection.CollectionName}</MenuItem>;
              })}
            </Select>
            <FormHelperText size="small">Select Collection to Save to</FormHelperText>
          </FormControl>
        </Box>

        {isUploading && uploadType == 'file' && (
          <>
            <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
              Uploading...
              <Typography variant="subtitle2" sx={{ display: 'inline' }}>
                {'  '}
                {currentFilename} ({currentFilesize})
              </Typography>
            </Typography>

            <span className={'font-bold'}>
              ({uploadCount}/{totalBulkUploadCount})
            </span>
          </>
        )}
        {showselectedfile && (
          <>
            <List dense={true}>
              {selectedFiles.map((file, index) => {
                return (
                  <ListItem>
                    <Grid container>
                      <Grid item sm={8}>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle2">
                              {index + 1}. {file.name}
                            </Typography>
                          }
                          sx={{}}
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Add metadata"
                          variant="outlined"
                          style={{ width: '100%' }}
                          value={metadataValues[index] || ''}
                          size="small"
                          onChange={(event) => handlemetadata(event, index)}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
            <Button variant="contained" onClick={uploadFileWithMeta}>
              Upload All
            </Button>
          </>
        )}

        <List dense={true}>
          {uploadedFiles.map((file) => {
            return (
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="comments">
                    <Iconify
                      icon={'fluent-mdl2:completed-solid'}
                      sx={{ width: 20, height: 20, ml: 1 }}
                      color="00FF00"
                    />
                  </IconButton>
                }
              >
                <ListItemText primary={file} />
              </ListItem>
            );
          })}
        </List>
        {uploadType === 'file' ? (
          <>
            {isUploading ? (
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Grid>
            ) : null}
            {logmsg && (
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Alert severity={severity}>{logmsg}</Alert>
              </Grid>
            )}
            <Dropzone style={{ width: '400px', height: '400px' }} onDrop={handleDropedFiles}>
              {({ getRootProps, getInputProps }) => (
                <Box
                  {...getRootProps()}
                  sx={{
                    width: isMobile ? '90%' : '700px',
                    height: isMobile ? '90%' : '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '1rem',
                    padding: '1rem',
                    border: '4px dotted blue',
                  }}
                >
                  <Iconify icon={'material-symbols:upload-file'} sx={{ width: 30, height: 30, ml: 1 }} />

                  <section>
                    <div>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                </Box>
              )}
            </Dropzone>
          </>
        ) : (
          <Grid
            container
            spacing={2}
            direction={'row'}
            sx={{ width: '100%', padding: '1rem 3rem' }}
            alignItems="stretch"
            justifyContent="space-evenly"
          >
            <Grid item xs={10}>
              <TextField
                value={webLink}
                onChange={(e) => setWebLink(e.target.value)}
                label="Web Link"
                variant="outlined"
                size="small"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" onClick={handleFetchLink}>
                Fetch Link
              </Button>
            </Grid>
            {isFetching ? (
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Typography>Fetched Links</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {fetchedLinks.map((link, idx) => (
              <>
                <Grid item xs={10}>
                  <TextField value={link} variant="outlined" size="small" fullWidth={true} />
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" onClick={() => handleDeleteLink(idx)} color="error">
                    <Iconify icon={'mdi:delete-forever'} sx={{ width: 20, height: 20, mr: 1 }} />
                  </Button>
                </Grid>
              </>
            ))}
            {isUploading ? (
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Grid>
            ) : null}
            {fetchedLinks.length > 0 ? (
              <Grid item xs={12}>
                <Button variant="contained" onClick={handleUploadLinks}>
                  Upload links
                </Button>
              </Grid>
            ) : (
              ''
            )}
            {logmsg && (
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Alert severity={severity}>{logmsg}</Alert>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </Popover>
  );
};

export default Uploader;
