import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Stack, Button, Divider, Typography, CircularProgress } from '@mui/material';
import Iconify from '../../components/Iconify';
import { UNIFY_URI } from '../../config';
import { auth, signInWithEmailAndPassword, signInWithGoogle } from '../../firebase';

import { useAuthState } from 'react-firebase-hooks/auth';
import { set } from 'lodash';

// ----------------------------------------------------------------------
const clientId = '612470439960-9cr8dfia2v3hr89no7emec851rrt9sug.apps.googleusercontent.com';
export default function AuthSocial() {
  const [user, loading, error] = useAuthState(auth);
  const [cookies, setCookie] = useCookies(['session_auth_token']);
  const [isLogging, setisLogging] = useState(false);
  const { state } = useLocation();
  console.log(state);
  const navigate = useNavigate();

  async function LoginHandler() {
    setisLogging(true);
    const userconfdata = await signInWithGoogle();
    console.log(userconfdata.idToken);

    setCookie('session_auth_data', userconfdata, { path: '/' });

    console.log(cookies.session_auth_data);
    if (state) {
      navigate(state.from);
    } else {
      navigate('/dashboard/ask');
    }
    setisLogging(false);
  }

  useEffect(() => {
    console.log(cookies.session_auth_token);
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
  }, [user, loading]);
  return (
    <>
      <Stack direction="row">
        <Button fullWidth size="large" color="inherit" variant="outlined" onClick={LoginHandler}>
          <Iconify icon="eva:google-fill" color="#1877F2" width={22} height={22} />
        </Button>
        {/* <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
        </Button>

        <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
        </Button> */}
      </Stack>
      {isLogging && (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingTop: 5 }}>
          <CircularProgress size="2rem" />
        </div>
      )}
      <Divider sx={{ my: 2 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider>
    </>
  );
}
