import { CORPORA_URI } from '../config';

class SharingClient {
  async createSharingLink(authorizationToken) {
    const responseJson = await fetch(CORPORA_URI + '/create-sharing-link', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authorizationToken,
      },
    });

    return await responseJson.json();
  }
  async createCollectionSharingLink(authorizationToken, collectionid, Status, sharing_key) {
    let formData = new FormData();
    formData.append('collectionid', collectionid);
    let collectioninfo = {
      collectionid: collectionid,
      Status: Status,
      sharingKey: sharing_key,
    };
    const responseJson = await fetch(CORPORA_URI + '/create-collection-sharing-link', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authorizationToken,
      },
      body: JSON.stringify(collectioninfo),
    });

    return await responseJson.json();
  }
  async deleteSharingLink(authorizationToken) {
    const responseJson = await fetch(CORPORA_URI + '/delete-sharing-link', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authorizationToken,
      },
    });

    return await responseJson.json();
  }

  async getShare(authorizationToken) {
    const responseJson = await fetch(CORPORA_URI + '/get-share', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authorizationToken,
      },
    });

    let json = await responseJson.json();

    return Object.assign(
      {
        is_hiding_logo: false,
        is_hiding_link: false,
      },
      json
    );
  }

  async updateSharingTitle(authorizationToken, title) {
    const responseJson = await fetch(CORPORA_URI + '/update-sharing-title', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authorizationToken,
      },
      body: JSON.stringify({ title: title }),
    });

    return await responseJson.json();
  }

  async updateIsHidingLogo(authorizationToken, isHidingLogo) {
    const responseJson = await fetch(CORPORA_URI + '/update-hiding-logo', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authorizationToken,
      },
      body: JSON.stringify({ isHidingLogo: isHidingLogo }),
    });

    return await responseJson.json();
  }

  async updateIsHidingLink(authorizationToken, isHidingLink) {
    const responseJson = await fetch(CORPORA_URI + '/update-hiding-link', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authorizationToken,
      },
      body: JSON.stringify({ isHidingLink: isHidingLink }),
    });

    return await responseJson.json();
  }
}

const sharingClient = new SharingClient();
export default sharingClient;
