import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { CORPORA_URI } from '../config';

import Context from '../context';
import { useContext } from 'react';
import conversationClient from '../clients/ConversationClient';
import AgentClient from '../clients/AgentClient';
import { styled } from '@mui/material/styles';

import { SSE } from 'sse';

import logo from '../assets/logo.png';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Box,
  InputAdornment,
  TextField,
  CircularProgress,
  ToggleButton,
  Stack,
  ToggleButtonGroup,
  Dialog,
  DialogContent,
  Tooltip,
  Switch,
  tooltipClasses,
  IconButton,
  Button,
  Avatar,
} from '@mui/material';
// components
import PageComp from '../components/Page';
import Iconify from '../components/Iconify';
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
import { auth, db, logout } from '../firebase';

// sections
import { ApplicationPanelBS } from '../sections/@dashboard/app';
import useResponsive from 'src/hooks/useResponsive';

import PDFViewer from './PDFViewer';

// ----------------------------------------------------------------------
const APPBAR_DESKTOP = 92;
const DRAWER_WIDTH = 280;
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(102, 115, 128, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      maxWidth: 180,
      padding: '15px 15px',
    },
  })
);
export default function BetterGoogle() {
  const { setShowSidebar } = useOutletContext();
  const isMobile = useResponsive('down', 'sm');
  const isTablet = useResponsive('down', 'lg');
  const theme = useTheme();
  const messagesEndRef = useRef(null);
  const [everytime, seteverytime] = useState(false);

  const [applicationtype, setapplicationtype] = useState('Database');
  const [authState, setauthState] = useState(false);
  const navigate = useNavigate();

  const [name, setName] = useState('');

  const [documents, setDocuments] = useState([]);

  const [cookies, setCookie] = useCookies(['session_auth_data']);

  const [primeQuestion, setprimeQuestion] = useState('');

  const [checked, setChecked] = useState([]);

  // const [isGPT, setIsGPT] = useState(false);
  const [includeSource, setincludeSource] = useState(false);

  const [isAccurate, setIsAccurate] = useState(false);
  const [loadingmessage, setloadingmessage] = useState(false);
  const [sessionId, setsessionId] = useState(null);
  useEffect(() => {
    setsessionId(null);
    setShowSidebar(false);
  }, []);

  //useEffect(() => {
  //  let source = new SSE('http://127.0.0.1:5000/listen', {
  //    method: 'GET',
  //  });
  //  console.log('stream');
  //  source.addEventListener('message', (e) => {
  //    console.log('streaming started');
  //    if (e.data != '[DONE]') {
  //      console.log(e);
  //      let responseData = e.data;
  //      const validJSONData = responseData.replace(/'/g, '"');
  //      const parsedData = JSON.parse(validJSONData);
  //      console.log(parsedData);
  //      //setchunkd([...chunkd, e.source.chunk]);
  //    } else {
  //      source.close();
  //    }
  //  });

  //  source.addEventListener('readystatechange', (e) => {
  //    if (e.readyState >= 2) {
  //    }
  //  });

  //  source.stream();
  //}, []);
  let { id } = useParams();

  let sampleConversation = {};
  sampleConversation[undefined] = [
    {
      message: 'Can you explain the reasons for the decrease in our gross margin for the fiscal year?',
      isBot: false,
    },
    {
      message: {
        answer:
          'The decrease in gross margin for the fiscal year can be attributed to a few factors. Firstly, we experienced an increase in the cost of raw materials which impacted the cost of goods sold. Additionally, we also had to offer discounts and promotions in order to remain competitive in the market. These factors combined resulted in a decrease in our gross margin for the fiscal year',
        documents: [
          {
            page: 18,
            file: 'Annual_Report.pdf',
            score: 0.92,
          },
        ],
      },
      isBot: true,
    },

    {
      message: 'How did our international sales perform compared to domestic sales for the fiscal year?',
      isBot: false,
    },
    {
      message: {
        answer:
          'For the fiscal year, our international sales performed well and grew by XX%, compared to domestic sales which only grew by YY%. This can be attributed to the efforts we made to expand our presence in international markets, as well as a favorable exchange rate.',
        documents: [
          {
            page: 8,
            file: 'International_Sales.pdf',
            score: 0.96,
          },
        ],
      },
      isBot: true,
    },

    {
      message: 'Can you provide an overview of our cash flow for the fiscal year?',
      isBot: false,
    },
    {
      message: {
        answer:
          'Our cash flow for the fiscal year was strong. We had a positive cash flow from operating activities of $XX million, which was primarily driven by our profits. Additionally, our investing activities also generated a positive cash flow of $YY million through the sale of investments. Our financing activities also generated a positive cash flow of $ZZ million through the issuance of new debt. Overall, we ended the fiscal year with a cash balance of $AAA million.',
        documents: [
          {
            page: 12,
            file: 'Annual_Report.pdf',
            score: 0.8,
          },
        ],
      },
      isBot: true,
    },
    {
      message: 'Can you explain the impact of the new tax laws on our financial results for the fiscal year?',
      isBot: false,
    },
    {
      message: {
        answer:
          'The new tax laws had a significant impact on our financial results for the fiscal year. The decrease in the corporate tax rate resulted in a lower effective tax rate for the year, which led to an increase in our net income. However, the new laws also resulted in a one-time charge for the revaluation of our deferred tax assets and liabilities which had a negative impact on our results for the fiscal year.',
        documents: [
          {
            page: 15,
            file: 'Annual_Report.pdf',
            score: 0.8,
          },
        ],
      },
      isBot: true,
    },
  ];
  // console.log('in convo');
  sampleConversation = null;

  const [question, setQuestion] = useState('');
  const [questionIndex, setQuestionIndex] = useState(0);
  const [fromcollection, setfromcollection] = useState('');
  const [toggle, setToggle] = useState('');
  const [agents, setAgents] = useState([]);

  // const [user, isLoading, error] = useAuthState(auth);
  let {
    user,
    authenticationToken,
    msgloading,
    setMsgloading,
    msg,
    customcollections,
    setMsg,
    isSharing,
    activeCollection,
    activeDocument,
    setActiveDocument,
    activeTask,
    setActiveTask,
    activeAgent,
    setActiveAgent,
    activeModel,
    setActiveModel,
    temperature,
    setTemperature,
    setuseraccountinfo,
    useraccountinfo,
    isGPT,
    setIsGPT,
    navsessions,
    setnavsessions,
  } = useContext(Context);
  // const [messages, setMessages] = useState(sampleConversation || {});
  // const [isSendingQuestion, setIsSendingMessage] = useState(false);
  const scrollRef = React.createRef();

  let [result, setResult] = useState('');
  let [answeringon, setansweringon] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfKeyword, setpdfKeyword] = useState('');
  const [pdfPage, setpdfPage] = useState(0);
  const [opensidebyside, setopensidebyside] = useState(false);

  const resultRef = useRef();
  useEffect(() => {
    setActiveModel(null);
    setActiveDocument(null);
    setTemperature(null);
    setActiveAgent(null);
  }, []);
  useEffect(() => {
    // setIsLoading(true);

    async function fetchData() {
      if (authenticationToken !== null) {
        console.info(' Getting Agents');
        const response = await AgentClient.getAgents(authenticationToken);
        setAgents(response);
      }
    }
    fetchData();
  }, [authenticationToken]);
  useEffect(() => {
    resultRef.current = result;
  }, [result]);

  //console.log(window.location.href.split('/').pop());

  useEffect(() => {
    async function fetchData() {
      if (sampleConversation) {
        return;
      }

      let newMessages = await conversationClient.getUserConversation(
        authenticationToken ? authenticationToken : cookies.session_auth_data.idToken
      );
      //console.log('setting messages');

      // console.log(newMessages);
      setMsg({
        ...msg,
        [id]: [],
      });
      console.log(' in main effect');
      if (msg[undefined].length == 0) {
        console.log('new user');
      } else {
        console.log('already a user');
      }
    }
    if (authenticationToken) {
      fetchData();
    }
  }, [authenticationToken, cookies]);

  async function regenerateResponse() {
    let q = getQuestionToRegenerate();
    let qI = questionIndex + 1;
    setQuestionIndex(qI);
    msg[id].pop();
    msg[id].pop();
    await sendQuestion(q, qI);
  }

  function getQuestionToRegenerate() {
    return msg[id] && msg[id][msg[id].length - 2] && !msg[id][msg[id].length - 2].isBot
      ? msg[id][msg[id].length - 2].message
      : ' ';
  }
  console.log(activeDocument);

  async function sendQuestion(q, qI) {
    let questionToUse = q || question;

    setMsgloading(true);
    console.log('chatcontext');
    let allchatresponse = msg[id].filter((item) => item.isBot === true);
    let chatcontexts = allchatresponse.slice(-3).map((item) => item.message.msgsummary);
    const chatcontext = chatcontexts.join('\n');

    console.log(chatcontext);

    if (msgloading) {
      return;
    }
    if (!sessionId) {
      setnavsessions((prevsessions) => [
        {
          title: 'New Chat',
          path: `current`,
          icon: getIcon('iconoir:message'),
        },
        ...prevsessions,
      ]);
    }
    let prevquestionanswerpair = msg[id].slice(-2);
    console.log(prevquestionanswerpair);
    setMsg({});
    let withQuestion = [
      {
        message: questionToUse,
        isBot: false,
      },
    ];
    setMsg({
      [id]: withQuestion,
    });

    if (!isSharing) {
    }

    let authtoken = authenticationToken ? authenticationToken : cookies.session_auth_data.idToken;

    try {
      console.log('fromcollection', fromcollection);
      let customData = '';
      if (isGPT) {
        checked.forEach((ele) => {
          if (!msg[id][ele].isBot) {
            customData += msg[id][ele].message + '\n';
          } else {
            customData += msg[id][ele].message.answer + '\n';
          }
        });
      }
      console.log(agents.find((ele) => ele.agentid === activeAgent));
      console.log(activeDocument);
      const fdata = {
        question: questionToUse,
        collectionid: activeCollection,
        documentid: activeDocument,
        agentData: agents.find((ele) => ele.agentid === activeAgent),
        temperature: temperature,
        modeltouse: activeModel,
        customdata: customData,
        msgsummary: '',
        prevquestionanswerpair: '',
        session_id: sessionId,
        session_type: 'admin',
        session_name: questionToUse.slice(0, 10),
        googlesearchquery: questionToUse,
      };
      if (activeModel == 'gpt-3.5-turbo') {
        useraccountinfo.searches_count = useraccountinfo.searches_count + 1;
        setuseraccountinfo({ ...useraccountinfo });
      } else if (activeModel == 'gpt-4') {
        useraccountinfo.searches_count = useraccountinfo.searches_count + 20;
        setuseraccountinfo({ ...useraccountinfo });
      } else if (activeModel == 'gpt-4-0314') {
        useraccountinfo.searches_count = useraccountinfo.searches_count + 20;
        setuseraccountinfo({ ...useraccountinfo });
      } else {
        useraccountinfo.searches_count = useraccountinfo.searches_count + 1;
        setuseraccountinfo({ ...useraccountinfo });
      }
      if (questionToUse !== '') {
        let url = CORPORA_URI + '/ask-from-custom-collection-stream';
        if (isAccurate) {
          url = CORPORA_URI + '/ask-gpt-recurse-stream';
        }
        if (isGPT) {
          url = CORPORA_URI + '/ask-gpt-custom-stream';
        }
        console.log(url);

        let source = new SSE(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer: ' + authtoken,
          },
          method: 'POST',
          payload: JSON.stringify(fdata),
        });
        console.log('stream');
        console.log(msg);
        setMsg((prevState) => {
          return {
            ...prevState,
            [id]: [
              {
                message: {
                  answer: '',
                  documents: [],
                  msgsummary: '',
                },
                paymentRequired: false,
                isBot: true,
              },
            ],
          };
        });
        source.addEventListener('message', (e) => {
          console.log('streaming started');

          if (e.data != '[DONE]') {
            // console.log(e);
            let payload = JSON.parse(e.data);

            let text = payload.data.answer;

            if (text != '\n') {
              let prevMessages = isSharing ? [] : [];

              console.log(prevMessages);
              setMsg({
                [id]: [
                  {
                    message: {
                      answer: id ? payload.data.answer : payload.data.answer || payload.data.answer,
                      documents: id ? [] : payload.data.documents,
                      msgsummary: payload.data.msgsummary,
                      generatingsummary: payload.data.generatingsummary,
                      streamend: payload.data.streamend,
                      session_id: payload.data.session_id,
                    },
                    paymentRequired: payload.data.paymentRequired,
                    isBot: true,
                  },
                ],
              });
              if (payload.status == 200) {
                setsessionId(payload.data.session_id);
                setnavsessions((prevsessions) => [
                  {
                    title: payload.data.session_name ? payload.data.session_name : 'New Chat',
                    path: `/dashboard/s/${payload.data.session_id}`,
                    icon: getIcon('iconoir:message'),
                  },
                  ...navsessions,
                ]);
                //if (payload.data.generatingsummary == false && payload.data.streamend == true) {
                //  navigate(`/dashboard/s/${payload.data.session_id}`);
                //}
              } else {
                setnavsessions((prevsessions) => [...navsessions]);
              }
            }
          } else {
            source.close();
          }
        });

        source.addEventListener('readystatechange', (e) => {
          if (e.readyState >= 2) {
            setprimeQuestion('');

            console.log(msg);

            setMsgloading(false);
          }
        });

        source.stream();
      } else {
        alert('Please insert a prompt!');
      }
    } catch (e) {
      if (e.type === 'payment') {
        if (window.confirm('You have run out of free credits, please create a subscription to continue')) {
          window.location.href = '/dashboard/profile';
        }

        setMsgloading(false);
        return;
      }
    }

    setMsgloading(false);

    // if (window.LO.events && window.LO.events.track) {
    //   window.LO.events.track("search");
    // }

    if (window.mixpanel && window.mixpanel.track) {
      window.mixpanel.track('Search', {
        source: 'Web App',
        question: q.substring(0, 200),
      });
    }
  }
  console.log(sessionId);
  console.log(navsessions);
  useEffect(() => {
    setauthState(true);
  }, []);
  const onTextChange = (event) => {
    props.onQuestionChange(question);
    setQuestion(event.target.value);
  };

  let onKeyPress = (e) => {
    if (e.keyCode === 13) {
      sendQuestion(e.target.value);
    }
  };
  let handlesend = (e) => {
    sendQuestion(e.target.value);
  };
  const handleQuestion = (event) => {
    setQuestion(event.target.value);
  };

  const scrollToBottom = () => {
    // messagesEndRef.current?.scrollIntoView();
    console.log('scrolling to bottom');
    messagesEndRef.current.scrollTo(0, messagesEndRef.current?.scrollHeight);
  };

  useEffect(() => {
    if (msg[id]) {
      //scrollToBottom();
    }
  });
  useEffect(() => {
    seteverytime(!everytime);
    //console.log(everytime);
  }, []);

  const handleCollectionChange = (event) => {
    setfromcollection(event.target.value);
  };
  const handleOnChecked = (e) => {
    let idx = checked.findIndex((ele) => ele == e.target.value);
    let _data = [...checked];
    if (idx !== -1) {
      _data.splice(idx, 1);
    } else {
      _data = [..._data, e.target.value];
    }
    setChecked(_data);
  };
  // console.log(fromcollection);
  // console.log(question);
  // console.log(msgloading);
  const handlepdfclose = () => {
    setPdfUrl(null);
    setpdfKeyword('');
    setpdfPage(0);
  };

  useEffect(() => {
    if (Boolean(pdfUrl)) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [Boolean(pdfUrl)]);
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  useEffect(() => {
    if (msg[id] != undefined) {
      if (msg[id].length == 0) {
        setMsg((prevState) => {
          return {
            ...prevState,
            [id]: [
              ...prevState[id],
              {
                message: {
                  answer: ' ',
                  documents: [],
                  generatedquestion: true,
                },
                generatetopic: true,
                paymentRequired: false,
                additionaltext: `Hello and welcome to Better Google! 😊, BG provides direct AI analysis of google search results so you don't have to spend time jumping from one site to another in search for answer.
                `,
                isBot: true,
              },
            ],
          };
        });
      } else {
        console.log('old user');
      }
    }
  });
  return msg[id] != undefined ? (
    <PageComp title="Dashboard">
      <Grid container>
        <Grid
          item
          xs={Boolean(pdfUrl) ? 6 : 12}
          md={Boolean(pdfUrl) ? 6 : 12}
          lg={Boolean(pdfUrl) ? 6 : 12}
          sx={{ paddingTop: 8 }}
        >
          <Container
            maxWidth="xl"
            sx={{
              position: 'relative',
              height: '97vh',
            }}
          >
            <Grid
              container
              spacing={3}
              ref={messagesEndRef}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                maxHeight: '100vh',

                overflowY: `${msg[id].length < 2 ? 'scroll' : 'scroll'}`,
                overflowX: 'hidden',
                webkitScrollBar: 'none',
                paddingTop: `2rem`,
                height: `calc( 90vh - 1rem)`,
              }}
            >
              {msg ? (
                <Grid item xs={12} md={Boolean(pdfUrl) ? 12 : 10} lg={Boolean(pdfUrl) ? 12 : 8} sx={{ mt: 5 }}>
                  {msg[id].map((message, idx) => (
                    <div>
                      {message.isBot ? (
                        message.paymentRequired ? (
                          <div>
                            {!isSharing ? (
                              <div>
                                Your account has run out of credits, please visit your{' '}
                                <a className={'underline hover:no-underline'} href="/profile">
                                  profile
                                </a>{' '}
                                and pick a plan to continue.
                              </div>
                            ) : (
                              <div>This account has run out of credits, please contact your administrator. </div>
                            )}
                          </div>
                        ) : id ? (
                          <Typography key={id} variant="subtitle1" style={{ padding: 10 }}>
                            {message.message}
                          </Typography>
                        ) : (
                          <ApplicationPanelBS
                            idx={idx}
                            title={message.message.answer}
                            subheader="+ Create Database API"
                            message={message}
                            documents={message.message.documents}
                            setPdfUrl={isMobile || isTablet ? null : setPdfUrl}
                            setpdfKeyword={setpdfKeyword}
                            setpdfPage={setpdfPage}
                            setShowSidebar={isMobile || isTablet ? null : setShowSidebar}
                            isBot={message.isBot}
                            userAvatar={user.photoURL}
                            handleOnChecked={handleOnChecked}
                            isGPT={isGPT}
                            sendQuestion={sendQuestion}
                            checked={Boolean(checked.find((ele) => ele == idx))}
                          />
                        )
                      ) : (
                        <>
                          <ApplicationPanelBS
                            idx={idx}
                            title={message.message}
                            subheader="+ Create Database API"
                            message={message}
                            isBot={message.isBot}
                            userAvatar={user.photoURL}
                            handleOnChecked={handleOnChecked}
                            isGPT={isGPT}
                            sendQuestion={sendQuestion}
                            checked={Boolean(checked.find((ele) => ele == idx))}
                          />
                        </>
                      )}
                    </div>
                  ))}
                </Grid>
              ) : (
                <CircularProgress />
              )}
            </Grid>
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: isTablet ? 0 : Boolean(pdfUrl) ? 0 : DRAWER_WIDTH,

                width: Boolean(pdfUrl)
                  ? '58.33%'
                  : isMobile
                  ? '100%'
                  : isTablet
                  ? '100%'
                  : `calc(100% - ${2 * DRAWER_WIDTH + 1}px)`,
                minHeight: '10vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'white',

                padding: isMobile ? '0rem' : '2rem',
                // backgroundImage: 'linear-gradient(to top,white,80%, transparent)',
                backgroundColor: isMobile ? 'transparent' : 'transparent',
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ width: isMobile ? '100%' : '100%', mt: isTablet ? (isMobile ? 7 : 4) : 0 }}
              >
                <Grid
                  item
                  xs={11}
                  lg={9}
                  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Avatar
                    src={logo}
                    alt="photoURL"
                    sx={{ mr: 3, width: 35, height: 35, display: isMobile ? 'none' : 'block' }}
                    width={5}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Ask"
                    variant="outlined"
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onKeyUp={onKeyPress}
                    value={question}
                    onChange={handleQuestion}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handlesend}>
                            <Iconify
                              icon="material-symbols:search"
                              sx={{ color: 'text.disabled', width: 25, height: 25 }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Grid>

        {Boolean(pdfUrl) ? (
          <Grid item xs={6} md={6} lg={6}>
            <Button
              onClick={() => {
                setPdfUrl(null);
                setpdfKeyword('');
                setpdfPage(0);
                setShowSidebar(true);
              }}
              variant="text"
              size="small"
              sx={{ color: 'red', transform: { translate: { x: '-50%' } } }}
            >
              <Iconify icon={'ic:round-close'} sx={{ width: 20, height: 20, mr: 1 }} />
            </Button>

            <Box>{Boolean(pdfUrl) ? <PDFViewer pdfUrl={pdfUrl} keyword={pdfKeyword} page={pdfPage} /> : ''}</Box>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </PageComp>
  ) : (
    <Grid
      item
      xs={12}
      md={10}
      lg={10}
      style={{ padding: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <CircularProgress />
    </Grid>
  );
}
