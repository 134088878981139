import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { CORPORA_URI } from '../config';

import { faker } from '@faker-js/faker';
import { set } from 'lodash';
import { useAuthState } from 'react-firebase-hooks/auth';
import Context from '../context';
import { useContext } from 'react';
import conversationClient from '../clients/ConversationClient';
import sharingClient from '../clients/SharingClient';
import DashboardRightSidebar from '../layouts/dashboard/DashboardRightSidebar';
import { styled } from '@mui/material/styles';
import Scrollbar from '../components/Scrollbar';
import useResponsive from '../hooks/useResponsive';
import PDFViewer from './PDFViewer';

import { SSE } from 'sse';

// @mui
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  InputAdornment,
  TextField,
  Drawer,
  List,
  Button,
  Chip,
  ListItem,
  ListItemIcon,
  ToggleButton,
  ToggleButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Dialog,
  DialogContent,
  CircularProgress,
  Box,
  Divider,
  ListItemText,
  ListItemButton,
  IconButton,
} from '@mui/material';
// components
import Page from '../components/Page';
import Register from './Register';
import { UNIFY_URI } from '../config';

import Iconify from '../components/Iconify';
import { auth, db, logout } from '../firebase';
const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  ApplicationPanel,
  AppTrafficBySite,
  Application,
  AppCurrentSubject,
  CodeSnippet,
} from '../sections/@dashboard/app';
import { faCropSimple } from '@fortawesome/free-solid-svg-icons';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;
const SideBar = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100vh',
  paddingTop: APP_BAR_MOBILE + 24,
  zIndex: 0,
}));
const DRAWER_WIDTH = 340;
const APPBAR_DESKTOP = 92;

const RootStyle = styled('div')(({ theme }) => ({
  pt: APPBAR_DESKTOP,
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));
// ----------------------------------------------------------------------

export default function ShareBot() {
  const theme = useTheme();
  let { sharingkey } = useParams();
  console.log(sharingkey);
  const messagesEndRef = useRef(null);
  const [everytime, seteverytime] = useState(false);
  const [open, setOpen] = useState(false);
  const isDesktop = useResponsive('up', 'lg');
  const [applicationtype, setapplicationtype] = useState('Database');
  const [authState, setauthState] = useState(false);
  const navigate = useNavigate();
  const isMobile = useResponsive('down', 'sm');
  const isTablet = useResponsive('down', 'lg');
  const [logmsg, setlogmsg] = useState('loading...');

  const [documents, setDocuments] = useState([]);
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfKeyword, setpdfKeyword] = useState('');
  const [pdfPage, setpdfPage] = useState(0);
  const [sessionId, setsessionId] = useState(null);

  const setApplication = (apptype) => {
    setapplicationtype(apptype);
  };
  const navigatetopayments = (apptype) => {
    navigate('/dashboard/paylink');
  };
  const [cookies, setCookie] = useCookies(['user']);
  const [primeQuestion, setprimeQuestion] = useState('');
  const [checked, setChecked] = useState([]);
  const [sidebar, setShowSidebar] = useState(false);

  let { id } = useParams();
  const scrollToBottom = () => {
    if (messagesEndRef.current) messagesEndRef.current.scrollTo(0, messagesEndRef.current.scrollHeight);
  };
  const handleOnChecked = (e) => {
    let idx = checked.findIndex((ele) => ele == e.target.value);
    let _data = [...checked];
    if (idx !== -1) {
      _data.splice(idx, 1);
    } else {
      _data = [..._data, e.target.value];
    }
    setChecked(_data);
  };
  // console.log('in convo');
  let sampleConversation = {};
  sampleConversation[undefined] = [
    {
      message: 'What is scrol.ai?',
      isBot: false,
    },
    {
      message: {
        answer: ` 🎉 Welcome to scrol.ai! 🎉 We're excited to introduce our innovative chatbot, designed to help you interact with your documents and even create custom chatbots trained on your content.
          To begin, simply type your first question below, and let our AI-powered natural language search effortlessly guide you to the information you need. Interested in creating your own chatbot? Visit us at https://www.scrol.ai and explore the possibilities! 😊`,
        documents: [
          {
            page: 18,
            file: 'Scrol.ai',
            fileURL: 'https://www.scrol.ai/blog/10x-your-lawyer-streamline-your-legal-practice-with-ai-powered-tools',
            score: 0.92,
            paragraph: 'this is shareBOT with ',
          },
        ],
      },
      isBot: true,
    },
  ];
  const [isGPT, setIsGPT] = useState(false);
  const [isAccurate, setIsAccurate] = useState(false);
  const [toggle, setToggle] = useState('');

  const [question, setQuestion] = useState('');
  const [questionIndex, setQuestionIndex] = useState(0);
  const [fromcollection, setfromcollection] = useState('');
  const [msg, setMsg] = useState(sampleConversation);
  const [msgloading, setMsgloading] = useState(false);
  const [activeModel, setactiveModel] = useState('gpt-3.5-turbo');
  const [models, setModels] = useState([
    { id: 'gpt-3.5-turbo', name: 'gpt-3.5-turbo' },
    { id: 'gpt-4', name: 'gpt-4' },
    { id: 'gpt-4-0314', name: 'gpt-4-0314' },
    // { id: 'gpt-4-32k', name: 'gpt-4-32k' },
    // { id: 'gpt-4-32k-0314', name: 'gpt-4-32k-0314' },
  ]);
  const scrollRef = React.createRef();
  const [openRight, setOpenRight] = useState(false);

  let [result, setResult] = useState('');
  let [answeringon, setansweringon] = useState(false);

  const resultRef = useRef();
  useEffect(() => {
    scrollToBottom();
  });
  useEffect(() => {
    resultRef.current = result;
  }, [result]);
  console.log(window.location.href.split('/').pop());
  useEffect(() => {
    if (window.location.href.split('/').pop() == 'ask') {
      console.log('Ask');
      scrollToBottom();
    } else {
      scrollToBottom();
    }
  }, []);

  console.log(isGPT);
  console.log(isAccurate);
  async function sendQuestion(q, qI) {
    return new Promise(async (resolve) => {
      let questionToUse = q;
      console.log('question to use');
      console.info(questionToUse);
      setMsgloading(true);

      if (msgloading) {
        return;
      }

      let withQuestion = [
        ...msg[id],
        {
          message: questionToUse,
          isBot: false,
        },
      ];
      setMsg({
        ...msg,
        [id]: withQuestion,
      });
      setQuestion('');

      try {
        let customData = '';
        if (isGPT) {
          checked.forEach((ele) => {
            if (!msg[id][ele].isBot) {
              customData += msg[id][ele].message + '\n';
            } else {
              customData += msg[id][ele].message.answer + '\n';
            }
          });
        }
        console.log(customData);
        const fdata = {
          question: questionToUse,
          collectionid: sharedCollectionId,
          sharing: true,
          session_id: sessionId,
          session_type: 'sharing',
          session_name: null,
          modeltouse: activeModel,
          customdata: customData,
        };

        if (questionToUse !== '') {
          let url = CORPORA_URI + '/ask-from-custom-collection-stream';
          if (isAccurate) {
            url = CORPORA_URI + '/ask-gpt-recurse-stream';
          }
          console.log(isGPT);
          if (isGPT) {
            url = CORPORA_URI + '/ask-gpt-custom-stream';
          }
          console.log(url);

          let source = new SSE(url, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer: ' + sharingkey,
            },
            method: 'POST',
            payload: JSON.stringify(fdata),
          });
          setMsg((prevState) => {
            return {
              ...prevState,
              [id]: [
                ...prevState[id],
                {
                  message: {
                    answer: '',
                    documents: [],
                    msgsummary: '',
                  },
                  paymentRequired: false,
                  isBot: true,
                },
              ],
            };
          });

          source.addEventListener('message', (e) => {
            console.log('streaming started');

            if (e.data != '[DONE]') {
              // console.log(e);
              let payload = JSON.parse(e.data);
              let text = payload.data.answer;
              // console.log(text);
              if (text != '\n') {
                let prevMessages = withQuestion;

                setMsg({
                  ...msg,
                  [id]: [
                    ...prevMessages,
                    {
                      message: {
                        answer: id ? payload.data.answer : payload.data.answer || payload.data.answer,
                        documents: id ? [] : payload.data.documents,
                        msgsummary: payload.data.msgsummary,
                        generatingsummary: payload.data.generatingsummary,
                        streamend: payload.data.streamend,
                        session_id: payload.data.session_id,
                      },
                      paymentRequired: payload.data.paymentRequired,
                      isBot: true,
                    },
                  ],
                });
                setsessionId(payload.data.session_id);
              }
            } else {
              setMsgloading(false);

              source.close();
              resolve();
            }
          });

          source.addEventListener('readystatechange', (e) => {
            if (e.readyState >= 2) {
              resolve();
              setMsgloading(false);
            }
          });

          source.stream();
        } else {
          alert('Please insert a prompt!');
        }
      } catch (e) {
        if (e.type === 'payment') {
          if (window.confirm('You have run out of free credits, please create a subscription to continue')) {
            window.location.href = '/profile';
          }
          resolve();
          setMsgloading(false);
          return;
        }
        console.log(e);
      }

      setMsgloading(false);

      // if (window.LO.events && window.LO.events.track) {
      //   window.LO.events.track("search");
      // }

      if (window.mixpanel && window.mixpanel.track) {
        window.mixpanel.track('Search', {
          source: 'Web App',
          question: q.substring(0, 200),
        });
      }
    });
  }
  console.log(sessionId);
  useEffect(() => {
    setauthState(true);
  }, []);
  const onTextChange = (event) => {
    props.onQuestionChange(question);
    setQuestion(event.target.value);
  };

  //  function sendQuestion() {
  //    props.onSendQuestion();
  //    if (props.isClearAfterQuestion) {
  //      setQuestion('');
  //    }
  //  }

  let onKeyPress = (e) => {
    if (e.keyCode === 13) {
      sendQuestion(e.target.value);
    }
  };
  let handlesend = (e) => {
    sendQuestion(question);
  };
  const handleQuestion = (event) => {
    setQuestion(event.target.value);
  };

  console.log('everytime');
  const handleCollectionChange = (event) => {
    setfromcollection(event.target.value);
  };
  const [sharedCollectionName, setsharedCollectionName] = useState('');
  const [sharedCollectionId, setsharedCollectionId] = useState('');

  useEffect(() => {
    console.log('aedflenwl');
    let sharingKey = window.location.href.split('/').pop();
    console.log(sharingkey);

    async function fetchData() {
      if (sharingKey) {
        console.log(sharingkey);
        let sharingdata = await sharingClient.getShare(sharingkey, true);
        if (!sharingdata.collectionname) {
          setsharedCollectionName('oops!');
          setlogmsg('sharing link incorrect or collection is inactive');
        } else {
          console.log(sharingdata);
          setsharedCollectionName(sharingdata.collectionname);
          setsharedCollectionId(sharingdata.collectionid);
          setDocuments((prevdata) => [...sharingdata.documents]);
        }
      } else {
        console.log('no shared chat bot');
      }
    }
    fetchData();
  }, []);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Grid item xs={12} md={6} sx={{ p: 1 }}>
        <Typography variant="h4" component="div">
          Documents
        </Typography>
        <Divider />
        <List dense={true}>
          {documents.map((document, index) => {
            return (
              <ListItemButton
                size="small"
                target="_blank"
                href={`${document.fileURL}`}
                variant="text"
                disablePadding
                sx={{ borderBottom: '1px solid gray' }}
              >
                <ListItemIcon sx={{ display: 'inline-block', minWidth: '22px' }}>
                  <Iconify icon="ic:outline-insert-drive-file" sx={{ color: 'text.disabled' }} />
                </ListItemIcon>
                <ListItemText size="small" disablePadding>
                  {document.fileName}
                </ListItemText>
              </ListItemButton>
            );
          })}
        </List>
      </Grid>
    </Scrollbar>
  );

  return authState ? (
    <Page title="Dashboard" style={{ display: 'flex', flexDirection: 'row' }}>
      <SideBar>
        <RootStyle>
          {!isDesktop && (
            <Drawer
              open={open}
              onClose={() => setOpen(false)}
              PaperProps={{
                sx: { width: DRAWER_WIDTH },
              }}
            >
              {renderContent}
            </Drawer>
          )}

          {isDesktop && (
            <Drawer
              open
              variant="persistent"
              PaperProps={{
                sx: {
                  width: DRAWER_WIDTH,
                  bgcolor: 'background.default',
                  borderRightStyle: 'dashed',
                },
              }}
            >
              {renderContent}
            </Drawer>
          )}
        </RootStyle>
      </SideBar>
      <Grid container>
        <Grid
          item
          xs={Boolean(pdfUrl) ? 7 : 12}
          md={Boolean(pdfUrl) ? 7 : 12}
          lg={Boolean(pdfUrl) ? 7 : 12}
          sx={{ paddingTop: -1 }}
        >
          <Container
            maxWidth="xl"
            sx={{
              position: 'relative',
              height: '87vh',
            }}
          >
            <Typography
              variant="h4"
              sx={{ mb: 5, mt: 2, display: 'flex', alignItems: 'center', flexDirection: 'column' }}
            >
              Chat Bot
              <Typography variant="body1" sx={{ mb: 1, color: '#808080' }}>
                powered by{' '}
                <Link to="https://www.scrol.ai/" target="_blank">
                  scrol.ai
                </Link>
              </Typography>
              <Typography variant="subtitle2">
                <Button variant="contained" size="small" onClick={() => {}}>
                  {sharedCollectionName ? `Collection: ${sharedCollectionName}` : `${logmsg}`}
                </Button>
              </Typography>
              {/* <Typography variant="subtitle2" sx={{ wordWrap: 'break-word', width: '30rem' }}>
        {userObj.urltoken}
      </Typography> */}
            </Typography>
            <Grid
              container
              spacing={3}
              ref={messagesEndRef}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                maxHeight: '60vh',

                overflowY: `${msg[id].length < 2 ? 'hidden' : 'scroll'}`,
                overflowX: 'hidden',
                webkitScrollBar: 'none',
                // paddingTop: `2rem`,
              }}
            >
              {msg ? (
                <Grid item xs={12} md={Boolean(pdfUrl) ? 12 : 10} lg={Boolean(pdfUrl) ? 12 : 10}>
                  {msg[id].map((message, idx) => (
                    <div>
                      {message.isBot ? (
                        message.paymentRequired ? (
                          <div>
                            {!isSharing ? (
                              <div>
                                Your account has run out of credits, please visit your{' '}
                                <a className={'underline hover:no-underline'} href="/profile">
                                  profile
                                </a>{' '}
                                and pick a plan to continue.
                              </div>
                            ) : (
                              <div>This account has run out of credits, please contact your administrator. </div>
                            )}
                          </div>
                        ) : id ? (
                          <Typography key={id} variant="subtitle1" style={{ padding: 10 }}>
                            {message.message}
                          </Typography>
                        ) : (
                          <ApplicationPanel
                            idx={idx}
                            title={message.message.answer}
                            subheader="+ Create Database API"
                            message={message}
                            documents={message.message.documents}
                            setPdfUrl={setPdfUrl}
                            setpdfKeyword={setpdfKeyword}
                            setpdfPage={setpdfPage}
                            setShowSidebar={setShowSidebar}
                            isBot={message.isBot}
                            userAvatar={null}
                            handleOnChecked={handleOnChecked}
                            isGPT={isGPT}
                            checked={Boolean(checked.find((ele) => ele == idx))}
                          />
                        )
                      ) : (
                        <>
                          <ApplicationPanel
                            idx={idx}
                            title={message.message}
                            subheader="+ Create Database API"
                            message={message}
                            isBot={message.isBot}
                            userAvatar={null}
                            handleOnChecked={handleOnChecked}
                            isGPT={isGPT}
                            checked={Boolean(checked.find((ele) => ele == idx))}
                          />
                        </>
                      )}
                    </div>
                  ))}
                </Grid>
              ) : (
                <CircularProgress />
              )}
            </Grid>
            <Box
              sx={{
                position: 'fixed',
                bottom: 0,
                left: isTablet ? 0 : Boolean(pdfUrl) ? 0 : DRAWER_WIDTH,

                width: Boolean(pdfUrl)
                  ? '58.33%'
                  : isMobile
                  ? '100%'
                  : isTablet
                  ? '100%'
                  : `calc(100% - ${DRAWER_WIDTH + 1}px)`,
                minHeight: '10vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'white',
                height: '20vh',
                padding: isMobile ? '0rem' : '2rem',
                // backgroundImage: 'linear-gradient(to top,white,80%, transparent)',
                backgroundColor: isMobile ? 'transparent' : 'transparent',
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ width: isMobile ? '100%' : '90%' }}
              >
                <Grid item xs={11} lg={9}>
                  <TextField
                    id="outlined-basic"
                    label="Ask"
                    variant="outlined"
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onKeyUp={onKeyPress}
                    value={question}
                    onChange={handleQuestion}
                    multiline
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handlesend}>
                            <Iconify icon="carbon:send-alt" sx={{ color: 'text.disabled', width: 25, height: 25 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {/* <Grid item lg={3} style={{ display: 'flex', alignItems: 'center' }}>
                <LightTooltip
                  title="Enable GPT Direct Chat to engage in conversations solely with GPT-4 or GPT-3.5, without any connections to your personal data,  as if you were on the OpenAI platform. You can attach any conversations from your Chat history to give Context. We highly recommend this mode when you want to generate new content."
                  placement="right"
                >
                  <ToggleButtonGroup
                    value={toggle}
                    exclusive
                    onChange={(e, value) => {
                      setToggle(value);
                      if (value == 'gpt') {
                        console.log(value);
                        setIsGPT(true);
                        setIsAccurate(false);
                      } else if (value == 'inc') {
                        setIsAccurate(true);
                        setIsGPT(false);
                      } else {
                        setIsAccurate(false);
                        setIsGPT(false);
                      }
                    }}
                    aria-label="toggle"
                  >
                    <ToggleButton
                      sx={{
                        '&.Mui-selected,&:hover, &.Mui-selected:hover': {
                          backgroundColor: 'primary.main',
                          color: 'primary.contrastText',
                        },
                      }}
                      value="gpt"
                      aria-label="left aligned"
                    >
                      Direct GPT Chat
                    </ToggleButton>

                    <ToggleButton
                  sx={{
                    '&.Mui-selected,&:hover, &.Mui-selected:hover': {
                      backgroundColor: 'primary.main',
                      color: 'primary.contrastText',
                    },
                  }}
                  value="inc"
                  aria-label="centered"
                >
                  IncreaseAccurary
                </ToggleButton> 
                  </ToggleButtonGroup>
                </LightTooltip>
              </Grid>
            
              <Grid item sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl sx={{ padding: 0 }}>
                  <InputLabel size="small" id="demo-simple-select-helper-label">
                    Models
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={activeModel}
                    label="Model"
                    onChange={(e) => {
                      setactiveModel(e.target.value);
                    }}
                    sx={{ padding: 0 }}
                    size="small"
                  >
                    {models.map((model) => (
                      <MenuItem aria-label="None" value={model.name}>
                        {model.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Select a model to use</FormHelperText>
                </FormControl>
              </Grid> */}
              </Grid>
            </Box>
          </Container>
          {console.log(pdfUrl)}
        </Grid>
        {Boolean(pdfUrl) ? (
          <Grid item xs={5} md={5} lg={5}>
            <Button
              onClick={() => {
                setPdfUrl(null);
                setpdfKeyword('');
                setpdfPage(0);
                //setShowSidebar(true);
              }}
              variant="text"
              size="small"
              sx={{ color: 'red', transform: { translate: { x: '-50%' } } }}
            >
              <Iconify icon={'ic:round-close'} sx={{ width: 20, height: 20, mr: 1 }} />
            </Button>

            <Box>{Boolean(pdfUrl) ? <PDFViewer pdfUrl={pdfUrl} keyword={pdfKeyword} page={pdfPage} /> : ''}</Box>
          </Grid>
        ) : (
          ''
        )}
      </Grid>

      <SideBar></SideBar>
    </Page>
  ) : null;
}
