import { CORPORA_URI } from '../config';

class StripeClient {
  async checkout(authorizationToken, priceId, isOneTimePayment) {
    const data = new FormData();
    data.append('priceId', priceId);
    console.log(priceId);
    const responseJson = await fetch(CORPORA_URI + '/create-checkout-session', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authorizationToken,
      },
      body: JSON.stringify({ priceId: priceId, isOneTimePayment: isOneTimePayment }),
    });

    return await responseJson.json();
  }

  async startPortalSession(authorizationToken) {
    const responseJson = await fetch(CORPORA_URI + '/stripeportalsession', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authorizationToken,
      },
    });

    return await responseJson.json();
  }

  async getSubscription(authorizationToken) {
    const responseJson = await fetch(CORPORA_URI + '/usersubscription', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer: ' + authorizationToken,
      },
    });

    const sub = await responseJson.json();
    return sub['subscription'];
  }
}

const stripeClient = new StripeClient();
export default stripeClient;
