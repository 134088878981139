import React from 'react';
import {
  Grid,
  Card,
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  InputLabel,
  OutlinedInput,
  ListItemText,
  FormHelperText,
} from '@mui/material';

const Chatbot = () => {
  return (
    <Grid container direction="column" justifyContent="flex-end">
      <Grid item style={{ height: 'calc(100% - 56px)', overflowY: 'scroll', padding: '16px', marginBottom: '16px' }}>
        {/* Chat messages go here */}
        <Card variant="outlined">SOmerhj h</Card>
        <Card variant="outlined">SOmerhj h</Card>
      </Grid>
      <Grid container alignItems="center" style={{ padding: '16px' }}>
        <Grid item style={{ flexGrow: 1, marginRight: '16px' }}>
          <TextField variant="outlined" placeholder="Type a message..." />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary">
            Send
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Chatbot;
