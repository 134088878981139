import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { CORPORA_URI } from '../config';

import Context from '../context';
import { useContext } from 'react';
import BotClient from '../clients/BotClient';
import { styled } from '@mui/material/styles';

import { SSE } from 'sse';

import { REACT_ROOT_URL } from 'src/config';
import logo from '../assets/logo.png';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Box,
  InputAdornment,
  TextField,
  CircularProgress,
  ToggleButton,
  Stack,
  ToggleButtonGroup,
  Dialog,
  DialogContent,
  Tooltip,
  Switch,
  tooltipClasses,
  IconButton,
  Button,
  Avatar,
  Card,
  Popover,
  Input,
  CardMedia,
  CardContent,
  CardHeader,
} from '@mui/material';
// components
import PageComp from '../components/Page';
import Iconify from '../components/Iconify';
import BouncingDotsLoader from 'src/components/BouncingDotsLoader';
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
import { auth, db, logout } from '../firebase';

// sections
import { ApplicationPanelBS } from '../sections/@dashboard/app';
import useResponsive from 'src/hooks/useResponsive';

import PDFViewer from './PDFViewer';

// ----------------------------------------------------------------------
const APPBAR_DESKTOP = 92;
const DRAWER_WIDTH = 280;
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(102, 115, 128, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      maxWidth: 180,
      padding: '15px 15px',
    },
  })
);

export default function Agents() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['session_auth_token']);
  const isMobile = useResponsive('down', 'sm');
  const isTablet = useResponsive('down', 'lg');
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;
  const [publicbots, setpublicbots] = useState([]);
  const [currentagent, setcurrentagent] = useState(null);
  const [isagentopen, setisagentopen] = useState(false);
  useEffect(() => {
    async function fetchData() {
      let response = await BotClient.getPublicBots();
      console.log(response.bots);
      setpublicbots(response.bots);
    }
    fetchData();
  }, []);
  const handleClose = () => {
    setAnchorEl(null);

    setisagentopen(false);
  };
  const handleAgentInfo = (e, agent) => {
    setisagentopen(true);
    setcurrentagent(agent);
    setAnchorEl(e.currentTarget);
  };
  function BotDetails() {
    return (
      <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', p: 5 }}>
        <Typography variant="subtitle1"> Share Link:</Typography>

        <Input
          sx={{ width: '100%' }}
          value={`${REACT_ROOT_URL}agents/${currentagent.botid}`}
          endAdornment={
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(`${REACT_ROOT_URL}agents/${currentagent.botid}`);
              }}
            >
              <Iconify icon={'clarity:copy-to-clipboard-line'} sx={{ width: 20, height: 20, mr: 1 }} />
            </IconButton>
          }
        />
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Description -
        </Typography>
        <Typography variant="body2" sx={{ ml: 1, maxWidth: '600px' }}>
          {currentagent.description ? currentagent.description : 'No descriprion.'}
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Tools -
        </Typography>
        {currentagent.tools.map((tool, index) => {
          return (
            <Typography variant="body2" sx={{ ml: 1 }} key={index}>
              {tool.name}
            </Typography>
          );
        })}
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          AI model -
        </Typography>
        <Typography variant="body2" sx={{ ml: 1 }}>
          {currentagent.aimodel.name}
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Visibility -
        </Typography>
        <Typography variant="body2" sx={{ ml: 1 }}>
          {currentagent.visibility}
        </Typography>
        <Link
          to={`${REACT_ROOT_URL}agents/${currentagent.botid}`}
          target="_blank"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button variant="contained" sx={{ mt: 3 }}>
            Open
          </Button>
        </Link>
      </Grid>
    );
  }
  return (
    <Grid
      title="Dashboard"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        height: '90vh',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Popover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: 5,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {isagentopen ? <BotDetails /> : null}
      </Popover>
      <Grid
        sx={{
          mt: 5,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="subtitle1" sx={{ mb: 3 }}>
            Public Agents
          </Typography>
        </Grid>
        <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
          <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '80%' }}>
            {publicbots.length > 0 ? (
              publicbots.map((agent, index) => {
                return (
                  <Card
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: 5,
                      width: '320px',
                      justifyContent: 'flex-end',
                    }}
                    key={index}
                  >
                    <CardMedia component="img" height="150" image={agent.avatar} alt="Paella dish" />
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        {agent.description
                          ? agent.description
                          : 'This agent has no description, you can check the tools this agent uses to understand more about its usecases.'}
                      </Typography>
                    </CardContent>
                    <Grid
                      sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
                    >
                      <Typography variant="subtitle1" sx={{ fontSize: 15 }}>
                        {agent.name}
                      </Typography>
                      <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                        {agent.tools.map((tool, index) => {
                          return (
                            <Typography variant="caption" sx={{ ml: 1, mb: 1 }} key={index}>
                              {tool.name},
                            </Typography>
                          );
                        })}
                      </Grid>{' '}
                    </Grid>
                    <Grid
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Link
                        to={`${REACT_ROOT_URL}agents/${agent.botid}`}
                        target="_blank"
                        text
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Button variant="contained" sx={{ mb: 1 }}>
                          Open
                        </Button>
                      </Link>
                      <Grid
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="caption" sx={{ ml: 1, mb: 1, display: 'flex', alignItems: 'center' }}>
                          <Iconify icon={`ic:outline-person`} sx={{ width: 13, height: 13 }} color="#808080" />
                          {agent.authorname}
                        </Typography>
                        <Typography variant="caption" sx={{ ml: 1, mb: 1, display: 'flex', alignItems: 'center' }}>
                          |
                        </Typography>
                        <Typography variant="caption" sx={{ ml: 1, mb: 1, display: 'flex', alignItems: 'center' }}>
                          <Iconify icon={`majesticons:chats-line`} color="#808080" />
                          {Intl.NumberFormat('en-US', {
                            notation: 'compact',
                            maximumFractionDigits: 1,
                          }).format(agent.totalsearches)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                );
              })
            ) : (
              <Typography variant="subtitle2">Loading Public Bots library ...</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
