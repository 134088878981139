import React from 'react';
import Avatar from '@mui/material/Avatar';
import Iconify from './Iconify';

function AvatarWithOverlay({ avatarSrc }) {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
      }}
    >
      <Avatar alt="Avatar" src={avatarSrc} />
      <Iconify
        icon={'ph:camera'}
        sx={{
          width: 15,
          height: 15,
          ml: 1,
          color: 'black',
          opacity: 0.8,
        }}
      />
    </div>
  );
}

export default AvatarWithOverlay;
