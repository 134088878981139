import { useState, useEffect, createContext } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import DashboardRightSidebar from './DashboardRightSidebar';
import Context from '../../context';
import { auth, onAuthStateChanged } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import userClient from '../../clients/UserClient';
import AgentClient from '../../clients/AgentClient';
import documentClient from '../../clients/DocumentClient';
import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

import { useCookies } from 'react-cookie';
import { CORPORA_URI } from 'src/config';
import { SSE } from 'sse';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});
const SideBar = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100vh',
  paddingTop: APP_BAR_MOBILE + 24,
  zIndex: 100,
}));
const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 2,
  minHeight: '100vh',
  overflow: 'hidden',
  // paddingTop: APP_BAR_MOBILE - 24,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [openRight, setOpenRight] = useState(false);

  const [cookies, setCookie] = useCookies(['session_auth_data']);
  let { id } = useParams();

  const [isSharing, setIsSharing] = useState(false);
  const [sharingTitle, setSharingTitle] = useState(false);
  const [isHidingLogo, setIsHidingLogo] = useState(true);
  const [isHidingLink, setIsHidingLink] = useState(false);
  const [user, isLoading, error] = useAuthState(auth);
  const location = useLocation();
  const [path, setPath] = useState('');
  const [authstate, setauthstate] = useState(false);

  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [customcollections, setcustomcollections] = useState([]);
  const [activeCollection, setActiveCollection] = useState(null);
  const [activeDocument, setActiveDocument] = useState(null);
  const [llmKeys, setLLMKeys] = useState([
    { vendor: 'openai', key: false },
    //{ vendor: 'cohere', key: false },
  ]);

  const [activeTask, setActiveTask] = useState(null);
  const [activeAgent, setActiveAgent] = useState(null);
  const [activeModel, setActiveModel] = useState(null);
  const [temperature, setTemperature] = useState(0);

  let sampleConversation = {};

  sampleConversation[undefined] = [
    {
      message: 'Can you explain the reasons for the decrease in our gross margin for the fiscal year?',
      isBot: false,
    },
    {
      message: {
        answer:
          'The decrease in gross margin for the fiscal year can be attributed to a few factors. Firstly, we experienced an increase in the cost of raw materials which impacted the cost of goods sold. Additionally, we also had to offer discounts and promotions in order to remain competitive in the market. These factors combined resulted in a decrease in our gross margin for the fiscal year',
        documents: [
          {
            page: 18,
            file: 'Annual_Report.pdf',
            score: 0.92,
          },
        ],
      },
      isBot: true,
    },

    {
      message: 'How did our international sales perform compared to domestic sales for the fiscal year?',
      isBot: false,
    },
    {
      message: {
        answer:
          'For the fiscal year, our international sales performed well and grew by XX%, compared to domestic sales which only grew by YY%. This can be attributed to the efforts we made to expand our presence in international markets, as well as a favorable exchange rate.',
        documents: [
          {
            page: 8,
            file: 'International_Sales.pdf',
            score: 0.96,
          },
        ],
      },
      isBot: true,
    },

    {
      message: 'Can you provide an overview of our cash flow for the fiscal year?',
      isBot: false,
    },
    {
      message: {
        answer:
          'Our cash flow for the fiscal year was strong. We had a positive cash flow from operating activities of $XX million, which was primarily driven by our profits. Additionally, our investing activities also generated a positive cash flow of $YY million through the sale of investments. Our financing activities also generated a positive cash flow of $ZZ million through the issuance of new debt. Overall, we ended the fiscal year with a cash balance of $AAA million.',
        documents: [
          {
            page: 12,
            file: 'Annual_Report.pdf',
            score: 0.8,
          },
        ],
      },
      isBot: true,
    },
    {
      message: 'Can you explain the impact of the new tax laws on our financial results for the fiscal year?',
      isBot: false,
    },
    {
      message: {
        answer:
          'The new tax laws had a significant impact on our financial results for the fiscal year. The decrease in the corporate tax rate resulted in a lower effective tax rate for the year, which led to an increase in our net income. However, the new laws also resulted in a one-time charge for the revaluation of our deferred tax assets and liabilities which had a negative impact on our results for the fiscal year.',
        documents: [
          {
            page: 15,
            file: 'Annual_Report.pdf',
            score: 0.8,
          },
        ],
      },
      isBot: true,
    },
  ];

  sampleConversation = null;
  const [msg, setMsg] = useState(sampleConversation || {});
  const [statechg, setstatechg] = useState(false);
  const [agents, setAgents] = useState([]);
  const [authenticationToken, setAuthenticationToken] = useState(null);
  const [msgloading, setMsgloading] = useState(false);
  const [useraccountinfo, setuseraccountinfo] = useState({});
  const [isGPT, setIsGPT] = useState(false);
  const [navsessions, setnavsessions] = useState([]);

  const [isMaxDocumentErrorShowing, setIsMaxDocumentErrorShowing] = useState(false);
  const closeModal = () => {
    setIsMaxDocumentErrorShowing(false);
  };

  console.log(useraccountinfo);
  let { sharingKey } = useParams();

  useEffect(() => {
    async function fetchData() {
      if (sharingKey) {
        setAuthenticationToken(sharingKey);
        setIsSharing(true);
        const share = await sharingClient.getShare(sharingKey);
        setSharingTitle(share.title);
        setIsHidingLogo(share.is_hiding_logo);
        setIsHidingLink(share.is_hiding_link);
      }
    }

    fetchData();
  }, [sharingKey]);
  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, refresh the token
        const token = await user.getIdToken(true);
        setAuthenticationToken(token);
        let userconfdata = cookies.session_auth_data;
        userconfdata.idToken = token;

        setCookie('session_auth_data', userconfdata, { path: '/' });
      } else {
        // User is signed out, handle accordingly
        navigate('/login');
        console.log('User is signed out');
      }
    });

    // Clean up the observer when the component is unmounted
    return () => {
      unregisterAuthObserver();
    };
  }, []);
  useEffect(() => {
    // setIsLoading(true);
    console.log('regaining data');

    async function fetchData() {
      if (authenticationToken !== null) {
        console.info(' Getting Documents');
        let user_info = await userClient.getUser(authenticationToken);
        setuseraccountinfo(user_info);
        if (user_info.status == 401) {
          navigate('/login');
        }
        // let loadedDocuments = await documentClient.getDocuments(authenticationToken);
        // setDocuments((prevDocuments) => [...prevDocuments, ...loadedDocuments]);
        console.info('Getting Collections');
        let collections = await userClient.getCollections(authenticationToken);
        setcustomcollections(collections.reverse());
        const response = await AgentClient.getAgents(authenticationToken);
        setAgents(response);
      }
    }
    fetchData();
  }, [authenticationToken]);
  useEffect(() => {
    async function fetchData() {
      if (isLoading || sharingKey) return;

      if (!user || user == null) return navigate('/login');
      if (!authstate) {
        let idToken = await user.getIdToken();
        console.log(idToken);
      }
      window.uuser = user;
      let customData = {
        name: user.displayName,
        email: user.email,
      };
      window._loq = window._loq || [];
      window._loq.push(['custom', customData]);
      setAuthenticationToken(cookies.session_auth_data.idToken);
      setauthstate(true);
      console.log(authstate);
    }

    fetchData();
  }, [user, isLoading, statechg]);

  useEffect(() => {
    setPath(location.pathname.split('/').slice(-1)[0]);
    if (path === 'collection') {
      setOpenRight(false);
    }
  }, [location.pathname]);
  useEffect(() => {
    setstatechg(!statechg);
    console.log('stateChange', statechg);
  }, []);
  async function sendQuestion(q, qI, isGPT = false, isAccurate = false, setMsgCallback) {
    return new Promise(async (resolve) => {
      let questionToUse = q;
      console.info(questionToUse);
      setMsgloading(true);

      if (msgloading) {
        return;
      }
      let currentAnswerIndex = -1;

      setMsgCallback((prevState) => {
        currentAnswerIndex = prevState[id].length;
        return {
          ...prevState,
          [id]: [
            ...prevState[id],
            {
              message: questionToUse,
              isBot: false,
            },
          ],
        };
      });

      // if (!isSharing) {
      //   setQuestion('', true);
      // }

      let authtoken = authenticationToken ? authenticationToken : cookies.session_auth_data.idToken;

      try {
        let customData = '';
        if (isGPT) {
          checked.forEach((ele) => {
            if (!msg[id][ele].isBot) {
              customData += msg[id][ele].message + '\n';
            } else {
              customData += msg[id][ele].message.answer + '\n';
            }
          });
        }
        console.log(agents.find((ele) => ele.agentid === activeAgent));
        const fdata = {
          question: questionToUse,
          collectionid: activeCollection,

          agentData: agents.find((ele) => ele.agentid === activeAgent),
          temperature: temperature,
          modeltouse: activeModel,
          customdata: customData,
        };
        if (activeModel == 'gpt-3.5-turbo') {
          useraccountinfo.searches_count = useraccountinfo.searches_count + 1;
          setuseraccountinfo({ ...useraccountinfo });
        } else if (activeModel == 'gpt-4') {
          useraccountinfo.searches_count = useraccountinfo.searches_count + 20;
          setuseraccountinfo({ ...useraccountinfo });
        } else if (activeModel == 'gpt-4-0314') {
          useraccountinfo.searches_count = useraccountinfo.searches_count + 20;
          setuseraccountinfo({ ...useraccountinfo });
        }

        if (questionToUse !== '') {
          let url = CORPORA_URI + '/ask-from-custom-collection-stream';
          if (isAccurate) {
            url = CORPORA_URI + '/ask-gpt-recurse-stream';
          }
          if (isGPT) {
            url = CORPORA_URI + '/ask-gpt-custom-stream';
          }

          let source = new SSE(url, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer: ' + authtoken,
            },
            method: 'POST',
            payload: JSON.stringify(fdata),
          });
          setMsgCallback((prevState) => {
            return {
              ...prevState,
              [id]: [
                ...prevState[id],
                {
                  message: {
                    answer: '',
                    documents: [],
                  },
                  paymentRequired: false,
                  isBot: true,
                },
              ],
            };
          });
          source.addEventListener('message', (e) => {
            console.log('streaming started');

            if (e.data != '[DONE]') {
              // console.log(e);
              let payload = JSON.parse(e.data);
              let text = payload.data.answer;
              // console.log(text);
              if (text != '\n') {
                setMsgCallback((prevState) => {
                  const newState = { ...prevState };
                  console.log(currentAnswerIndex);
                  console.log(newState);
                  newState[id][currentAnswerIndex + 1].message.answer = payload.data.answer;
                  newState[id][currentAnswerIndex + 1].message.documents = payload.data.documents;
                  newState[id][currentAnswerIndex + 1].paymentRequired = payload.data.paymentRequired;

                  return newState;
                });
              }
            } else {
              setMsgloading(false);
              console.log('ONRESOLVE', msg);
              console.log('ONRESOLVE', msg);
              source.close();
              resolve();
            }
          });

          source.addEventListener('readystatechange', (e) => {
            if (e.readyState >= 2) {
              resolve();
              console.log('ONRESOLVE', msg);
              setMsgloading(false);
            }
          });

          source.stream();
        } else {
          alert('Please insert a prompt!');
        }
      } catch (e) {
        if (e.type === 'payment') {
          if (window.confirm('You have run out of free credits, please create a subscription to continue')) {
            window.location.href = '/profile';
          }
          resolve();
          setMsgloading(false);
          return;
        }
        console.log(e);
      }

      setMsgloading(false);

      // if (window.LO.events && window.LO.events.track) {
      //   window.LO.events.track("search");
      // }

      if (window.mixpanel && window.mixpanel.track) {
        window.mixpanel.track('Search', {
          source: 'Web App',
          question: q.substring(0, 200),
        });
      }
    });
  }
  const value = {
    documents: documents,
    setDocuments: setDocuments,
    msg: msg,
    msgloading: msgloading,
    setMsgloading: setMsgloading,
    setMsg: setMsg,
    activeCollection: activeCollection,
    setActiveCollection: setActiveCollection,
    activeDocument: activeDocument,
    setActiveDocument: setActiveDocument,
    user: cookies.session_auth_data.user,
    customcollections: customcollections,
    setcustomcollections: setcustomcollections,
    authenticationToken: authenticationToken,
    setAuthenticationToken: setAuthenticationToken,
    isSharing: isSharing,
    sharingTitle: sharingTitle,
    isHidingLink: isHidingLink,
    isHidingLogo: isHidingLogo,
    setIsMaxDocumentErrorShowing: setIsMaxDocumentErrorShowing,
    activeTask: activeTask,
    setActiveTask: setActiveTask,
    activeAgent: activeAgent,
    setActiveAgent: setActiveAgent,
    activeModel: activeModel,
    setActiveModel: setActiveModel,
    temperature: temperature,
    setTemperature: setTemperature,
    useraccountinfo: useraccountinfo,
    setuseraccountinfo: setuseraccountinfo,
    isGPT: isGPT,
    setIsGPT: setIsGPT,
    navsessions: navsessions,
    setnavsessions: setnavsessions,
    llmKeys: llmKeys,
    setLLMKeys: setLLMKeys,
  };

  const [showSidebar, setShowSidebar] = useState(true);
  return (
    <RootStyle>
      {authstate ? (
        <Context.Provider value={value}>
          {showSidebar ? (
            <SideBar>
              <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            </SideBar>
          ) : (
            ''
          )}

          <DashboardNavbar
            onOpenSidebar={() => setOpen(true)}
            onOpenSidebarRight={() => setOpenRight(true)}
            path={path}
          />

          <MainStyle>
            <Outlet context={{ setShowSidebar, isGPT, setIsGPT, setOpen, setOpenRight }} />
          </MainStyle>
          {showSidebar ? (
            <SideBar>
              <DashboardRightSidebar
                sendQuestion={sendQuestion}
                isOpenSidebar={openRight}
                onCloseSidebar={() => setOpenRight(false)}
                isGPT={isGPT}
                setIsGPT={setIsGPT}
              />
            </SideBar>
          ) : (
            ''
          )}
        </Context.Provider>
      ) : null}
    </RootStyle>
  );
}
