import { createContext } from 'react';

const Context = createContext({
  documents: [],
  msgloading: false,
  activeCollection: null,
  setActiveCollection: () => {},
  llmKeys: [],
  setLLMKeys: () => {},
  activeDocument: null,
  setActiveCollection: () => {},
  customcollections: [],
  setcustomcollections: () => {},
  setMsgloading: () => {},
  setAuthenticationToken: () => {},
  msg: {},
  setMsg: () => {},
  setDocuments: () => {},
  user: null,
  authenticationToken: null,
  isSharing: false,
  isHidingLink: false,
  isHidingLogo: true,
  sharingTitle: '',
  setIsMaxDocumentErrorShowing: () => {},
  activeTask: null,
  setActiveTask: () => {},
  activeAgent: null,
  setActiveAgent: () => {},
  activeModel: null,
  setActiveModel: () => {},
  temperature: null,
  setTemperature: () => {},
  isGPT: false,
  setIsGPT: () => {},
});

export default Context;
