import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import logo from '../../../assets/logo.png';
import { styled } from '@mui/material/styles';
import conversationClient from '../../../clients/ConversationClient';

// @mui
import {
  Grid,
  Card,
  Avatar,
  Divider,
  CardHeader,
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Tab,
  CircularProgress,
  useTheme,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
  Skeleton,
  tooltipClasses,
} from '@mui/material';
// components

import { useContext } from 'react';
import Context from '../../../context';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import rehypeRaw from 'rehype-raw';
import markdownStyles from './styles/markdown.module.css';
import Iconify from 'src/components/Iconify';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(102, 115, 128, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      maxWidth: 180,
      padding: '15px 15px',
    },
  })
);
ApplicationPanel.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};
function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}
export default function ApplicationPanel({
  idx,
  title,
  isBot,
  userAvatar,
  checked,
  handleOnChecked,
  subheader,
  message,
  isGPT,
  setPdfUrl,
  setpdfKeyword,
  setpdfPage,
  documents,
  setShowSidebar,
  sendQuestion,
  livechat,
}) {
  const [open, setOpen] = useState(false);
  const [streamFinished, setstreamFinished] = useState(false);

  const isMobile = useResponsive('down', 'sm');

  const theme = useTheme();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenSources = async () => {
    handleClickOpen();
  };

  useEffect(() => {
    // console.log(message.generatingsummary);

    if (message.message.generatingsummary && message.message.generatingsummary) {
      setstreamFinished(true);
    } else {
      setstreamFinished(false);
    }
  });
  let {
    user,
    customcollections,
    activeCollection,
    setActiveCollection,
    activeDocument,
    setActiveDocument,
    authenticationToken,
    activeTask,
    setActiveTask,
    activeAgent,
    setActiveAgent,
    activeModel,
    setActiveModel,
    temperature,
    setTemperature,
    msgloading,
    setMsgloading,
    msg,
    setMsg,
    useraccountinfo,
  } = useContext(Context);
  const generateQues = (setMsgCallback) => {
    let id = undefined;
    return new Promise(async (resolve) => {
      let questionToUse =
        'Using the Table of Contents, Introduction, Preface, Conclusion or Summary section, generate 5 topics that are being discussed.';
      console.info(questionToUse);
      setMsgloading(true);

      if (msgloading) {
        return;
      }
      let currentAnswerIndex = -1;
      // if (!isSharing) {
      //   setQuestion('', true);
      // }

      let authtoken = authenticationToken ? authenticationToken : cookies.session_auth_data.idToken;
      console.log(activeDocument);
      try {
        const fdata = {
          question: questionToUse,
          documentid: activeDocument,
          collectionid: activeCollection,
          temperature: temperature,
          modeltouse: activeModel,
          sharing: false,
          index: 2,
          generatequestion: true,
        };
        setMsgCallback((prevState) => {
          currentAnswerIndex = prevState[id].length;
          return {
            ...prevState,
            [id]: [
              ...prevState[id],
              {
                message: {
                  answer: '',
                  documents: [],
                },
                paymentRequired: false,
                isBot: true,
              },
            ],
          };
        });
        if (questionToUse !== '') {
          let response = await conversationClient.generateGPT3(authenticationToken, fdata);

          setMsgCallback((prevState) => {
            const newState = { ...prevState };
            console.log(currentAnswerIndex);
            console.log(newState);
            if (response.data.answer === 'No results found') {
              newState[id][currentAnswerIndex].message.answer = response.data.answer;
              newState[id][currentAnswerIndex].message.documents = response.data.documents;
              newState[id][currentAnswerIndex].paymentRequired = response.data.paymentRequired;
              newState[id][currentAnswerIndex].message.generatedquestion = false;
            } else {
              newState[id][currentAnswerIndex].message.answer = response.data.answer;
              newState[id][currentAnswerIndex].message.documents = response.data.documents;
              newState[id][currentAnswerIndex].paymentRequired = response.data.paymentRequired;
              newState[id][currentAnswerIndex].message.generatedquestion = true;
            }

            return newState;
          });

          console.log(response);
        } else {
          alert('Please insert a prompt!');
        }
      } catch (e) {
        if (e.type === 'payment') {
          if (window.confirm('You have run out of free credits, please create a subscription to continue')) {
            window.location.href = '/profile';
          }
          resolve();
          setMsgloading(false);
          return;
        }
        console.log(e);
      }

      setMsgloading(false);

      // if (window.LO.events && window.LO.events.track) {
      //   window.LO.events.track("search");
      // }

      if (window.mixpanel && window.mixpanel.track) {
        window.mixpanel.track('Search', {
          source: 'Web App',
          question: q.substring(0, 200),
        });
      }
    });
  };
  function handlesendgeneratequestion(question) {
    console.log(question);
    sendQuestion(question);
  }
  const handleGenerate = async (e) => {
    await generateQues(setMsg);
  };

  function RenderTextBlock() {
    let questions = title.split('\n');
    questions = questions.filter((element) => element !== '');
    if (message.message.generatedquestion || message.generatedquestion) {
      return (
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
          key={idx}
        >
          <Typography sx={{ fontSize: '15px' }}>{message.additionaltext ? message.additionaltext : ''}</Typography>
          {questions.map((question, idx) => {
            return (
              <Typography
                variant="body2"
                sx={{
                  display: 'inline-block',
                  backgroundColor: '#e0e0e0',
                  padding: '4px 8px',
                  borderRadius: '16px',
                  cursor: 'pointer',
                  mb: 1,
                  pl: 2,
                  pr: 2,
                  pb: 1,
                  pt: 1,
                  mt: 1,

                  '&:hover': {
                    backgroundColor: '#bdbdbd',
                  },
                }}
                onClick={(e) =>
                  message.generatetopic ? handleGenerate(question) : handlesendgeneratequestion(question)
                }
                value={question}
                key={idx}
              >
                {question}
              </Typography>
            );
          })}
        </Grid>
      );
    } else {
      return (
        <ReactMarkdown remarkPlugins={[remarkGfm]} className={markdownStyles} rehypePlugins={[rehypeRaw]}>
          {title}
        </ReactMarkdown>
      );
    }
  }

  return (
    <>
      <Card
        sx={{
          padding: { xs: '0.3rem', md: '1rem' },
          margin: '0.5rem',
          marginLeft: isMobile ? 0 : '0.5rem',
          marginRight: isMobile ? 0 : '0.5rem',

          backgroundColor: !isBot ? theme.palette.grey[500_12] : '',
          borderRadius: { xs: '0.4rem', md: '1rem' },
        }}
        key={idx}
      >
        {/* <CardHeader title={title} /> */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
          <Stack
            direction="row"
            alignItems="flex-start"
            sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: isMobile ? 'center' : null }}
          >
            <Box>
              {isBot ? (
                isMobile ? null : (
                  <Avatar src={logo} alt="photoURL" />
                )
              ) : (
                <Avatar
                  src={userAvatar}
                  alt="photoURL"
                  sx={{ width: { xs: '20px', md: '40px' }, height: { xs: '20px', md: '40px' } }}
                />
              )}
            </Box>
            {/* <Typography variant={isBot ? 'body1' : 'subtitle1'} style={{ padding: 20 }}>
              {title}
            </Typography> */}
            {/* modify React markdown acording to current UI, it's styling is causing some global changes (see on collection page) */}

            <Box sx={{ padding: 1, paddingLeft: isMobile ? 1 : 3, paddingRight: isMobile ? 2 : null, width: '100%' }}>
              {!title ? (
                <Grid sx={{ width: '100%' }}>
                  <Typography variant="caption">
                    <Skeleton />
                  </Typography>
                  <Typography variant="caption">
                    <Skeleton />
                  </Typography>
                  <Typography variant="caption">
                    <Skeleton />
                  </Typography>
                </Grid>
              ) : (
                <Typography
                  style={{
                    fontSize: isMobile ? '12px' : '90%',
                    marginRight: 2,
                  }}
                >
                  <RenderTextBlock />
                </Typography>
              )}
              {isBot ? <Divider sx={{ padding: '0.3rem' }} /> : null}
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                {documents?.slice(0, 4).map((doc, i) => {
                  return (
                    <Box sx={{ borderRadius: 0, padding: '1rem', paddingLeft: 0 }}>
                      {isValidHttpUrl(doc.file) ? (
                        <Link href={doc.file} target="_blank">
                          <Chip
                            icon={
                              <>
                                <Iconify
                                  icon={`material-symbols:counter-${i + 1}-rounded`}
                                  sx={{ ml: '0.3rem' }}
                                  color="#808080"
                                />
                                <Iconify icon={'material-symbols:link'} sx={{ ml: '0.3rem' }} />
                              </>
                            }
                            label={`${i + 1}. ${doc.file} `}
                            onClick={() => {}}
                            style={{ fontSize: '12px' }}
                          />
                        </Link>
                      ) : // <Link href={`${doc.file_url}#page=${doc.page}`} target="_blank">
                      //   <Chip
                      //     icon={
                      //       <>
                      //         <Iconify
                      //           icon={`material-symbols:counter-${i + 1}-rounded`}
                      //           sx={{ ml: '0.3rem' }}
                      //           color="#808080"
                      //         />
                      //         <Iconify icon={'material-symbols:link'} sx={{ ml: '0.3rem' }} />
                      //       </>
                      //     }
                      //     label={`${i + 1}. ${doc.file} `}
                      //     onClick={() => {}}
                      //     style={{ fontSize: '13px' }}
                      //   />
                      // </Link>
                      livechat ? (
                        <Link href={`${doc.file_url}#page=${doc.page}`} target="_blank">
                          <Chip
                            icon={
                              <>
                                <Iconify
                                  icon={`material-symbols:counter-${i + 1}-rounded`}
                                  sx={{ ml: '0.3rem' }}
                                  color="#808080"
                                />
                                <Iconify icon={'material-symbols:link'} sx={{ ml: '0.3rem' }} />
                              </>
                            }
                            label={`${i + 1}. ${doc.file} `}
                            onClick={() => {}}
                            style={{ fontSize: '12px' }}
                          />
                        </Link>
                      ) : (
                        <Chip
                          icon={
                            <>
                              <Iconify icon={`mdi:numeric-${i + 1}-circle`} sx={{ ml: '0.3rem' }} color="#808080" />
                              <Iconify icon={'tabler:pdf'} sx={{ ml: '0.3rem' }} color="#808080" />
                            </>
                          }
                          label={`${doc.file} `}
                          onClick={() => {
                            setShowSidebar(false);
                            setPdfUrl(doc.file_url);
                            setpdfKeyword(doc.paragraph.replace(/\s+/g, ' ').trim());
                            setpdfPage(doc.page);
                          }}
                          style={{ fontSize: '12px' }}
                        />
                      )}

                      {/* <Typography variant="subtitle2">{doc.paragraph.substring(0, 100)}</Typography> */}
                    </Box>
                  );
                })}

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {isBot ? (
                    <LightTooltip title="View Detailed Sources" placement="right">
                      <IconButton size="small">
                        <Iconify
                          icon={'grommet-icons:resources'}
                          sx={{ ml: '0.3rem' }}
                          color="#808080"
                          onClick={handleOpenSources}
                        />
                      </IconButton>
                    </LightTooltip>
                  ) : null}
                  {streamFinished && <CircularProgress size="1rem" sx={{ ml: 1 }} />}
                </Box>
              </div>
            </Box>
            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth={'md'}
              fullWidth={true}
              PaperProps={{
                style: { borderRadius: 5 },
              }}
            >
              <DialogTitle>Sources</DialogTitle>
              <Divider />

              <DialogContent sx={{ mt: '1rem' }}>
                {' '}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {documents?.map((doc, i) => {
                    return (
                      <Box sx={{ borderRadius: 0, padding: '1rem', paddingTop: 0 }}>
                        {isValidHttpUrl(doc.file) ? (
                          <Link href={doc.file} target="_blank" style={{ fontWeight: 'bold' }}>
                            {i + 1}. {doc.file} <Iconify icon={'ic:outline-open-in-new'} sx={{ ml: '0.3rem' }} />
                          </Link>
                        ) : (
                          <Button
                            onClick={() => {
                              setShowSidebar(false);
                              setPdfUrl(doc.file_url);
                              setpdfKeyword(doc.paragraph.replace(/\s+/g, ' ').trim());
                              setpdfPage(doc.page);
                            }}
                          >
                            {i + 1}. {doc.file} <Iconify icon={'ic:outline-open-in-new'} sx={{ ml: '0.3rem' }} />
                          </Button>
                        )}
                        <Typography variant="subtitle2" style={{ paddingBottom: '1rem' }}>
                          {doc.paragraph.textData
                            ? doc.paragraph.textData.substring(0, 500)
                            : doc.paragraph.substring(0, 500)}
                        </Typography>
                        <Divider />
                      </Box>
                    );
                  })}
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </Stack>
          {isGPT ? (
            <LightTooltip
              title="All selected responses will be included as context in Direct GPT Chat"
              placement="bottom"
            >
              <Checkbox checked={checked} value={idx} onChange={handleOnChecked} />
            </LightTooltip>
          ) : (
            ''
          )}
        </Stack>
      </Card>
    </>
  );
}
