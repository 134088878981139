import React, { useEffect, useRef } from 'react';
import WebViewer from '@pdftron/webviewer';
import './pdftroncss.css';

export default function PDFViewer({ pdfUrl, keyword, page, ...other }) {
  const viewer = useRef(null);

  useEffect(() => {
    // Remove the previous instance of WebViewer before creating a new one
    if (viewer.current) {
      viewer.current.innerHTML = '';
    }

    // Create a new element for the new instance of WebViewer
    const webViewerElement = document.createElement('span');
    webViewerElement.height = '100vh';
    viewer.current.appendChild(webViewerElement);

    WebViewer(
      {
        path: '/webviewer/lib',
        initialDoc: pdfUrl,
        licenseKey: 'demo:1683806884332:7dd3a8190300000000524edf06c11082e5f2a9bbf836a333b463026f17',
        disabledElements: ['viewControlsButton', 'viewControlsOverlay'],
        enableAnnotations: true,
      },
      webViewerElement
    ).then((instance) => {
      console.log(page);
      const { annotationManager, documentViewer, Annotations } = instance.Core;
      const searchListener = (searchPattern, options, results) => {
        // add redaction annotation for each search result
        const newAnnotations = results.map((result) => {
          const annotation = new Annotations.RedactionAnnotation();
          // annotation.PageNumber = result.pageNum;
          // annotation.Quads = result.quads.map((quad) => quad.getPoints());
          // annotation.StrokeColor = new Annotations.Color(255, 255, 0);
          return annotation;
        });
        annotationManager.addAnnotations(newAnnotations);
        annotationManager.drawAnnotationsFromList(newAnnotations);
      };
      documentViewer.addEventListener('documentLoaded', () => {
        const searchPattern = keyword.substring(0, 20);
        // searchPattern can be something like "search*m" with "wildcard" option set to true
        // searchPattern can be something like "search1|search2" with "regex" option set to true
        documentViewer.zoomTo(1.25);
        documentViewer.setCurrentPage(page);
        // // options default values are false
        // const searchOptions = {
        //   caseSensitive: false, // match case
        //   wholeWord: false, // match whole words only
        //   wildcard: false, // allow using '*' as a wildcard value
        //   regex: false, // string is treated as a regular expression
        //   searchUp: false, // search from the end of the document upwards
        //   ambientString: true, // return ambient string as part of the result
        // };
        // instance.UI.addSearchListener(searchListener);
        // // start search after document loads
        // instance.UI.searchTextFull(searchPattern, searchOptions);
      });
    });

    return () => {
      // Cleanup the previous instance of WebViewer
      if (viewer.current) {
        viewer.current.innerHTML = '';
      }
    };
  }, [pdfUrl, page, keyword]);

  return <div ref={viewer} style={{ height: '97vh' }} className="webviewer"></div>;
}
