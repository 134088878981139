import { useState, useEffect, useRef, useContext } from 'react';

import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';

import Context from '../context';
import documentClient from '../clients/DocumentClient';
import MenuPopover from '../components/MenuPopover';
import Iconify from 'src/components/Iconify';

import {
  Grid,
  Card,
  Container,
  CardHeader,
  Box,
  Button,
  Typography,
  TextField,
  Divider,
  CircularProgress,
  CardContent,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
// components
import Page from '../components/Page';
import { styled } from '@mui/material/styles';

import sharingClient from 'src/clients/SharingClient';
import conversationClient from '../clients/ConversationClient';
import CollapsibleTable from 'src/components/CollapsibleTable';
import { collection } from 'firebase/firestore';

// ----------------------------------------------------------------------

export default function Collections() {
  const [applicationtype, setapplicationtype] = useState('Payments');

  const [open, setOpen] = useState(null);
  const [openAddDoc, setopenAddDoc] = useState(null);

  const [loading, setloading] = useState(false);
  const [status, setstatus] = useState('active');
  const [newAddedDatabase, setnewAddedDatabase] = useState('');
  const [MongoURI, setMongoURI] = useState('');
  const [MongoPass, setMongoPass] = useState('');
  const [currcollectionid, setcurrcollectionid] = useState('');
  const [shouldexpand, setshouldexpand] = useState(false);
  const [usedspace, setusedspace] = useState(0);
  const [downloading, setdownloading] = useState(false);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const navigate = useNavigate();

  let { id } = useParams();

  const [isFileLoading, setIsFileLoading] = useState(false);
  const [singleFileLoading, setsingleFileLoading] = useState(false);

  const resultRef = useRef();
  let [result, setResult] = useState('');

  const fileRef = useRef(null);

  let [sampleDocuments, setSampleDocuments] = useState([
    {
      fileName: 'Annual_Report.pdf',
      id: 1,
    },
    {
      fileName: 'International_Sales.pdf',
      id: 2,
    },
    {
      fileName: 'Employee_Satisfaction.pdf',
      id: 3,
    },

    {
      fileName: 'Annual_Report.pdf',
      id: 1,
    },
    {
      fileName: 'International_Sales.pdf',
      id: 2,
    },
    {
      fileName: 'Employee_Satisfaction.pdf',
      id: 3,
    },

    {
      fileName: 'Annual_Report.pdf',
      id: 1,
    },
    {
      fileName: 'International_Sales.pdf',
      id: 2,
    },
    {
      fileName: 'Employee_Satisfaction.pdf',
      id: 3,
    },

    {
      fileName: 'Annual_Report.pdf',
      id: 1,
    },
    {
      fileName: 'International_Sales.pdf',
      id: 2,
    },
    {
      fileName: 'Employee_Satisfaction.pdf',
      id: 3,
    },
  ]);
  sampleDocuments = null;
  let {
    documents,
    setDocuments,
    setMsgloading,
    msgloading,
    msg,
    setMsg,
    user,
    customcollections,
    setcustomcollections,
    authenticationToken,
    isSharing,
    setIsMaxDocumentErrorShowing,
    useraccountinfo,
  } = useContext(Context);
  [documents, setDocuments] = sampleDocuments ? [sampleDocuments, setSampleDocuments] : [documents, setDocuments];

  const location = useLocation();
  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(102, 115, 128, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: 180,
        padding: '15px 15px',
      },
    })
  );
  function deleteDocument(id) {
    setDocuments(documents.filter((d) => d.id !== id));
    documentClient.deleteDocument(id, authenticationToken);
  }

  async function viewDocument(fileUrl) {
    window.open(fileUrl, '_blank').focus();
  }
  console.log(customcollections);
  let sampleConversation = null;

  // useEffect(() => {
  //   console.log('documents');
  //   async function fetchData() {
  //     if (sampleDocuments || !authenticationToken) {
  //       return;
  //     }
  //     console.log(authenticationToken);
  //     let loadedDocuments = await documentClient.getDocuments(authenticationToken);
  //     setDocuments((prevDocuments) => [...prevDocuments, ...loadedDocuments]);
  //     console.log(documents);
  //     setIsLoading(false);
  //   }
  //   fetchData();
  // }, [authenticationToken]);

  function lastword(words) {
    var n = words.split(' ');
    return n[n.length - 1];
  }
  async function generateSummary(ids, fileName) {
    console.log(msg);
    setMsgloading(true);
    let withQuestion = [
      ...msg[id],
      {
        message: `Summarize this document.`,
        isBot: false,
      },
    ];
    console.log(msg);
    setMsg({
      ...msg,
      [id]: withQuestion,
    });

    console.log(msg);
    // const response = await conversationClient.getSummary(
    //   "Summarize this document.",
    //   ids,
    //   authenticationToken
    // );
    try {
      // const fdata = new FormData();
      // fdata.append("question", "Summarize this document.");
      // fdata.append("document_id", ids);
      const fdata = {
        question: 'Summarize this document.',
        document_id: ids,
      };
      console.log(fdata);

      if (withQuestion !== '') {
        setResult('');

        let url = process.env.REACT_APP_API_URL + '/get-summary-stream';
        console.log(url);

        let source = new SSE(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer: ' + authenticationToken,
          },
          method: 'POST',
          payload: JSON.stringify(fdata),
        });
        // scrollRef.current.scrlolTop = 0;
        source.addEventListener('message', (e) => {
          console.log('streaming started');
          console.log(JSON.parse(e.data));
          if (e.data != '[DONE]') {
            // console.log(e);
            let payload = JSON.parse(e.data);
            let text = payload.data.answer;
            // console.log(text);
            if (text != '\n') {
              // console.log("Text: " + text);
              resultRef.current = resultRef.current + text;
              // console.log("ResultRef.current: " + resultRef.current);
              setResult(resultRef.current);
              let prevMessages = isSharing ? [] : withQuestion;
              if (lastword(payload.data.answer) == 'DONESUMALPHA') {
                if (payload.chunkno == payload.chunklen - 1) {
                  setMsgloading(false);
                } else {
                  setMsgloading(true);
                }
              } else {
                setMsgloading(true);
                setMsg({
                  ...msg,
                  [id]: [
                    ...prevMessages,
                    {
                      message: {
                        answer: id ? payload.data.answer : payload.data.answer || payload.data.answer,
                        documents: id ? [] : payload.data.documents,
                      },
                      paymentRequired: payload.data.paymentRequired,
                      isBot: true,
                    },
                  ],
                });
              }
            }
          } else {
            source.close();
          }
        });

        source.addEventListener('readystatechange', (e) => {
          console.log(e.readyState);
          if (e.readyState >= 2) {
            setMsgloading(false);
          }
        });

        source.stream();
      } else {
        alert('Please insert a prompt!');
      }
    } catch (e) {
      if (e.type === 'payment') {
        if (window.confirm('You have run out of free credits, please create a subscription to continue')) {
          window.location.href = '/profile';
        }

        setMsgloading(false);
        return;
      }
    }

    let prevMessages = withQuestion;
  }
  //

  async function handleFileChange(evt) {
    let statusresponse;
    console.log(evt);
    if (!evt || !evt.target || !evt.target.files[0]) {
      return;
    }
    setIsFileLoading(true);
    let document;
    try {
      document = await documentClient.uploadDocument(evt.target.files[0], authenticationToken);
      document.spinnerstatus = false;

      console.log(document);
      let interval = setInterval(async () => {
        statusresponse = await documentClient.getDocumentStatus(document.id);
        console.log(statusresponse);
        if (statusresponse.status == 200) {
          document.spinnerstatus = false;
          setsingleFileLoading(false);
          clearInterval(interval);
        } else {
          document.spinnerstatus = true;
          setsingleFileLoading(true);
        }
      }, 2000);
    } catch (e) {
      if (e.type === 'documentLimitExceeded') {
        setIsMaxDocumentErrorShowing(true);
        return;
      }
      alert('There was an error uploading your file, please try again later');
      return;
    } finally {
      setIsFileLoading(false);
    }

    console.log(location);
    console.log(document);
    setDocuments([document, ...documents]);

    navigate('/dashboard/conversation');
  }
  const userObj = {
    name: 'navdeep',
    email: 'navdepdhakar1@gmail.com',
    userdatabases: ['db', 'db1'],
    userauthinfo: 'auth info',
    urltoken: 'url token',
    customapi: 'custom api',
  };
  async function CreateCollection(newdatabase) {
    setloading(true);

    let user_collections = await documentClient.addCollection(newdatabase, authenticationToken);

    setcustomcollections((prevdatabase) => [
      {
        CollectionName: user_collections.collectionname,
        documents: user_collections.documents,
        Status: false,
        expand: false,
        collectionid: user_collections.collectionid,
      },
      ...prevdatabase,
    ]);
    setloading(false);
    console.log(user_collections);
  }
  const NewDatabaseChange = (event) => {
    setnewAddedDatabase(event.target.value);
  };
  const AMongoURI = (event) => {
    setMongoURI(event.target.value);
  };

  console.log(customcollections);
  async function generateSharingKey(collectionid, Status, sharing_key) {
    console.log(collectionid);
    console.log(Status);
    setcustomcollections((prevDatabase) => {
      const updatedCollections = prevDatabase.map((collection) => {
        if (collection.collectionid == collectionid) {
          console.log('match');

          console.log(collection);

          collection.Status = Status;
          return {
            ...collection,
          };
        } else {
          console.log('no match');

          return collection;
        }
      });
      console.log(updatedCollections);
      return updatedCollections;
    });
    let response = await sharingClient.createCollectionSharingLink(
      authenticationToken,
      collectionid,
      Status,
      sharing_key
    );
    setcustomcollections((prevDatabase) => {
      const updatedCollections = prevDatabase.map((collection) => {
        if (collection.collectionid == collectionid) {
          console.log('match');

          console.log(collection);
          collection.sharingkey = response.sharing_key;
          collection.Status = Status;
          return {
            ...collection,
          };
        } else {
          console.log('no match');

          return collection;
        }
      });
      console.log(updatedCollections);
      return updatedCollections;
    });
    console.log('custom collection');
    console.log(customcollections);
  }
  function formatSizeUnits(bytes) {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + ' GB';
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes > 1) {
      bytes = bytes + ' bytes';
    } else if (bytes == 1) {
      bytes = bytes + ' byte';
    } else {
      bytes = '0 bytes';
    }
    return bytes;
  }
  function getTotalSpaceUsed() {
    let totalspaceused = 0;
    customcollections.forEach((collection) => {
      collection.documents.forEach((document) => {
        let fileSize = document.filesize ? document.filesize : 0;
        totalspaceused = totalspaceused + fileSize;
      });
    });

    return totalspaceused;
  }
  useEffect(() => {
    let totspaceused = getTotalSpaceUsed();
    setusedspace(formatSizeUnits(totspaceused));
    console.log(totspaceused);
  });
  async function getsharedata() {
    console.log(useraccountinfo.sessions);
    setdownloading(true);
    const sharingSessions = useraccountinfo.sessions.filter((session) => session.session_type === 'sharing');
    let allsessionconversation = [];
    for (let i = 0; i < sharingSessions.length; i++) {
      let sessionconvo = await conversationClient.getsessionData(authenticationToken, {
        session_id: sharingSessions[i].session_id,
      });
      allsessionconversation.push(sessionconvo);
    }
    console.log(allsessionconversation);
    const jsonString = JSON.stringify(allsessionconversation, null, 2);

    // Create a new Blob object with the JSON string
    const blob = new Blob([jsonString], { type: 'application/json' });

    // Generate a file name for the exported file
    const fileName = 'data.json';

    // Save the file using file-saver
    setdownloading(false);
    saveAs(blob, fileName);
  }

  console.log(usedspace);
  return (
    <Page title={'Collections'}>
      <Grid container spacing={2} direction="row" sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ padding: '0.5rem' }}>
            <CardContent>
              <Typography variant="h4">Total Documents</Typography>
              <Typography variant="h1">
                {customcollections.reduce((acc, ele) => acc + ele.documents.length, 0)}
              </Typography>
              <Divider sx={{ borderBottomColor: 'text.primary', mr: 10, borderBottomWidth: '0.3rem' }} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ padding: '0.5rem' }}>
            <CardContent>
              <Typography variant="h4">Total Space Used</Typography>
              <Typography variant="h2">{usedspace}</Typography>
              <Divider sx={{ borderBottomColor: 'text.primary', mr: 10, borderBottomWidth: '0.3rem' }} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ padding: '0.5rem' }}>
            <CardContent>
              <Typography variant="h4">Total Collections</Typography>
              <Typography variant="h1">{customcollections.length}</Typography>
              <Divider sx={{ borderBottomColor: 'text.primary', mr: 10, borderBottomWidth: '0.3rem' }} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Card>
        <Grid container spacing={2} sx={{}} alignItems="center" justifyContent="end">
          <LightTooltip title="Export all chats for shared bots in .json format" placement="left">
            {downloading ? (
              <Grid item alignItems="center" justifyContent="center">
                <CircularProgress size="1rem" />
              </Grid>
            ) : (
              <Grid item>
                <Button variant="text" onClick={getsharedata} style={{ margin: 10 }}>
                  <Iconify icon={'material-symbols:download'} width={22} height={22} />
                </Button>
              </Grid>
            )}
          </LightTooltip>
          <Grid item>
            <Button variant="contained" onClick={handleOpen} style={{ margin: 10 }}>
              Create Collection
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          {/* <Loading /> */}
        </Grid>

        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">Name your collecton </Typography>

              <TextField
                label="Collection Name"
                fullWidth
                style={{ margin: 10 }}
                onChange={NewDatabaseChange}
                value={newAddedDatabase}
              />
              <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    CreateCollection(newAddedDatabase);
                  }}
                  style={{ margin: 10 }}
                >
                  Add
                </Button>
                {loading && <CircularProgress size="1rem" />}
              </Grid>
            </Box>
          </Box>
        </MenuPopover>
        <CollapsibleTable
          collections={customcollections}
          generateSharingKey={generateSharingKey}
          setCollections={setcustomcollections}
        />
      </Card>{' '}
    </Page>
  );
}
