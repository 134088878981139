import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';

import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp2 from './pages/DashboardApp2';
import Collections from './pages/Collections';
import Profile from './pages/Profile';
import PDFViewer from './pages/PDFViewer';
import ShareBot from './pages/ShareBot';
import LiveBot from './pages/LiveBot';
import UserSubscription from './pages/UserSubscription';
import Session from './pages/Session';
import BetterGoogle from './pages/BetterGoogle';
import CreateBots from './pages/CreateBots';
import Agents from './pages/Agents';
import BotsLibrary from './pages/BotsLibrary';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    { path: '/', element: <Navigate to="/login" /> },
    { path: 'login', element: <Login /> },
    { path: 'register', element: <Register /> },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'ask', element: <DashboardApp2 /> },
        { path: 'bettergoogle', element: <BetterGoogle /> },
        { path: 'collections', element: <Collections /> },
        { path: 'createbots', element: <CreateBots /> },
        { path: 'profile', element: <Profile /> },
        { path: 'pdfview', element: <PDFViewer /> },
        { path: 's/:sessionID', element: <Session /> },
      ],
    },
    { path: 'agents/:bottoken', element: <Agents /> },
    { path: 'bots/discover', element: <BotsLibrary /> },
    { path: '/sharing/:sharingkey', element: <ShareBot /> },
    { path: '/livechat/:sharingkey', element: <LiveBot /> },

    { path: 'subscription', element: <UserSubscription /> },

    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
